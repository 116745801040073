export const personalInformationAgreement = `株式会社ベネッセコーポレーション（以下「当社」といいます）では、「いぬ・ねこのきもち応募会員」（以下、本サービス）のご利用にあたってご提供いただく個人情報を以下の方針で取り扱います。
１．当社では、個人情報保護法その他関連する法令等を遵守し、管理責任者のもとで厳重なセキュリティ対策を施し、適正に個人情報を管理しています。
２．本サービスでは主に以下の個人情報を取得します。
（1）お客様ご自身が本サービスの利用のために登録する情報
氏名、住所、電話番号、メールアドレス等、お客様が登録する情報
（2）本サービスのご利用にともないお客様が入力する内容や履歴情報
アンケートや問い合わせ等の本サービス利用履歴情報、お客様が入力したペットに関する情報　等
（3）本サービスへのアクセスにより機械的に取得する情報
cookie（*1）等の情報を利用して記録するパソコンやスマートフォン等の端末を識別する情報、お客様が本サービス内で閲覧・利用した記録　等
上記は例としてあげています。個人情報に該当しない場合もあります。
また、位置情報等を取得する場合は、都度、事前にご承諾を得た上で取得します。
３．取得した個人情報は、以下の目的で利用します。
・本サービスの提供
・当社からの暮らし支援、趣味等の商品・サービスに関するご案内　
・お客様の興味・関心等にあわせた広告や情報の表示
・調査、統計・マーケティング資料作成および、研究・企画開発
※本サービス以外で当社が取得した個人情報をあわせて分析することがあります。
なお、本サービスを退会された場合、利用停止された場合でも同様の目的で利用することがあります。また、お客さまの情報を正確に保つため、当社にご登録済みの情報を更新することがあります。
４．上記利用目的の範囲において、お預かりしている個人情報を業務委託する場合がありますが、厳重に委託先の管理・指導を行います。
５．個人情報をご提供いただくにあたって、ご提供はお客さまの任意です。ただし、ご提供いただけない部分がある場合、手続き・ご連絡やサービス等に支障が生じることがありますのでご了承ください。
６．本サービスでは、お客様から本サービス上で診察受付した際に、診察券を発行するため、受付申し込みいただいた動物病院にお客様の氏名、住所、電話番号、メールアドレス、およびペット情報をデータ送信により提供します。
なお、これ以外で、あらかじめお客様の同意を得ることなくお客様の情報を第三者に提供することはありません。ただし、次の場合は除きます。
（１）法令に基づく場合
（２）人の生命、身体または財産の保護のために必要がある場合
（３）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合
（４）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合
７．当社は、保有する個人情報の開示・訂正等の求めに対応いたします。申し出先、手続き等の詳細は当社ホームページにて公表しております。
８．当社の個人情報の取り扱いおよび管理についてのお問い合わせは、以下の窓口で承ります。
　　窓口電話番号：０１２０−９２４７２１（通話料無料）受付時間：９：００〜２１：００（年末年始は除く）
商品・サービス・お申し込みいただいた内容に関するお問い合わせは、個別サービスに記載されております専用窓口で承ります。
(株)ベネッセコーポレーション CPO(個人情報保護最高責任者)
*1 cookie等とは、お客様がWEBサイトを利用されたときに、お客様の閲覧履歴等を記録するためのしくみをいいます。cookie、web beaconなどがあります。
`;

export const termsOfUse = `いぬ・ねこのきもち応募会員規約

第１条（いぬ・ねこのきもち応募会員規約）
１. 本いぬ・ねこのきもち応募会員規約（以下「本規約」といいます）は、株式会社ベネッセコーポレーション（以下「当社」といいます）が、本規約の各条項の定めに同意した上で「いぬ・ねこのきもち応募会員」の会員登録をした方（以下「会員」といいます）に、会員向けサービス（第４条に定める本サービスを指します）を提供する上での契約内容となります。会員は、本規約の各条項の定めが本サービスの利用にあたり契約条件として適用されることに同意し、これを遵守するものとします。
２. 当社は、相当の理由がある場合には、本規約を随時改定することができます。なお、本規約の改定にあたっては、変更後の内容および効力発生日を事前にWEBサイト（第４条に定める本サイトを指します）への掲載、会員向けに配信する電子メール等の手段により周知することにより本規約を変更することができます。 
３. 当社は、本規約を具体化しまたは補足するための規則を自由に定め、また改定することができます。それらの規則は、当社が本サービス上で表示した時点より、本規約の一部を構成します。

第２条（会員登録）
１. 本サービスの会員の登録は、所定の登録手続を行った時に成立します。ただし、次のいずれかに該当する場合には、当社は登録を承諾しないか、承諾後であっても会員資格を取り消すことができます。
（１）登録した情報に、虚偽の情報、誤記または記入もれがあった場合
（２）すでに登録しているか、過去本規約その他本サービスのほかに当社が提供するサービスの違反等により会員資格を取り消されたことがある場合
（３）民法に定める制限能力行為者であり、その申込みを取り消し得ないものとするための同意・代理等を得ていない場合
（４）その他本サービスの運営に支障を与え、または第三者（他の会員および会員以外の者の双方を含みます。以下同じ。）・当社の権利・利益を害するおそれがあると当社が判断した場合
２.当社は、登録手続を行った方または会員に対し、いつでも電話、電子メール等で連絡し、前項の要件に該当するかどうかを確認することができます。この確認に応じない方については、登録の拒否、または利用の一時停止もしくは会員資格の取り消しができます。

第３条（会員情報）
１.会員は、会員の氏名、住所、電話番号、メールアドレス、パスワード等の情報ならびに、会員の飼育しているペットの名前、ペットの生年月日、種別、性別、品種、毛色等の情報等の、本サービスの運営または本人の確認のため当社が必要と定めた情報（以下「会員情報」といいます）の登録を行うものとします。
２.会員は前項の情報に変更があった場合、すみやかに本サービス所定の方法により変更するものとします。この手続きがない場合、当社は当該情報の変更がないものとして取り扱います。
３.第１項に定める情報に関する変更がないために、当社からの通知、その他が遅延し、または不着、不履行であった場合、当社はその責任を負わないものとします。

第４条（本サービスの内容）
１.当社は、第３条第１項でご登録いただいたメールアドレスに対し、電子メールで、当社が運営するWEBサイト「いぬのきもち・ねこのきもち応募会員」（以下「本サイト」といいます）上で実施されるキャンペーン、アンケート、モニター募集等に関する情報、本サイト運営上のお知らせ等の情報およびペットに関する商品・サービス情報（以下総称して「キャンペーン情報等」）を配信します。
２.会員は、キャンペーン情報等の内容に、当社又は当社の提携先等第三者に関する広告、宣伝等が含まれる場合があることについて、あらかじめ承諾するものとします。
３.会員は、本サイト上で実施されるキャンペーン、アンケート、モニター募集等に応募または回答する場合において、会員情報を用いることができるものとします。

第５条（本サービスの利用料）
１.当社は、本サービスを、会員に対し無料で提供します。
２.第11条に定める利用環境の整備その他本サービスを利用するために必要な全ての費用は、会員の負担とします。

第６条（ID、パスワードの管理責任）
会員は、ID、パスワードの使用と管理について自ら責任をもつものとします。また、これらを第三者に譲渡・貸与したり、他の会員のID、パスワード等を使用することは一切禁止します。

第７条（配信の停止）
当社は、会員が所定の手続きを行うことにより、キャンペーン情報等の配信を望まない旨の意思を表示した場合には、配信を停止します。会員は、当社システムの都合上、更新された内容が直ちに反映されない場合があることを、あらかじめ了承するものとします。なお、配信を望まない旨の意思を表示した会員についても、本規約の変更その他本サービスの提供に大きく影響を及ぼすと当社が判断した情報については、通知するものとします。

第８条（会員資格の取消、登録解除等）
１.当社は、会員が本規約に違反した場合は、当該会員への注意や警告、本サービスの提供の一時停止または会員資格の取消しを行うことができます。
２.会員は、いつでも所定の手続を行うことにより、退会することができます。
３.第２項の定めにより、退会した会員が、再び本サービスを利用される場合、改めて第２条に定める登録手続に従い、本サービスについて登録いただく必要がございます。

第９条（本サービスの中断等）
１.当社は、次のいずれかの事由が生じた場合、会員に事前に通知することなく、一時的に本サービスの全部または一部の提供を中断することがあります。
(１)本サービスを提供するための通信設備等の定期的な保守点検を行う場合または点検を緊急に行う場合
(２)火災、停電等により本サービスの提供ができなくなった場合
(３)地震、噴火、洪水、津波等の天災により本サービスの提供ができなくなった場合
(４)戦争、動乱、暴動、騒乱、労働争議、パンデミック等の不可抗力により本サービスの提供ができなくなった場合
(５)その他、運用上または技術上、当社が本サービスの提供の一時的な中断を必要と判断した場合
２.当社は、当社が必要と判断した場合には、会員に事前に通知することなくいつでも本サービスの内容を変更もしくは追加し、または本サービスの提供を停止もしくは中止することができるものとします。

第10条（権利・義務の譲渡等の禁止）
会員は、会員として有する権利を第三者に譲渡・貸与しもしくは担保として供し、または会員として有する義務を第三者に引き受けさせることはできません。

第11条（利用環境の整備）
１.会員は、本サービスを利用するために必要な通信機器、ソフトウエアその他これらに付随して必要となる全ての機器および利用環境を、自己の費用と責任において準備し、利用可能な状態に置くものとします。また、本サービスのご利用にあたっては、自己の費用と責任において、会員が任意に選択した電気通信サービスまたは電気通信回線を経由してインターネットに接続するものとします。
２.会員は、関係官庁等が提供する情報を参考にして、自己の利用環境に応じ、コンピュータ・ウィルスの感染、不正アクセスおよび情報漏洩の防止等のため、セキュリティ環境を構築・維持するものとします。
３.当社は、会員の機器および利用環境について一切関与せず、また一切の責任を負いません。
４.当社は、別途定める推奨環境以外の利用により当社が損害を被った場合、会員に対し損害賠償を請求することができるものとします。

第12条（禁止事項）
会員は、本サービスを利用するにあたり、以下の行為を一切行ってはならないものとします。
（１）社会通念上不適切と判断される相談行為
（２）同内容の問い合わせを繰り返し過度に行うなどの迷惑行為、第３条の定めに反するご相談、または義務や理由のないことを強要し、当社の業務に著しく支障を来す行為
（３）本サービスの運営を妨害する行為
（４）プライバシー、肖像権、知的財産権その他の権利を侵害する行為
（５）名誉・信用を毀損する行為
（６）本サービスを提供するための機器・ソフトウエア等に不正にアクセスする行為
（７）本サービスにとって有害なコンピュータプログラム等を送信する行為
（８）法令、公序良俗、本規約に反する行為
（９）その他、本サービスの運営に支障をきたすおそれのある行為

第13条（本サービスの廃止）
当社は、当社の都合によりいつでも本サービスの全部または一部を廃止することができるものとします。但し、会員に重大な影響を及ぼすと当社が判断した場合であって、緊急やむを得ない場合以外のときは、当社は、本サービスの廃止について、事前に当社が適当と判断する方法で会員に通知しまたは周知するものとします。

第14条（免責事項等）
当社は、本サービスに関連して当社の責に帰すべき事由により会員に発生した損害について、責任および損害の内容に応じて、通常損害の範囲内で、損害賠償責任を負うものとします。ただし、当社の故意または重過失に起因する損害の場合はこの限りではありません。

第15条（個人情報等の取り扱い）
会員の個人情報の取り扱いについては「個人情報の取り扱いについて」に定めるものとします。

第16条（準拠法）
本規約に関する準拠法は、日本国法令とします。

第17条（裁判管轄）
本サービスに関して当社と会員との間に紛争を生じた場合は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。


以上
2022年1月27日　制定
`;
