import styled from "styled-components";

export const UpdateNewslettersComplete = () => (
  <>
    <Title>登録を変更しました</Title>
  </>
);

const Title = styled.h2`
  font-size: 26px;
`;
