import { useEffect, Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  CampaignStatus,
  fetchCampaignData,
  setError,
  setnoticeData,
  skipRegistration,
} from "../features/campaign/campaignSlice";
import { setApplicationType } from "../features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Present } from "../features/campaign/Present";
import { Button } from "../components/Button";

import RibbonOrange from "../assets/images/ribbon_orange@3x.png";
import OrangeWaveIcon from "../assets/images/bg_wave_orange@3x.png";
import axios from "axios";

import { saveStoreData } from "../utils/saveStoreData";
import { clearFormValues, setZipSearchResults } from "../features/form/formSlice";
import { checkMagazineJudge } from "../features/user/userSlice";

export const Campaign = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { campaignId } = useParams<{ campaignId?: string }>();
  const { campaignData, campaignStatus, error, loading, noticeData } = useAppSelector(
    (state) => state.campaign
  );
  const { fieldSettings } = useAppSelector((state) => state.form);
  const { loggedIn, checkType } = useAppSelector(({ user }) => user);
  const [idx, setInx] = useState<number | null>(null);

  useEffect(() => {
    if (!campaignId) {
      dispatch(setError(true));
      return;
    }

    dispatch(fetchCampaignData(campaignId, document));
  }, [dispatch, campaignId]);

  useEffect(() => {
    dispatch(clearFormValues());
    dispatch(setZipSearchResults({zipType: "user", results: []}));
    dispatch(setZipSearchResults({zipType: "delivery", results: []}));
  }, [dispatch])

  if (noticeData === undefined) {
      (async () => {
        dispatch((() => {
          return async () => {
            await axios.get(process.env.REACT_APP_BACKEND_API_URL + "/api/v1/getNoticeList").then(async (response: any) => {
              dispatch(setnoticeData(response.data));
            });
          }
        })())
      })()
  }
  if (loading) {
    return <Fragment />;
  }

  if (error || !campaignData) {
    return <>キャンペーン情報を見つけられませんでした</>;
  }

  const { campaign, presents } = campaignData;

  const formatDate = (date: string) => {
    const safariSafeDate = new Date(date.replaceAll("-", "/"));

    return `${safariSafeDate.getFullYear()}年${
      safariSafeDate.getMonth() + 1
    }月${safariSafeDate.getDate()}日（${
      ["日", "月", "火", "水", "木", "金", "土"][safariSafeDate.getDay()]
    }）`;
  };

  const handleApplication = async () => {
    if (!fieldSettings.user.show) {
      dispatch(skipRegistration(history));
      saveStoreData();

      return;
    }

    if (loggedIn) {
      dispatch(setApplicationType("login"));
      saveStoreData();

      if ( await checkMagazineJudge( checkType ) ) {
        history.push({
          pathname: "/registration",
          search: `?campaignId=${campaignId}`,
        });
      } else {
        history.push({
          pathname: "/judgeMagazine",
          search: `?campaignId=${campaignId}`,
        })
      }
    } else {
      history.push({
        pathname: "/",
        search: `?campaignId=${campaignId}`,
      });
    }
  };

  return (
    <>
      <NoticeInfo>
        {noticeData && noticeData?.notices.map((value, index)=>{
          return (
            <div>
              <NoticeTitle 
              key={index} 
              onClick={()=>{ idx === index ? setInx(null) : setInx(index) }}>
                {idx === index ? '▲' : '▼'} {value.title}
              </NoticeTitle>

              {idx === index && <NoticeContent><div dangerouslySetInnerHTML={{__html: value.content}}></div></NoticeContent>}
            </div>
          )
        })}
        <WaveIconImage
          src={OrangeWaveIcon}
          alt="Wave icon"
        />
      </NoticeInfo>

      <TitleContainer>
        <Title>{campaign.name}</Title>
      </TitleContainer>

      {campaignStatus === CampaignStatus.Public && (
        <>
          {campaign.images?.title && (
            <CampaignImage
              src={campaign.images.title}
              alt="キャンペーンタイトルPhoto"
            />
          )}

          {campaign.is_number_of_winners_display && !!presents?.length && (
            <TotalPresentInfo>
              <TotalPresentInfoText>
                抽選で
                <TotalPresentCount>
                  {presents.reduce(
                    (total, present) => total + present.number_of_winners,
                    0
                  )}
                </TotalPresentCount>
                <TotalPresentCountLabel>名</TotalPresentCountLabel>
                に<br />豪華景品をプレゼント
              </TotalPresentInfoText>
            </TotalPresentInfo>
          )}

          {campaign.images?.insert1 && (
            <InsertImage src={campaign.images.insert1} alt="差し込み画像1" />
          )}

          {campaign.period?.start && (
            <CampaignPeriodInfo>
              {campaign.period.title}
              <br />
              <CampaignPeriod>
                {formatDate(campaign.period.end)}まで
              </CampaignPeriod>
            </CampaignPeriodInfo>
          )}
        </>
      )}

      {campaign.description && (<StyledButtonTop type="button" icon="arrow" onClick={handleApplication}>応募する</StyledButtonTop>)}
      {campaign.description && (
        <Description addMargin={campaignStatus !== CampaignStatus.Public}>
          {campaign.heading && (
            <DescriptionTitle>{campaign.heading}</DescriptionTitle>
          )}

          <DescriptionText><div dangerouslySetInnerHTML={{__html: campaign.description}}></div></DescriptionText>
        </Description>
      )}

      {campaign.images?.insert2 && (
        <InsertImage src={campaign.images.insert2} alt="差し込み画像2" />
      )}

      {campaignStatus === CampaignStatus.Public && (
        <>
          {!!presents?.length && (
            <PresentSection>
              <PresentTitleContainer>
                <RibbonImage src={RibbonOrange} />

                <PresentTitle>賞品</PresentTitle>
              </PresentTitleContainer>

              <PresentContainer>
                {presents.map((present, index) => (
                  <Present present={present} key={`${present.name}-${index}`} />
                ))}
              </PresentContainer>
            </PresentSection>
          )}

          {campaign.images?.insert3 && (
            <InsertImage src={campaign.images.insert3} alt="差し込み画像3" />
          )}

          <ConditionsInfoSection>
            {campaign.conditions && (
              <Description>
                <DescriptionTitle>応募要項</DescriptionTitle>

                <DescriptionText><div dangerouslySetInnerHTML={{__html: campaign.conditions}}></div></DescriptionText>
              </Description>
            )}
          </ConditionsInfoSection>

          {campaign.images?.insert4 && (
            <InsertImage src={campaign.images.insert4} alt="差し込み画像4" />
          )}

          <StyledButton type="button" icon="arrow" onClick={handleApplication}>
            応募する
          </StyledButton>
        </>
      )}
    </>
  );
};

const TitleContainer = styled.div`
  width: 100vw;
  background-color: #ef8205;
  box-shadow: #00000029 0px 7px 6px;
`;

const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-shadow: 0px 3px 6px #00000029;
  padding: 0px 43px;
  white-space: pre-wrap;
`;

const CampaignImage = styled.img`
  max-width: 100%;
`;

const InsertImage = styled.img`
  max-width: 100%;
`;

const TotalPresentInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  background-color: #fff;
`;

const TotalPresentInfoText = styled.p`
  width: 180px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

const TotalPresentCount = styled.span`
  font-size: 36px;
  color: #ef8205;
`;

const TotalPresentCountLabel = styled.span`
  color: #ef8205;
`;

const CampaignPeriodInfo = styled.p`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
`;

const CampaignPeriod = styled.span`
  font-size: 20px;
`;

const Description = styled.div<{ addMargin?: boolean }>`
  margin-top: ${({ addMargin }) => (addMargin ? "40px" : 0)};
  background-color: #fff;
  width: 100%;
  padding: 36px 43px;
`;

const DescriptionTitle = styled.h2`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  white-space: pre-wrap;
`;

const DescriptionText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  white-space: pre-wrap;
`;

const PresentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const PresentSection = styled.div`
  width: 100%;
  padding: 30px 38px 35px 38px;
`;

const PresentTitleContainer = styled.div`
  position: relative;
  height: 38px;
  margin-bottom: 23px;
`;

const RibbonImage = styled.img`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  filter: drop-shadow(#00000029 0px 3px 6px);
`;

const PresentTitle = styled.p`
  position: absolute;
  top: 0;
  color: #fff;
  margin: 8px 0 0 0;
  width: 100%;
  text-align: center;
  height: 38px;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
`;

const ConditionsInfoSection = styled.div`
  width: 100%;
  background-color: white;
`;

const StyledButtonTop = styled(Button)`
  margin: 25px 0 25px 0;
  width: 80%;
`;

const StyledButton = styled(Button)`
  margin: 40px 0 25px 0;
  width: 80%;
`;

const WaveIconImage = styled.img`
  height: 60px;
  width: 100%;
`;

const NoticeInfo = styled.div`
  width: 100vw;
  background-color: #ffffff;
  margin-bottom: -8px;
`;

const NoticeTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding: 0px 43px;
`;

const NoticeContent = styled.div`
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  white-space: pre-wrap;
  padding: 0px 43px;
`;