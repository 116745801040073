import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { Card } from "../components/Card";
import { Label } from "../components/Label";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Newsletter } from "./Registration";
import { FormResponse, FormValue } from "../features/form/FormResponse";
import { Redirect, useHistory } from "react-router";
import { Button } from "../components/Button";
import { Gender, PetTypeEnum as PetType } from "../types/typescript-axios";
import { applyForCampaignAndRegister } from "../features/campaign/campaignSlice";
import { PageNotFound } from "./PageNotFound";
import { saveStoreData } from "../utils/saveStoreData";
import TagManager from 'react-gtm-module';

export const RegistrationConfirmation = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { email, userData, userPetData } = useAppSelector((state) => state.user);
  const { formValues, fieldSettings } = useAppSelector((state) => state.form);

  const query = new URLSearchParams(useLocation().search);
  if (!query.has("campaignId")) {
    return <PageNotFound />;
  }
  const campaignId = query.get("campaignId") as string;

  if (!formValues) {
    return (
      <Redirect
        push
        to={{ pathname: "/registration", search: "?campaignId=" + campaignId }}
      />
    );
  }

  const { user, delivery, pets } = formValues;

  const userGenderDict: Record<Gender, string> = {
    female: "女性",
    male: "男性",
    other: "無回答",
  };

  const petGenderDict: Record<Gender, string> = {
    female: "女の子",
    male: "男の子",
    other: "不明",
  };

  const petTypeDict: Record<PetType, string> = {
    dog: "犬",
    cat: "猫",
    other: "その他",
  };

  const petGenderDictLayer: Record<Gender, string> = {
    female: "メス",
    male: "オス",
    other: "不明",
  };

  const newsletterDict: Record<Newsletter, string> = {
    longevity: "長生き情報",
  };

  const hasDeliveryValues = Boolean(
    delivery?.fullName ||
      delivery?.zipCode ||
      delivery?.address?.firstPart ||
      delivery?.address?.secondPart
  );

  const handleSubmit = async () => {
    
    await dispatch(applyForCampaignAndRegister(history));
    saveStoreData();

    if (email) {
      TagManager.initialize({
        gtmId: 'GTM-TX6427',
      })

      var postal_code
      if (!fieldSettings.user.show) {
        postal_code = userData && userData.postal_code
      } else {
        postal_code = user && user.zipCode
      }
      
      var kfdb_external_id = userData?.kfdb_external_id ? userData?.kfdb_external_id : localStorage.getItem("kfdbExternalId")
      var gtmpets
      var gtmuserpets

      if (postal_code) {
        if (fieldSettings.pet.show) {
          gtmpets = pets?.filter(item => {return item.have === '1'})
          TagManager.dataLayer({
            dataLayer: {
              qg_user_id: kfdb_external_id,
              qg_email: email,
              qg_app_haspet: gtmpets && gtmpets.length > 0 ? "飼っている" : "飼っていない",
              qg_app_self_postal_code: postal_code,
            },
          });
        } else {
          gtmuserpets = userPetData?.filter(item => {return item.have === '1'})
          TagManager.dataLayer({
            dataLayer: {
              qg_user_id: kfdb_external_id,
              qg_email: email,
              qg_app_haspet: gtmuserpets && gtmuserpets.length > 0 ? "飼っている" : "飼っていない",
              qg_app_self_postal_code: postal_code,
            },
          });
        }
      } else {
        if (fieldSettings.pet.show) {
          gtmpets = pets?.filter(item => {return item.have === '1'})
          TagManager.dataLayer({
            dataLayer: {
              qg_user_id: kfdb_external_id,
              qg_email: email,
              qg_app_haspet: gtmpets && gtmpets.length > 0 ? "飼っている" : "飼っていない",
            },
          });
        } else {
          gtmuserpets = userPetData?.filter(item => {return item.have === '1'})
          TagManager.dataLayer({
            dataLayer: {
              qg_user_id: kfdb_external_id,
              qg_email: email,
              qg_app_haspet: gtmuserpets && gtmuserpets.length > 0 ? "飼っている" : "飼っていない",
            },
          });
        }
      }

      if (fieldSettings.pet.show) {
        gtmpets && gtmpets.map((pet, index) => (
          TagManager.dataLayer({
            dataLayer: {
              ['qg_app_pet_id_' + index]: pet.petId,
              ['qg_app_pet_birthday_' + index]: pet.dateOfBirth.year + "-" + ("0" + pet.dateOfBirth.month).slice(-2) + "-" + ("0" + pet.dateOfBirth.day).slice(-2) + "T00:00:00+9:000:00",
              ['qg_app_pet_dog_cat_' + index]: petTypeDict[pet.type],
              ['qg_app_pet_kind_type_' + index]: pet.breed,
              ['qg_app_pet_sex_' + index]: petGenderDictLayer[pet.gender]
            },
          })
        ))
      } else {
        gtmuserpets && gtmuserpets.map((pet, index) => (
          TagManager.dataLayer({
            dataLayer: {
              ['qg_app_pet_id_' + index]: pet.id,
              ['qg_app_pet_birthday_' + index]: pet.birthday + "T00:00:00+9:000:00",
              ['qg_app_pet_dog_cat_' + index]: petTypeDict[pet.type],
              ['qg_app_pet_kind_type_' + index]: pet.breed,
              ['qg_app_pet_sex_' + index]: petGenderDictLayer[pet.gender]
            },
          })
        ))
      }
    }
  };

  return (
    <>
      <Title>お客様情報確認</Title>
      <NextButton type="button" icon="arrow" onClick={handleSubmit}>
        応募する
      </NextButton>

      {user && (
        <StyledCard>
          <SectionTitle>応募者情報</SectionTitle>

          <FormResponse
            label="氏名（漢字）"
            value={`${user.surname} ${user.name}`}
          />

          {(user.surnameKatakana || user.nameKatakana) && (
            <FormResponse
              label="氏名（カタカナ）"
              value={`${user.surnameKatakana} ${user.nameKatakana}`}
            />
          )}

          {user.gender && (
            <FormResponse label="性別" value={userGenderDict[user.gender]} />
          )}

          {user.dateOfBirth && (
            <FormResponse
              label="生年月日"
              value={(() => {
                if (!user.dateOfBirth.year || !user.dateOfBirth.month || !user.dateOfBirth.day) {
                  return "";
                }
                return `${user.dateOfBirth.year}年 ${user.dateOfBirth.month}月  ${user.dateOfBirth.day}日`;
              })()}
            />
          )}

          {user.phoneNumber && (
            <FormResponse
              label="電話番号"
              value={(() => {
                if (!user.phoneNumber.firstPart || !user.phoneNumber.secondPart || !user.phoneNumber.thirdPart) {
                  return "";
                }
                return `${user.phoneNumber.firstPart}${user.phoneNumber.secondPart}${user.phoneNumber.thirdPart}`;
              })()}
            />
          )}

          <FormResponse
            label="郵便番号（ハイフンなしの7桁）"
            value={user.zipCode}
          />

          <FormResponse label="住所" value={user.address?.firstPart} />

          <FormResponse label="住所2" value={user.address?.secondPart} />

          <FormResponse label="メールアドレス" value={email} />

          <FormResponse
            label="いぬ・ねこのきもちからペットに関するサービスや 商品のご案内を"
            value={user.subscribeToNewsletter ? "受け取る" : "受け取らない"}
          />

          {!!user.newsletters.length && <Label>追加で入手したい情報</Label>}

          {user.newsletters.map((newsletter) => (
            <FormValue key={`newsletter-${newsletter}`}>
              {newsletterDict[newsletter]}
            </FormValue>
          ))}

          {user.password && (
            <FormResponse
              label="パスワード"
              value={user.password.firstTime
                ?.split("")
                .map(() => "・")
                .join("")}
            />
          )}
        </StyledCard>
      )}

      {delivery && hasDeliveryValues && (
        <StyledCard>
          <SectionTitle>お届け先情報</SectionTitle>

          <FormResponse label="届け先氏名" value={delivery.fullName} />

          <FormResponse
            label="電話番号"
            value={(() => {
              if (!delivery.phoneNumber?.firstPart || !delivery.phoneNumber?.secondPart || !delivery.phoneNumber?.thirdPart) {
                return '';
              }
              return `${delivery.phoneNumber.firstPart}${delivery.phoneNumber.secondPart}${delivery.phoneNumber.thirdPart}`;
            })()}
          />

          <FormResponse
            label="郵便番号（ハイフンなしの7桁）"
            value={delivery.zipCode}
          />

          <FormResponse label="住所" value={delivery.address?.firstPart} />

          <FormResponse label="住所2" value={delivery.address?.secondPart} />
        </StyledCard>
      )}

      {pets && !!pets.length && (
        <StyledCard>
          <SectionTitle>ペット情報</SectionTitle>

          {pets.map((pet, index) => (
            <PetSection key={`${pet}-${index}`}>
              <FormResponse label="飼育状況" value={pet.have === "1" ? "飼っている" : "飼う予定"} />

              <FormResponse label="ペット名" value={pet.name} />

              {pet.gender && pet.have === "1" && (
                <FormResponse
                  label="ペットの性別"
                  value={petGenderDict[pet.gender]}
                />
              )}

              {pet.dateOfBirth && pet.have === "1" && (
                <FormResponse
                  label="ペットの誕生日"
                  value={`${pet.dateOfBirth.year}年 ${pet.dateOfBirth.month}月 ${pet.dateOfBirth.day}日`}
                />
              )}

              {pet.type && pet.have === "1" && (
                <FormResponse
                  label="ペットの種類"
                  value={petTypeDict[pet.type]}
                />
              )}

              {pet.type2 && pet.have === "0" &&(
                <FormResponse
                  label="飼う予定のペットの種類"
                  value={petTypeDict[pet.type2]}
                />
              )}

              {pet.have === "1" && (
                <FormResponse label="ペットの品種" value={pet.breed} />
              )}
            </PetSection>
          ))}
        </StyledCard>
      )}

      {!user && !(delivery && hasDeliveryValues) && !(pets && !!pets.length) && (
        <StyledCard>
          <p>登録する情報はありません。</p>
        </StyledCard>
      )}

      <NextButton type="button" icon="arrow" onClick={handleSubmit}>
        応募する
      </NextButton>

      <BackButton
        type="button"
        color="white"
        onClick={() =>
          history.push({
            pathname: "/registration",
            search: `?campaignId=${campaignId}`,
            state: {referrer: "/registrationConfirm"},
          })
        }
      >
        修正する
      </BackButton>
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
`;

const StyledCard = styled(Card)`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
`;

const SectionTitle = styled.h3`
  text-align: center;
  font-size: 20px;
  margin: 0 0 10px 0;
  font-weight: 600;
`;

const NextButton = styled(Button)`
  width: 80%;
`;

const BackButton = styled(Button)`
  width: 80%;
  margin: 35px 0 55px 0;
`;

const PetSection = styled.div`
  &:not(:last-of-type) {
    border-bottom: 2px dashed #707070;
    margin-bottom: 15px;
  }
`;
