import { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Button } from "../components/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { Card } from "../components/Card";
import { Label } from "../components/Label";
import { Input } from "../components/Input";
import { registerEmail } from "../features/user/userSlice";
import { useAppDispatch } from "../app/hooks";
import { regExps } from "../utils/regExps";
import { saveStoreData } from "../utils/saveStoreData";
import { clearFormValues, setZipSearchResults } from "../features/form/formSlice";
import { PageNotFound } from "./PageNotFound";

interface EmailInput {
  email: string;
}

export const EmailRegistration = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { register, handleSubmit } = useForm<EmailInput>({});

  useEffect(() => {
    dispatch(clearFormValues());
    dispatch(setZipSearchResults({zipType: "user", results: []}));
    dispatch(setZipSearchResults({zipType: "delivery", results: []}));
  }, [dispatch])

  const query = new URLSearchParams(useLocation().search);
  if (!query.has('campaignId')) {
    return <PageNotFound />;
  }
  const campaignId = query.get('campaignId') as string;

  const onSubmit: SubmitHandler<EmailInput> = async (data) =>{
    await dispatch(registerEmail(data.email, history, campaignId));
    saveStoreData();
  }

  return (
    <>
      <Title>メールアドレス登録</Title>

      <Description>
        ご入力いただいたメールアドレス宛に、「ワンタイムパスワード」を記載したメールを送りします。以下の欄にご希望のメールアドレスを入力してください。
      </Description>
      <Description>
        ドメイン指定受信設定等をされている方は「@mail.inm.benesse.ne.jp」からのメールを受信できるように設定をお願いいたします。
        受信設定を変更しても受信できない場合は、携帯電話会社やインターネット・サービス・プロバイダにお問い合わせください。
      </Description>
      <Description>
        ○○@docomo.ne.jpや○○@au.com、○○@softbank.ne.jpなどのキャリアメールのご登録はワンタイムパスワードをお届けできない場合があるため、推奨しておりません。
      </Description>


      <EmailCard>
        <EmailForm onSubmit={handleSubmit(onSubmit)}>
          <Label>メールアドレス登録</Label>

          <Input
            type="email"
            {...register("email", {
              required: true,
              pattern: regExps.email,
            })}
            placeholder=" "
          />

          <SubmitButton icon="arrow">送信</SubmitButton>
        </EmailForm>

      </EmailCard>
    </>
  );
};

const Title = styled.h2`
  width: 60%;
  text-align: center;
  font-size: 20px;
`;

const Description = styled.div`
  text-align: center;
  width: 80%;
  font-size: 12px;
  margin-bottom: 30px;
`;

const WarningText = styled.div`
  font-size: 12px;
`;

const SubmitButton = styled(Button)`
  margin: 30px 0;
`;

const EmailCard = styled(Card)`
  margin-bottom: 50px;
  width: inherit;
`;

const EmailForm = styled.form`
  display: flex;
  flex-direction: column;
`;
