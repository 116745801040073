import { useHistory } from "react-router";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Label } from "../components/Label";
import { Input } from "../components/Input";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { confirmOtp } from "../features/user/userSlice";
import { PageNotFound } from "./PageNotFound";
import { useLocation } from "react-router-dom";
import { saveStoreData } from "../utils/saveStoreData";

export interface OTPConfirmationInput {
  otp: string;
}

export const OTPConfirmation = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<OTPConfirmationInput>({});
  const dispatch = useAppDispatch();
  const { otpType } = useAppSelector(({ user }) => user);

  const query = new URLSearchParams(useLocation().search);
  if (!query.has("campaignId") && !query.has("noCampaignPath")) {
    return <PageNotFound />;
  }
  const campaignId = query.get("campaignId") as string;
  const noCampaignPath = query.get("noCampaignPath") as string;

  const onSubmit: SubmitHandler<OTPConfirmationInput> = async (data) => {
    await dispatch(
      confirmOtp(data.otp, history, campaignId, noCampaignPath, setError)
    );
    saveStoreData();
  };

  return (
    <>
      <Title>ワンタイムパスワード入力</Title>

      {otpType === "registration" && (
        <Description>
          <b>まだ登録は完了していません。</b>
          <br />
          ご登録いただいたメールアドレスにワンタイムパスワードを送信しました。メール本文に記載されているワンタイムパスワードを入力し、情報登録を完了してください。
        </Description>
      )}

      <OTPCard>
        <OTPForm onSubmit={handleSubmit(onSubmit)}>
          <Label>ワンタイムパスワード</Label>

          <ErrorContainer>
            <Input
              type="number"
              {...register("otp", {
                required: true,
              })}
              placeholder=" "
              error={!!errors.otp?.message}
            />

            {errors.otp?.type === "api" && (
              <ErrorMessage>{errors.otp?.message}</ErrorMessage>
            )}
          </ErrorContainer>

          <SubmitButton icon="arrow">送信</SubmitButton>

        </OTPForm>
      </OTPCard>
    </>
  );
};

const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 20px;
`;

const Description = styled.div`
  text-align: center;
  width: 80%;
  font-size: 12px;
  margin-bottom: 30px;
`;

const WarningText = styled.div`
  font-size: 12px;
`;

const SubmitButton = styled(Button)`
  margin: 30px 0;
`;

const OTPCard = styled(Card)`
  margin-bottom: 50px;
  width: inherit;
`;

const OTPForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: #d01414;
  font-size: 14px;
`;
