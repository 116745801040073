/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import styled from "styled-components";
import { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { useAppSelector } from "../app/hooks";
import { useHistory } from "react-router";
import { User } from "../features/form/formSlice";
import { Pet, Delivery } from "../features/form/formSlice";
import { Radio } from "../components/Radio";
import {
  fetchCampaignData,
  setError as setCampaignError,
} from "../features/campaign/campaignSlice";
import { PageNotFound } from "./PageNotFound";
import { RegistrationForm } from "../features/campaign/RegistrationForm";
import { fetchUserData } from "../features/user/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";

export type Newsletter = "longevity";

export interface DateOfBirth {
  year: string;
  month: string;
  day: string;
}

interface FormUser extends User {
  email?: string;
}

export interface RegistrationInputs {
  user: FormUser;
  delivery: Delivery;
  pets: Pet[];
}

export const Registration = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const { campaignData, error, currentCampaignId } = useAppSelector(
    (state) => state.campaign
  );
  const { loggedIn } = useAppSelector((state) => state.user);

  const queryParam = useLocation().search;
  useEffect(() => {
    (async () => {
      const query = new URLSearchParams(queryParam);
      const campaignId = query.get("campaignId");
      if (!campaignId) {
        dispatch(setCampaignError(true));
        return;
      }

      await dispatch(fetchCampaignData(campaignId, document));

      if (loggedIn) {
        const response = await dispatch(fetchUserData({ history, campaignId }));
        const isTermsAgreed = unwrapResult(response).user.is_terms_agreed;
        if (isTermsAgreed === 0) {
          // 規約未同意の場合はリダイレクト
          history.push({
            pathname: "/userAgreement",
            search: queryParam,
          });
        }
      }

      setLoading(false);
    })();
  }, [loggedIn, dispatch, queryParam, setLoading, history]);

  if (loading) {
    return <Fragment />;
  }

  if (error || !campaignData || !currentCampaignId) {
    return <PageNotFound />;
  }

  return <RegistrationForm />;
};

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PasswordToggleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledRadio = styled(Radio)`
  margin-right: 20px;
`;

export const OptionDescription = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  padding: 0 5px;
  margin-bottom: 20px;
`;
