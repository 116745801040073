import styled from "styled-components";
import { Card } from "../components/Card";

export const FailureScreen = () => { 
  return (
    <>
    <Card>
      <Title>システム障害発生のご報告とお詫び</Title>
      <DescriptionText>
        <p>現在、システム障害が発生しており、</p>
        <p>サービスをご利用いただけない</p>
        <p>事象が発生しております。</p>
        <p>復旧次第、お知らせいたしますので</p>
        <p>今しばらくお待ちくださいますよう</p>
        <p>お願い申し上げます。</p>
        <p>ご不便をおかけし申し訳ございませんが</p>
        <p>何卒よろしくお願いいたします。</p>
      </DescriptionText>
    </Card>
    </>
  );
}

const Title = styled.h2`
  text-align: center;
  font-size: 16px;
`;

const DescriptionText = styled.p`
  font-size: 14px;
  text-align: center;
`;
