import styled from "styled-components";

export const PageNotFound = () => { 
  return (
    <>
      <Title>404</Title>
      <DescriptionText>該当のページが見つかりませんでした</DescriptionText>
    </>
  );
}

const Title = styled.h2`
  text-align: center;
  font-size: 20px;
`;

const DescriptionText = styled.p`
  font-size: 12px;
`;
