import React from "react";
import styled from "styled-components";

import ProgressOn from "../../../assets/images/progress_on@3x.png";
import ProgressOff from "../../../assets/images/progress_off@3x.png";

interface StepProps {
  active?: boolean;
}

export const Step: React.FC<StepProps> = ({ active, children }) => (
  <StepContainer>
    <StepImage src={active ? ProgressOn : ProgressOff} />

    <StepTitle active={active}>{children}</StepTitle>
  </StepContainer>
);

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 125px;
  height: 30px;

  &:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const StepImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const StepTitle = styled.div<StepProps>`
  z-index: 10;
  color: ${({ active }) => (active ? "#fff" : "#707070")};
  font-size: 11px;
  line-height: 13px;
`;
