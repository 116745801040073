import React from "react";
import styled from "styled-components";

interface RadioProps {
  isSelected: boolean;
  onClick: () => void;
  label: string;
  className?: string;
}

export const Radio: React.FC<RadioProps> = ({
  isSelected,
  onClick,
  label,
  className,
}) => (
  <Container onClick={onClick} className={className}>
    <CheckMark isSelected={isSelected} />

    <Label>{label}</Label>
  </Container>
);

const Container = styled.div`
  display: flex;
  cursor: pointer;

  /** Safariのちらつき対策 */
  -webkit-tap-highlight-color: transparent;
`;

const CheckMark = styled.div<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? "#EF8205" : "#D5D5D5")};
  height: 23px;
  width: 23px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;

  :after {
    display: ${({ isSelected }) => (isSelected ? "block" : "none")};
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
  }
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 23px;
`;
