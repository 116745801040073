import styled from "styled-components";
import { useHistory, Link, useLocation } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, Fragment, useState } from "react";

import { Card } from "../components/Card";
import { Button } from "../components/Button";
import { Label } from "../components/Label";
import { Input } from "../components/Input";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { login, setApplicationType } from "../features/user/userSlice";
import { skipRegistration } from "../features/campaign/campaignSlice";
import {
  fetchCampaignData,
  setError as setCampaignError,
} from "../features/campaign/campaignSlice";
import { PageNotFound } from "./PageNotFound";
import { saveStoreData } from "../utils/saveStoreData";
import { clearFormValues, setZipSearchResults } from "../features/form/formSlice";
import eyeOpen from "../assets/images/eyeOpen.svg";
import eyeClose from "../assets/images/eye-close.svg";

export interface LoginInput {
  loginId: string;
  password: string;
}

export const Login = () => {

  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  }

  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginInput>({});
  const { fieldSettings } = useAppSelector((state) => state.form);

  const handleRegistration = () => {
    dispatch(setApplicationType("registration"));
    saveStoreData();
    history.push({
      pathname: "/userAgreement",
      search: `?campaignId=${currentCampaignId}`,
    });
  };

  const handleSkipRegistration = async () => {
    await dispatch(skipRegistration(history));
    saveStoreData();
  };

  const { campaignData, error, loading, currentCampaignId } = useAppSelector(
    (state) => state.campaign
  );
  const queryParam = useLocation().search;
  useEffect(() => {
    if (campaignData == null) {
      const query = new URLSearchParams(queryParam);
      const campaignId = query.get("campaignId");
      if (!campaignId) {
        dispatch(setCampaignError(true));
        return;
      }

      dispatch(fetchCampaignData(campaignId, document));
    }
  }, [dispatch, queryParam, campaignData]);

  useEffect(() => {
    dispatch(clearFormValues());
    dispatch(setZipSearchResults({zipType: "user", results: []}));
    dispatch(setZipSearchResults({zipType: "delivery", results: []}));
  }, [dispatch])

  if (loading) {
    return <Fragment />;
  }

  const query = new URLSearchParams(queryParam);
  const noCampaignPath = query.get("noCampaignPath");
  const resetState = query.get("resetState");
  if (
    (error || !campaignData || !currentCampaignId) &&
    noCampaignPath === null
  ) {
    return <PageNotFound />;
  }

  const trustedTerminalId = localStorage.getItem("trustedTerminalId");
    
  if (trustedTerminalId === null) {
    
    let prefix = "trustedTerminalId="
    let start = document.cookie.indexOf(prefix)

    if (start !== -1) {
      let end = document.cookie.indexOf(";", start + prefix.length)
      if (end === -1) {
          end = document.cookie.length;
      }
      const cookieTrustedTerminalId = document.cookie.substring(start + prefix.length, end)

      localStorage.setItem("trustedTerminalId", cookieTrustedTerminalId);
    }
  }

  const onSubmit: SubmitHandler<LoginInput> = async (data) => {
    await dispatch(
      login(
        data.loginId,
        data.password,
        history,
        currentCampaignId,
        noCampaignPath,
        setError
      )
    );
    saveStoreData();
  };

  const passwordForgatLink = "/forgotPassword/?campaignId=" + (currentCampaignId ?? "") + "&noCampaignPath=" + (noCampaignPath ?? "");

  return (
    <>
      <Title>ログイン{noCampaignPath ? "" : "・新規登録"}</Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <LoginCard>
          <Description>
            {!noCampaignPath && (
              <DescriptionTitle>会員の方はこちら</DescriptionTitle>
            )}

            <DescriptionText>
              本サービスは
              <StyledOutsideLink href="https://petcare.benesse.ne.jp/faq/81845">
                「いぬ・ねこのきもち共通ペットID」
              </StyledOutsideLink>
              を利用しています。対象サービスをすでに利用している方は、登録済みのペット情報を利用可能です。
            </DescriptionText>
            <DescriptionText>
              パスワードが分からなくなった場合は、登録済みのサービスにてパスワード再設定をしてください。
            </DescriptionText>
          </Description>

          <ResetPassword>
            {resetState === "completeResetPassword" && (
              <ErrorMessage>パスワード変更が完了しました、再度ログインしてご利用ください</ErrorMessage>
            )}
          </ResetPassword>

          <Label>ログインID（メールアドレス）</Label>

          <Input
            {...register("loginId", {
              required: true,
            })}
            placeholder=" "
            error={!!errors.loginId}
          />

          <Label>パスワード</Label>

          <ErrorContainer>
            <Input
              type={isRevealPassword ? 'text' : 'password'}
              {...register("password", {
                required: true,
              })}
              placeholder=" "
              error={!!errors.password}
              icon={true}
            />
            <PasswordReveal>
              <span 
                onClick={togglePassword}
                role="presentation"
              >
                {isRevealPassword ? (
                  <SmallEyes src={eyeOpen} />
                ) : (
                  <SmallEyes src={eyeClose} />
                )}
              </span>
            </PasswordReveal>

            {errors.password?.type === "api" && (
              <ErrorMessage>{errors.password?.message}</ErrorMessage>
            )}
          </ErrorContainer>

          <LoginButton icon="arrow">ログイン</LoginButton>

          <ForgotPasswordText>
            <StyledLink to={passwordForgatLink}>
              パスワードを忘れた方はこちら
            </StyledLink>
          </ForgotPasswordText>
        </LoginCard>
      </Form>

      {!noCampaignPath && (
        <RegistrationCard>
          <Description>
            <DescriptionTitle>会員登録がお済みでない方</DescriptionTitle>

            <DescriptionText>
              新規登録はこちらからお願いします。 
              <StyledOutsideLink href="https://petcare.benesse.ne.jp/faq/81845">
                「いぬ・ねこのきもち共通ペットID」
              </StyledOutsideLink>
              をご利用の場合は上記のログインからお進みください。
            </DescriptionText>
          </Description>

          <Button onClick={handleRegistration} type="button" icon="arrow">
            新規会員登録
          </Button>

          {!fieldSettings.user.required && (
            <SkipRegistrationButton
              onClick={handleSkipRegistration}
              type="button"
              icon="arrow"
              color="white"
            >
              登録せずに応募する
            </SkipRegistrationButton>
          )}
        </RegistrationCard>
      )}

      <InunekoAccountInfoLink href="http://cat-dog.love/inm0001">
        いぬ・ねこのきもち応募会員とは
      </InunekoAccountInfoLink>
    </>
  );
};

const Form = styled.form`
  width: 100%;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 20px;
`;

const Description = styled.div`
  margin-bottom: 30px;
  padding: 0;
`;

const DescriptionTitle = styled.h3`
  text-align: center;
  margin: 0;
`;

const DescriptionText = styled.p`
  font-size: 12px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const StyledOutsideLink = styled.a`
  text-decoration: underline;
  color :blue;
`;

const LoginCard = styled(Card)`
  width: 100%;
`;

const LoginButton = styled(Button)`
  margin: 30px 0 10px 0;
`;

const SkipRegistrationButton = styled(Button)`
  margin: 40px 0 15px 0;
`;

const ForgotPasswordText = styled(DescriptionText)`
  text-align: center;
  margin-bottom: 0;
`;

const RegistrationCard = styled(Card)`
  width: 100%;
  margin-top: 20px;
`;

const InunekoAccountInfoLink = styled.a`
  font-size: 14px;
  text-decoration: underline;
  color: #0a7fbe;
  margin: 20px 0 30px 0;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ResetPassword = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: #d01414;
  font-size: 14px;
`;
const PasswordReveal = styled.div`
  position: absolute;
  height: 30px;
  right:5px;
  top:9px;
  cursor: pointer;
`;
const SmallEyes = styled.img`
  height: 30px;
  width: 30px;
`;