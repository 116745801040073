import styled from "styled-components";
import { Redirect } from "react-router";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import { fetchNewsletterSettings } from "../features/user/userSlice";
import { NewslettersForm } from "../features/form/NewslettersForm";

export const UpdateNewsletters = () => {
  const dispatch = useAppDispatch();
  const { loggedIn, loading } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchNewsletterSettings());
    }
  }, [dispatch, loggedIn]);

  if (!loggedIn) {
    return <Redirect push to="/?noCampaignPath=updateNewsletters" />;
  }

  return (
    <>
      <Title>登録内容変更</Title>

      {loading === false && <NewslettersForm />}
    </>
  );
};

const Title = styled.h1`
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 50px;
`;
