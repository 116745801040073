import React from "react";
import styled from "styled-components";

import { Label } from "../../components/Label";

interface FormResponseProps {
  label: string;
  value?: string | number;
}

export const FormResponse: React.FC<FormResponseProps> = ({ label, value }) => (
  <>
    {value && (
      <>
        <Label>{label}</Label>

        <FormValue>{value}</FormValue>
      </>
    )}
  </>
);

export const FormValue = styled.div`
  word-break: break-all;
  font-size: 18px;
  line-height: 25px;
  font-weight: 300;
  margin: 15px 0 20px 20px;
`;
