import styled from "styled-components";
import { useHistory } from "react-router";
import { SubmitHandler, useForm } from "react-hook-form";

import { Card } from "../../components/Card";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Newsletters, NewslettersValues } from "./Newsletters";
import { Subscription } from "./formSlice";
import { Newsletter } from "../../pages/Registration";
import { Button } from "../../components/Button";
import { instantiateUserApi } from "../../utils/apiWrappers";
import { ApiError, handleError } from "../../utils/handleError";
import { resetCampaignState } from "../campaign/campaignSlice";
import { resetFormState } from "../form/formSlice";
import { resetUserState } from "../user/userSlice";

export const NewslettersForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { userData } = useAppSelector((state) => state.user);
  const { handleSubmit, control } = useForm<NewslettersValues>({
    defaultValues: {
      user: {
        subscribeToNewsletter:
          userData?.is_subscription_mail_magazine ===
            Subscription.NotSubscribed ||
          userData?.is_subscription_mail_magazine === null
            ? false
            : true,
        newsletters: [
          ...(userData?.is_need_senior_lead === Subscription.NotSubscribed ||
          userData?.is_need_senior_lead === null
            ? []
            : (["longevity"] as Newsletter[])),
        ],
      },
    },
  });

  const onSubmit: SubmitHandler<NewslettersValues> = async ({ user }) => {
    const userApi = instantiateUserApi();

    try {
      const res = await userApi.putApiV1UserOpt({
        is_senior_lead: user.newsletters.includes("longevity"),
        is_subscription_mail_magazine: user.subscribeToNewsletter,
      });
      if (res.data.maintain_state === 1) {
        history.push({
          pathname: "/maintenancePage",
          search: `?noCampaignPath=maintenancePage`,
        })
      } else {
        history.push("/updateNewslettersComplete");
      }
    } catch (err) {
      handleError(err as ApiError);

      if ((err as ApiError).response?.status === 401 || (err as ApiError).response?.status === 404 || (err as ApiError).response?.status === 409) {
        history.push({
          pathname: "/",
          search: `?noCampaignPath=updateNewsletters`,
        });
      }
      if ((err as ApiError).response?.status === 403) {
        dispatch(resetFormState());
        dispatch(resetCampaignState());
        dispatch(resetUserState());

        localStorage.clear();
        sessionStorage.clear();

        history.push("/?noCampaignPath=updateNewsletters");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <StyledCard>
        <Newsletters control={control} />

        <UpdateButton icon="arrow">変更する</UpdateButton>
      </StyledCard>
    </form>
  );
};

const StyledCard = styled(Card)`
  padding-top: 30px;
`;

const UpdateButton = styled(Button)`
  width: 80%;
  margin-bottom: 45px;
`;
