import { toast } from "react-toastify";

export interface ApiError {
  response?: {
    data?: {
      error?: {
        message?: string;
      };
      message?: string;
      is_need_login?: boolean;
    };
    status: number;
    statusText: string;
  };
}

export const handleError = (e: ApiError) => {
  if (e?.response?.data?.error?.message) {
    toast.error(e.response.data.error.message, {autoClose: 10000});
  } else if (e?.response?.data?.message) {
    toast.error(e.response.data.message, {autoClose: 10000});
  } else if (e?.response?.statusText) {
    toast.error(e.response.statusText, {autoClose: 10000});
  } else {
    toast.error("不明なエラーが発生しました", {autoClose: 10000});
  }
};
