/* tslint:disable */
/* eslint-disable */
/**
 * プレキャンAPI
 * 説明
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 届け先情報
 * @export
 * @interface ApiV1CampaignsIdApplicationDelivery
 */
export interface ApiV1CampaignsIdApplicationDelivery {
    /**
     * 届け先の名前
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationDelivery
     */
    'name'?: string;
    /**
     * 郵便番号<br> XXXXXXX の形式
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationDelivery
     */
    'postal_code'?: string;
    /**
     * 住所１
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationDelivery
     */
    'address1'?: string;
    /**
     * 住所２
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationDelivery
     */
    'address2'?: string;
}
/**
 * ユーザ情報
 * @export
 * @interface ApiV1CampaignsIdApplicationUser
 */
export interface ApiV1CampaignsIdApplicationUser {
    /**
     * 姓
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'last_name': string;
    /**
     * 名
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'first_name': string;
    /**
     * セイ
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'last_name_kana'?: string;
    /**
     * メイ
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'first_name_kana'?: string;
    /**
     * 
     * @type {Gender}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'gender'?: Gender;
    /**
     * 日付<br> YYYY-MM-DD の形式
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'birthday'?: string;
    /**
     * 電話番号<br> 「090-1111-1111」など、ハイフン区切りの形式
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'telephone_number'?: string;
    /**
     * 郵便番号<br> XXXXXXX の形式
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'postal_code'?: string;
    /**
     * 住所１
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'address1'?: string;
    /**
     * 住所２
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'address2'?: string;
    /**
     * パスワード
     * @type {string}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'password'?: string;
    /**
     * 長生き情報を入手したいかどうか<br> 指定しない - 変更しない<br> true - チェック<br> false - 未チェック
     * @type {boolean}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'is_senior_lead'?: boolean;
    /**
     * 「いぬ・ねこのきもち」のメルマガについて<br> 指定しない - 変更しない<br> false - 受け取らない<br> true - 受け取る
     * @type {boolean}
     * @memberof ApiV1CampaignsIdApplicationUser
     */
    'is_subscription_mail_magazine'?: boolean;
}
/**
 * 性別<br> male=男性、female=女性、other=無回答
 * @export
 * @enum {string}
 */

export enum Gender {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * ユーザID
     * @type {string}
     * @memberof InlineObject
     */
    'user_id': string;
    /**
     * パスワード
     * @type {string}
     * @memberof InlineObject
     */
    'password': string;
    /**
     * 信頼済み端末ID
     * @type {string}
     * @memberof InlineObject
     */
    'trusted_terminal_id'?: string;
    /**
     * プレキャン登録を確認する<br> true -  プレキャン登録が未だの場合404エラーになる<br> false - プレキャン登録に関わらず、ログインを行う<br> 未指定 - falseと同じ
     * @type {boolean}
     * @memberof InlineObject
     */
    'check_precam'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * メールアドレス
     * @type {string}
     * @memberof InlineObject1
     */
    'mail_address': string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * ワンタイムパスワード
     * @type {string}
     * @memberof InlineObject2
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {ApiV1CampaignsIdApplicationUser}
     * @memberof InlineObject3
     */
    'user'?: ApiV1CampaignsIdApplicationUser;
    /**
     * 
     * @type {ApiV1CampaignsIdApplicationDelivery}
     * @memberof InlineObject3
     */
    'delivery'?: ApiV1CampaignsIdApplicationDelivery;
    /**
     * ペット情報
     * @type {Array<Pet>}
     * @memberof InlineObject3
     */
    'pets'?: Array<Pet>;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * ワンタイムパスワード
     * @type {string}
     * @memberof InlineObject4
     */
    'otp'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 「個人情報取扱規約」と「利用規約」に同意したならtrue
     * @type {boolean}
     * @memberof InlineObject5
     */
    'is_terms_agreed': boolean;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * メールアドレス
     * @type {string}
     * @memberof InlineObject6
     */
    'mail_address': string;
    /**
     * ユーザ登録時の姓
     * @type {string}
     * @memberof InlineObject6
     */
    'last_name': string;
    /**
     * ユーザ登録時の名
     * @type {string}
     * @memberof InlineObject6
     */
    'first_name': string;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * ワンタイムパスワード
     * @type {string}
     * @memberof InlineObject7
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * パスワード
     * @type {string}
     * @memberof InlineObject8
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 長生き情報を入手したいかどうか<br> 指定しない - 変更しない<br> true - チェック<br> false - 未チェック
     * @type {boolean}
     * @memberof InlineObject9
     */
    'is_senior_lead'?: boolean;
    /**
     * 「いぬ・ねこのきもち」のメルマガについて<br> 指定しない - 変更しない<br> false - 受け取らない<br> true - 受け取る
     * @type {boolean}
     * @memberof InlineObject9
     */
    'is_subscription_mail_magazine'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * OTPの確認必要フラグ。true=必要、false=不要＆ログイン成功
     * @type {boolean}
     * @memberof InlineResponse200
     */
    'is_need_otp': boolean;
    /**
     * 
     * @type {InlineResponse200User}
     * @memberof InlineResponse200
     */
    'user': InlineResponse200User;
    /**
     * 0個以上のペット情報
     * @type {Array<Pet>}
     * @memberof InlineResponse200
     */
    'pets': Array<Pet>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * ユーザ登録要件（1=非表示、2=任意、3=必須）
     * @type {number}
     * @memberof InlineResponse2001
     */
    'user_registration_requirements': number;
    /**
     * 本誌判定要件（0=無、1=有：オープン（現＋元＋非）、2=有：購読者限定（現＋元）、3=有：購読者限定（現）、4=有：いぬ現購読者限定（現）、5=有：ねこ現購読者限定（現））
     * @type {number}
     * @memberof InlineResponse2001
     */
     'check_magazine'?: number;
    /**
     * ユーザ登録しない人向けの同意文<br> user_registration_requirements が `非表示` または `任意` の時に存在する
     * @type {string}
     * @memberof InlineResponse2001
     */
    'confirm_sentence'?: string;
    /**
     * 住所入力要件（1=非表示、2=任意、3=必須）
     * @type {number}
     * @memberof InlineResponse2001
     */
    'address_requirements': number;
    /**
     * ペットデータ入力要件（1=非表示、2=任意、3=必須）
     * @type {number}
     * @memberof InlineResponse2001
     */
    'petdata_requirements': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * ランディングページの状態（1=応募期間前、2=応募期間中、3=応募期間終了）
     * @type {number}
     * @memberof InlineResponse2002
     */
    'status': number;
    /**
     * 
     * @type {InlineResponse2002Campaign}
     * @memberof InlineResponse2002
     */
    'campaign': InlineResponse2002Campaign;
    /**
     * プレゼント情報の配列
     * @type {Array<InlineResponse2002Presents>}
     * @memberof InlineResponse2002
     */
    'presents': Array<InlineResponse2002Presents>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
 export interface InlineResponse2005 {
     /**
      * お知らせ情報の配列
      * @type {Array<InlineResponse2005Presents>}
      * @memberof InlineResponse2005
      */
     'notices': Array<InlineResponse2005Presents>;
}
/**
 * キャンペーン情報
 * @export
 * @interface InlineResponse2002Campaign
 */
export interface InlineResponse2002Campaign {
    /**
     * キャンペーン名
     * @type {string}
     * @memberof InlineResponse2002Campaign
     */
    'name': string;
    /**
     * 見出し<br> statusが`応募期間中`の時 - 見出しテキスト<br> それ以外 - 未定義
     * @type {string}
     * @memberof InlineResponse2002Campaign
     */
    'heading'?: string;
    /**
     * 説明<br> statusが`応募期間中`の時 - 存在 or 未定義の場合があり、未定義なら表示しない）<br> statusが`応募期間前`の時 - 未定義<br> statusが`応募期間終了`の時 - 未定義
     * @type {string}
     * @memberof InlineResponse2002Campaign
     */
    'description'?: string;
    /**
     * 
     * @type {InlineResponse2002CampaignPeriod}
     * @memberof InlineResponse2002Campaign
     */
    'period'?: InlineResponse2002CampaignPeriod;
    /**
     * 応募条件<br> statusが`応募期間中`の時 - 応募条件<br> それ以外 - 未定義
     * @type {string}
     * @memberof InlineResponse2002Campaign
     */
    'conditions'?: string;
    /**
     * 当選者数の表示フラグ<br> false - 表示しない<br> true - 表示する<br> 未定義 - statusが`応募期間前`or`応募期間終了`の時は未定義になる
     * @type {boolean}
     * @memberof InlineResponse2002Campaign
     */
    'is_number_of_winners_display'?: boolean;
    /**
     * 
     * @type {InlineResponse2002CampaignImages}
     * @memberof InlineResponse2002Campaign
     */
    'images'?: InlineResponse2002CampaignImages;
    /**
     * URI<br> 「http://...」または「https://...」の形式
     * @type {string}
     * @memberof InlineResponse2002Campaign
     */
    'questionnaire_url': string;
}
/**
 * キャンペーンの画像URL<br> statusが`応募期間中`の時 - titleは有効、insert*は任意<br> それ以外 - 未定義
 * @export
 * @interface InlineResponse2002CampaignImages
 */
export interface InlineResponse2002CampaignImages {
    /**
     * タイトル画像のURL
     * @type {string}
     * @memberof InlineResponse2002CampaignImages
     */
    'title': string;
    /**
     * 差し込み画像1のURL
     * @type {string}
     * @memberof InlineResponse2002CampaignImages
     */
    'insert1'?: string;
    /**
     * 差し込み画像2のURL
     * @type {string}
     * @memberof InlineResponse2002CampaignImages
     */
    'insert2'?: string;
    /**
     * 差し込み画像3のURL
     * @type {string}
     * @memberof InlineResponse2002CampaignImages
     */
    'insert3'?: string;
    /**
     * 差し込み画像4のURL
     * @type {string}
     * @memberof InlineResponse2002CampaignImages
     */
    'insert4'?: string;
}
/**
 * 応募期間<br> statusが`応募期間中`の時 - 応募期間の情報<br> それ以外 - 未定義
 * @export
 * @interface InlineResponse2002CampaignPeriod
 */
export interface InlineResponse2002CampaignPeriod {
    /**
     * 実施期間の開始 年月日
     * @type {string}
     * @memberof InlineResponse2002CampaignPeriod
     */
    'start': string;
    /**
     * 実施期間の終了 年月日
     * @type {string}
     * @memberof InlineResponse2002CampaignPeriod
     */
    'end': string;
    /**
     * 応募期間タイトル
     * @type {string}
     * @memberof InlineResponse2002CampaignPeriod
     */
    'title': string;
}
/**
 * プレゼント
 * @export
 * @interface InlineResponse2002Presents
 */
export interface InlineResponse2002Presents {
    /**
     * 名前
     * @type {string}
     * @memberof InlineResponse2002Presents
     */
    'name': string;
    /**
     * 説明
     * @type {string}
     * @memberof InlineResponse2002Presents
     */
    'description': string;
    /**
     * 当選者数
     * @type {number}
     * @memberof InlineResponse2002Presents
     */
    'number_of_winners': number;
    /**
     * 画像URL
     * @type {string}
     * @memberof InlineResponse2002Presents
     */
    'image_url': string;
    /**
     * プレゼント情報の表示形状
     * @type {string}
     * @memberof InlineResponse2002Presents
     */
    'shape_type': InlineResponse2002PresentsShapeTypeEnum;
}
/**
 * プレゼント
 * @export
 * @interface InlineResponse2005Presents
 */
 export interface InlineResponse2005Presents {
     /**
     * タイトル
     * @type {string}
     * @memberof InlineResponse2005Presents
     */
    'title': string;
    /**
     * 内容
     * @type {string}
     * @memberof InlineResponse2005Presents
     */
     'content': string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse2002PresentsShapeTypeEnum {
    Full = 'full',
    Half = 'half'
}

/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 信頼済み端末ID
     * @type {string}
     * @memberof InlineResponse2003
     */
    'trusted_terminal_id': string;
    /**
     * code
     * @type {number}
     * @memberof InlineResponse2003
     */
    'code': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 長生き情報を入手したいかどうか<br> 未設定 - 未指定<br> true - する<br> false - しない
     * @type {boolean}
     * @memberof InlineResponse2004
     */
    'is_senior_lead'?: boolean;
    /**
     * 「いぬ・ねこのきもち」のメルマガについて<br> 未設定 - 未指定<br> false - 受け取らない<br> true - 受け取る
     * @type {boolean}
     * @memberof InlineResponse2004
     */
    'is_subscription_mail_magazine'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
 export interface InlineResponse2009 {
    /**
     * システムメンテナンスについてのステータス取得<br> 1 - メンテナンス中<br> 0 - 未メンテナンス
     * @type {number}
     * @memberof InlineResponse2009
     */
     'maintain_state'?: number;
    /**
     * システムメンテナンスについてのタイミング取得
     * @type {string}
     * @memberof InlineResponse2009
     */
     'maintain_time'?: string;
}
/**
 * ユーザの個人情報
 * @export
 * @interface InlineResponse200User
 */
export interface InlineResponse200User {
    /**
     * メールアドレス
     * @type {string}
     * @memberof InlineResponse200User
     */
    'email'?: string;
    /**
     * 姓
     * @type {string}
     * @memberof InlineResponse200User
     */
    'last_name'?: string;
    /**
     * 名
     * @type {string}
     * @memberof InlineResponse200User
     */
    'first_name'?: string;
    /**
     * セイ
     * @type {string}
     * @memberof InlineResponse200User
     */
    'last_name_kana'?: string;
    /**
     * メイ
     * @type {string}
     * @memberof InlineResponse200User
     */
    'first_name_kana'?: string;
    /**
     * 
     * @type {Gender}
     * @memberof InlineResponse200User
     */
    'gender'?: Gender;
    /**
     * 日付<br> YYYY-MM-DD の形式
     * @type {string}
     * @memberof InlineResponse200User
     */
    'birthday'?: string;
    /**
     * 電話番号<br> 「090-1111-1111」など、ハイフン区切りの形式
     * @type {string}
     * @memberof InlineResponse200User
     */
    'telephone_number'?: string;
    /**
     * 郵便番号<br> XXXXXXX の形式
     * @type {string}
     * @memberof InlineResponse200User
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200User
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200User
     */
    'address2'?: string;
    /**
     * null - 未定義<br> 0 - 規約同意してない<br> 1 - 規約同意している
     * @type {number}
     * @memberof InlineResponse200User
     */
    'is_terms_agreed': number;
    /**
     * 「長生き情報」の受け取りを...<br> null - 未定義<br> 0 - しない<br> 1 - する
     * @type {number}
     * @memberof InlineResponse200User
     */
    'is_need_senior_lead': number;
    /**
     * 「いぬ・ねこのきもち」のメルマガについて<br> null - 未定義<br> 0 - 受け取らない<br> 1 - 受け取る
     * @type {number}
     * @memberof InlineResponse200User
     */
    'is_subscription_mail_magazine': number;
    /**
     * メールアドレス
     * @type {string}
     * @memberof InlineResponse200User
     */
    'login_id'?: string;
    /**
     * KFDB外部ID
     * @type {string}
     * @memberof InlineResponse200User
     */
    'kfdb_external_id': string;
    /**
     * ペット登録上限数
     * @type {number}
     * @memberof InlineResponse200User
     */
    'registerable_pet_count'?: number;
}
/**
 * ユーザの個人情報
 * @export
 * @interface InlineResponse2009User
 */
 export interface InlineResponse2009User {
    /**
     * 姓
     * @type {string}
     * @memberof InlineResponse2009User
     */
    'surname'?: string;
    /**
     * 名
     * @type {string}
     * @memberof InlineResponse2009User
     */
    'name'?: string;
    /**
     * 年
     * @type {string}
     * @memberof InlineResponse2009User
     */
     'year'?: string;
     /**
     * 月
     * @type {string}
     * @memberof InlineResponse2009User
     */
    'month'?: string;
    /**
     * 日
     * @type {string}
     * @memberof InlineResponse2009User
     */
     'day'?: string;
     /**
     * 電話番号1
     * @type {string}
     * @memberof InlineResponse2009User
     */
    'firstPart'?: string;
    /**
     * 電話番号2
     * @type {string}
     * @memberof InlineResponse2009User
     */
     'secondPart'?: string;
     /**
     * 電話番号3
     * @type {string}
     * @memberof InlineResponse2009User
     */
    'thirdPart'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * URI<br> 「http://...」または「https://...」の形式
     * @type {string}
     * @memberof InlineResponse201
     */
    'questionnaire_url': string;
    /**
     * プレキャン回答データのid。dryrun=trueの場合は存在しない
     * @type {number}
     * @memberof InlineResponse201
     */
    'campaign_answer_id'?: number;
    /**
     * KFDB外部ID
     * @type {string}
     * @memberof InlineResponse201
     */
     'kfdb_external_id': string;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {ModelError}
     * @memberof InlineResponse400
     */
    'error': ModelError;
}
/**
 * 
 * @export
 * @interface InlineResponse409
 */
export interface InlineResponse409 {
    /**
     * ログイン遷移フラグ。trueならログイン画面に遷移。falseならOTP検証画面に遷移する必要がある
     * @type {boolean}
     * @memberof InlineResponse409
     */
    'is_need_login'?: boolean;
    /**
     * 
     * @type {ModelError}
     * @memberof InlineResponse409
     */
    'error': ModelError;
}
/**
 * エラー情報
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 画面表示用のエラーメッセージ
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * エラー情報
     * @type {Array<Array<object>>}
     * @memberof ModelError
     */
    'errors': Array<Array<object>>;
}
/**
 * ペット固有の情報
 * @export
 * @interface Pet
 */
export interface Pet {
    /**
     * UUID。この値をキーに、追加または更新が行われる
     * @type {string}
     * @memberof Pet
     */
    'id': string;
    /**
     * 飼育情報
     * @type {string}
     * @memberof Pet
     */
    'have': string;
    /**
     * 飼育情報
     * @type {string}
     * @memberof Pet
     */
    'havehave': string;
    /**
     * 名前
     * @type {string}
     * @memberof Pet
     */
    'name': string;
    /**
     * 日付<br> YYYY-MM-DD の形式
     * @type {string}
     * @memberof Pet
     */
    'birthday': string;
    /**
     * 
     * @type {Gender}
     * @memberof Pet
     */
    'gender': Gender;
    /**
     * 種別
     * @type {string}
     * @memberof Pet
     */
    'type': PetTypeEnum;
    /**
     * 種別(検討中)
     * @type {string}
     * @memberof Pet
     */
    'type2': PetTypeEnum;
    /**
     * 品種
     * @type {string}
     * @memberof Pet
     */
    'breed': string;
}

/**
 * 応募完了画面の情報
 * @export
 * @interface ThanksData
 */
 export interface ThanksData {
    /**
     * キャンペーン名
     * @type {string}
     * @memberof ThanksData
     */
    'name': string;
    /**
     * 見出し
     * @type {string}
     * @memberof ThanksData
     */
    'heading'?: string;
    /**
     * 見出しの表示フラグ
     * @type {boolean}
     * @memberof ThanksData
     */
    'is_heading_display': boolean;
    /**
     * 本文
     * @type {string}
     * @memberof ThanksData
     */
    'description': string;
}

/**
 * 
 * @export
 * @interface PostApiV1UserJudgeMagazineMember200Response
 */
 export interface PostApiV1UserJudgeMagazineMember200Response {
    /**
     * いぬ本誌会員判定結果。0=いぬ非会員、1=いぬ現会員、2=いぬ過去会員
     * @type {string}
     * @memberof PostApiV1UserJudgeMagazineMember200Response
     */
    'dogMagazineMemberFlg': string;
    /**
     * ねこ本誌会員判定結果。0=ねこ非会員、1=ねこ現会員、2=ねこ過去会員
     * @type {string}
     * @memberof PostApiV1UserJudgeMagazineMember200Response
     */
    'catMagazineMemberFlg': string;
    /**
     * ドットステータスコード
     * @type {number}
     * @memberof PostApiV1UserJudgeMagazineMember200Response
     */
    'code': number;
}
/**
 * 
 * @export
 * @interface PostApiV1UserJudgeMagazineMemberRequest
 */
export interface PostApiV1UserJudgeMagazineMemberRequest {
    /**
     * 姓
     * @type {string}
     * @memberof PostApiV1UserJudgeMagazineMemberRequest
     */
    'familyName': string;
    /**
     * 名
     * @type {string}
     * @memberof PostApiV1UserJudgeMagazineMemberRequest
     */
    'firstName': string;
    /**
     * 生年月日
     * @type {string}
     * @memberof PostApiV1UserJudgeMagazineMemberRequest
     */
    'birthDay': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PostApiV1UserJudgeMagazineMemberRequest
     */
    'fixPhoneNumber': string;
    /**
     * 共通顧客番号
     * @type {string}
     * @memberof PostApiV1UserJudgeMagazineMemberRequest
     */
    'commonCustomerNumber': string;
    /**
     * ジャッジフラッグ
     * @type {string}
     * @memberof PostApiV1UserJudgeMagazineMemberRequest
     */
    'JudgeFlag': string;
}

/**
    * @export
    * @enum {string}
    */
export enum PetTypeEnum {
    Dog = 'dog',
    Cat = 'cat',
    Other = 'other'
}


/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 郵便番号から住所検索
         * @param {string} zip 郵便番号 XXXXXXX の形式
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressZip: async (zip: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zip' is not null or undefined
            assertParamExists('getAddressZip', 'zip', zip)
            const localVarPath = `/api/v1/address/searchByZipCode/{zip}`
                .replace(`{${"zip"}}`, encodeURIComponent(String(zip)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 郵便番号から住所検索
         * @param {string} zip 郵便番号 XXXXXXX の形式
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressZip(zip: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressZip(zip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @summary 郵便番号から住所検索
         * @param {string} zip 郵便番号 XXXXXXX の形式
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressZip(zip: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAddressZip(zip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @summary 郵便番号から住所検索
     * @param {string} zip 郵便番号 XXXXXXX の形式
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public getAddressZip(zip: string, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).getAddressZip(zip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * キャンペーン応募に必要な入力項目の情報を取得する
         * @summary キャンペーン応募情報取得
         * @param {string} id キャンペーンのUUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1ApplicationsId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiV1ApplicationsId', 'id', id)
            const localVarPath = `/api/v1/campaigns/{id}/application`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーンの内容を取得する<br> `応募期間前`：期間前のメッセージなどの情報<br> `応募期間中`：受付中のメッセージと、表示フォームの情報<br> `応募締め切り後`：締め切り後のメッセージなどの情報
         * @summary ランディングページ情報取得
         * @param {string} id キャンペーンのUUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1CampaignsIdStatus: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiV1CampaignsIdStatus', 'id', id)
            const localVarPath = `/api/v1/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーンに応募する<br> ユーザの新規登録も兼ねる
         * @summary キャンペーン応募
         * @param {string} id キャンペーンのUUID
         * @param {boolean} [dryrun] false(既定値)&#x3D;応募処理を行う、true&#x3D;パラメータの確認のみを行う
         * @param {InlineObject3} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1CampaignsIdApply: async (id: string, dryrun?: boolean, body?: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postApiV1CampaignsIdApply', 'id', id)
            const localVarPath = `/api/v1/campaigns/{id}/application`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryrun !== undefined) {
                localVarQueryParameter['dryrun'] = dryrun;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * キャンペーン応募に必要な入力項目の情報を取得する
         * @summary キャンペーン応募情報取得
         * @param {string} id キャンペーンのUUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1ApplicationsId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1ApplicationsId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーンの内容を取得する<br> `応募期間前`：期間前のメッセージなどの情報<br> `応募期間中`：受付中のメッセージと、表示フォームの情報<br> `応募締め切り後`：締め切り後のメッセージなどの情報
         * @summary ランディングページ情報取得
         * @param {string} id キャンペーンのUUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1CampaignsIdStatus(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1CampaignsIdStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーンに応募する<br> ユーザの新規登録も兼ねる
         * @summary キャンペーン応募
         * @param {string} id キャンペーンのUUID
         * @param {boolean} [dryrun] false(既定値)&#x3D;応募処理を行う、true&#x3D;パラメータの確認のみを行う
         * @param {InlineObject3} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1CampaignsIdApply(id: string, dryrun?: boolean, body?: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1CampaignsIdApply(id, dryrun, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * キャンペーン応募に必要な入力項目の情報を取得する
         * @summary キャンペーン応募情報取得
         * @param {string} id キャンペーンのUUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1ApplicationsId(id: string, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.getApiV1ApplicationsId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーンの内容を取得する<br> `応募期間前`：期間前のメッセージなどの情報<br> `応募期間中`：受付中のメッセージと、表示フォームの情報<br> `応募締め切り後`：締め切り後のメッセージなどの情報
         * @summary ランディングページ情報取得
         * @param {string} id キャンペーンのUUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1CampaignsIdStatus(id: string, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.getApiV1CampaignsIdStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーンに応募する<br> ユーザの新規登録も兼ねる
         * @summary キャンペーン応募
         * @param {string} id キャンペーンのUUID
         * @param {boolean} [dryrun] false(既定値)&#x3D;応募処理を行う、true&#x3D;パラメータの確認のみを行う
         * @param {InlineObject3} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1CampaignsIdApply(id: string, dryrun?: boolean, body?: InlineObject3, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.postApiV1CampaignsIdApply(id, dryrun, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * キャンペーン応募に必要な入力項目の情報を取得する
     * @summary キャンペーン応募情報取得
     * @param {string} id キャンペーンのUUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getApiV1ApplicationsId(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).getApiV1ApplicationsId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーンの内容を取得する<br> `応募期間前`：期間前のメッセージなどの情報<br> `応募期間中`：受付中のメッセージと、表示フォームの情報<br> `応募締め切り後`：締め切り後のメッセージなどの情報
     * @summary ランディングページ情報取得
     * @param {string} id キャンペーンのUUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getApiV1CampaignsIdStatus(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).getApiV1CampaignsIdStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーンに応募する<br> ユーザの新規登録も兼ねる
     * @summary キャンペーン応募
     * @param {string} id キャンペーンのUUID
     * @param {boolean} [dryrun] false(既定値)&#x3D;応募処理を行う、true&#x3D;パラメータの確認のみを行う
     * @param {InlineObject3} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public postApiV1CampaignsIdApply(id: string, dryrun?: boolean, body?: InlineObject3, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).postApiV1CampaignsIdApply(id, dryrun, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ALB向けの生存確認用のAPI
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * ALB向けの生存確認用のAPI
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHeal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * ALB向けの生存確認用のAPI
         * @summary ヘルスチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeal(options?: any): AxiosPromise<object> {
            return localVarFp.getHeal(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * ALB向けの生存確認用のAPI
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHeal(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHeal(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログイン時に、OTP確認必要フラグがtrueで返された場合は、このAPIでOTPを検証する。検証に成功ならば、レスポンスで返される 信頼済み端末ID を保存して、次回からのログイン時に 信頼済み端末ID を送信する。
         * @summary ログインOTP検証
         * @param {InlineObject4} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserLoginConfirmOtp: async (body?: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/login/confirmOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 成功後に返されるtokenを、以降のAPIリクエストのヘッダに設定する<br> 例：`Authorization: Bearer {{token}}`<br> <br> KFDB API `login by id/pw` を使用する
         * @summary ログイン
         * @param {InlineObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogin: async (body?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * ログイン時に、OTP確認必要フラグがtrueで返された場合は、このAPIでOTPを検証する。検証に成功ならば、レスポンスで返される 信頼済み端末ID を保存して、次回からのログイン時に 信頼済み端末ID を送信する。
         * @summary ログインOTP検証
         * @param {InlineObject4} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1UserLoginConfirmOtp(body?: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1UserLoginConfirmOtp(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 成功後に返されるtokenを、以降のAPIリクエストのヘッダに設定する<br> 例：`Authorization: Bearer {{token}}`<br> <br> KFDB API `login by id/pw` を使用する
         * @summary ログイン
         * @param {InlineObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogin(body?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * ログイン時に、OTP確認必要フラグがtrueで返された場合は、このAPIでOTPを検証する。検証に成功ならば、レスポンスで返される 信頼済み端末ID を保存して、次回からのログイン時に 信頼済み端末ID を送信する。
         * @summary ログインOTP検証
         * @param {InlineObject4} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserLoginConfirmOtp(body?: InlineObject4, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.postApiV1UserLoginConfirmOtp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 成功後に返されるtokenを、以降のAPIリクエストのヘッダに設定する<br> 例：`Authorization: Bearer {{token}}`<br> <br> KFDB API `login by id/pw` を使用する
         * @summary ログイン
         * @param {InlineObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogin(body?: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.postLogin(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * ログイン時に、OTP確認必要フラグがtrueで返された場合は、このAPIでOTPを検証する。検証に成功ならば、レスポンスで返される 信頼済み端末ID を保存して、次回からのログイン時に 信頼済み端末ID を送信する。
     * @summary ログインOTP検証
     * @param {InlineObject4} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public postApiV1UserLoginConfirmOtp(body?: InlineObject4, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).postApiV1UserLoginConfirmOtp(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 成功後に返されるtokenを、以降のAPIリクエストのヘッダに設定する<br> 例：`Authorization: Bearer {{token}}`<br> <br> KFDB API `login by id/pw` を使用する
     * @summary ログイン
     * @param {InlineObject} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public postLogin(body?: InlineObject, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).postLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary オプト イン/アウト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1UserOpt: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/opt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 「パスワードのリセットメール(OTP)送信」APIで送信したOTPを検証する
         * @summary パスワードのOTP検証
         * @param {InlineObject7} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserPasswordConfirmOtp: async (body?: InlineObject7, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/password/confirmOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新しいパスワードを設定する。先に「パスワードのOTP検証」APIを成功させておく必要がある
         * @summary パスワードの再設定
         * @param {InlineObject8} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserPasswordReset: async (body?: InlineObject8, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワード再設定の手順に必要なOTPを送信する
         * @summary パスワードのリセットメール(OTP)送信
         * @param {InlineObject6} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserPasswordSendOtp: async (body?: InlineObject6, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/password/sendOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 退会
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserWithdraw: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 「仮ユーザ登録API」で発行されたワンタイムパスワード（OTP）が正しいかどうか検証する<br> 新たなユーザコンテキストがレスポンスされるので、以降のAPIはこれを使用する
         * @summary 仮ユーザ登録OTP検証
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UsersConfirmOtp: async (body?: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/confirmOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザの仮登録を行う。<br> メールアドレスを指定して、ワンタイムパスワード（OTP）を発行する。<br> OTPはワンタイムパスワード確認APIに使用する。
         * @summary 仮ユーザ登録OTP送信
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UsersPreRegistration: async (body?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/preRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 規約同意
         * @param {InlineObject5} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV1UserAgreeTerms: async (body?: InlineObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/agreeTerms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザに関するオプトインorアウトを行う
         * @summary オプト イン/アウト
         * @param {InlineObject9} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV1UserOpt: async (body?: InlineObject9, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/opt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーセッションを基に本誌判定を行う
         * @summary 本誌判定（セッション）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1UserCheckMagazineMemberJudge: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/checkMagazineMemberJudge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個人情報または共通顧客番号を基に本誌判定を行う
         * @summary 本誌判定（個人情報または共通顧客番号）
         * @param {PostApiV1UserJudgeMagazineMemberRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserJudgeMagazineMember: async (body?: PostApiV1UserJudgeMagazineMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/judgeMagazineMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
        
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
        
        
            
            localVarHeaderParameter['Content-Type'] = 'application/json';
        
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)
        
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary オプト イン/アウト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1UserOpt(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1UserOpt(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 「パスワードのリセットメール(OTP)送信」APIで送信したOTPを検証する
         * @summary パスワードのOTP検証
         * @param {InlineObject7} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1UserPasswordConfirmOtp(body?: InlineObject7, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1UserPasswordConfirmOtp(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新しいパスワードを設定する。先に「パスワードのOTP検証」APIを成功させておく必要がある
         * @summary パスワードの再設定
         * @param {InlineObject8} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1UserPasswordReset(body?: InlineObject8, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1UserPasswordReset(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワード再設定の手順に必要なOTPを送信する
         * @summary パスワードのリセットメール(OTP)送信
         * @param {InlineObject6} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1UserPasswordSendOtp(body?: InlineObject6, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1UserPasswordSendOtp(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 退会
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1UserWithdraw(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1UserWithdraw(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 「仮ユーザ登録API」で発行されたワンタイムパスワード（OTP）が正しいかどうか検証する<br> 新たなユーザコンテキストがレスポンスされるので、以降のAPIはこれを使用する
         * @summary 仮ユーザ登録OTP検証
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1UsersConfirmOtp(body?: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1UsersConfirmOtp(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザの仮登録を行う。<br> メールアドレスを指定して、ワンタイムパスワード（OTP）を発行する。<br> OTPはワンタイムパスワード確認APIに使用する。
         * @summary 仮ユーザ登録OTP送信
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1UsersPreRegistration(body?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1UsersPreRegistration(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 規約同意
         * @param {InlineObject5} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV1UserAgreeTerms(body?: InlineObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV1UserAgreeTerms(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザに関するオプトインorアウトを行う
         * @summary オプト イン/アウト
         * @param {InlineObject9} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV1UserOpt(body?: InlineObject9, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV1UserOpt(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーセッションを基に本誌判定を行う
         * @summary 本誌判定（セッション）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV1UserCheckMagazineMemberJudge(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiV1UserJudgeMagazineMember200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV1UserCheckMagazineMemberJudge(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 個人情報または共通顧客番号を基に本誌判定を行う
         * @summary 本誌判定（個人情報または共通顧客番号）
         * @param {PostApiV1UserJudgeMagazineMemberRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV1UserJudgeMagazineMember(body?: PostApiV1UserJudgeMagazineMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostApiV1UserJudgeMagazineMember200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV1UserJudgeMagazineMember(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary オプト イン/アウト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1UserOpt(options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.getApiV1UserOpt(options).then((request) => request(axios, basePath));
        },
        /**
         * 「パスワードのリセットメール(OTP)送信」APIで送信したOTPを検証する
         * @summary パスワードのOTP検証
         * @param {InlineObject7} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserPasswordConfirmOtp(body?: InlineObject7, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.postApiV1UserPasswordConfirmOtp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 新しいパスワードを設定する。先に「パスワードのOTP検証」APIを成功させておく必要がある
         * @summary パスワードの再設定
         * @param {InlineObject8} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserPasswordReset(body?: InlineObject8, options?: any): AxiosPromise<void> {
            return localVarFp.postApiV1UserPasswordReset(body, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワード再設定の手順に必要なOTPを送信する
         * @summary パスワードのリセットメール(OTP)送信
         * @param {InlineObject6} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserPasswordSendOtp(body?: InlineObject6, options?: any): AxiosPromise<void> {
            return localVarFp.postApiV1UserPasswordSendOtp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 退会
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserWithdraw(options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.postApiV1UserWithdraw(options).then((request) => request(axios, basePath));
        },
        /**
         * 「仮ユーザ登録API」で発行されたワンタイムパスワード（OTP）が正しいかどうか検証する<br> 新たなユーザコンテキストがレスポンスされるので、以降のAPIはこれを使用する
         * @summary 仮ユーザ登録OTP検証
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UsersConfirmOtp(body?: InlineObject2, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.postApiV1UsersConfirmOtp(body, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザの仮登録を行う。<br> メールアドレスを指定して、ワンタイムパスワード（OTP）を発行する。<br> OTPはワンタイムパスワード確認APIに使用する。
         * @summary 仮ユーザ登録OTP送信
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UsersPreRegistration(body?: InlineObject1, options?: any): AxiosPromise<object> {
            return localVarFp.postApiV1UsersPreRegistration(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 規約同意
         * @param {InlineObject5} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV1UserAgreeTerms(body?: InlineObject5, options?: any): AxiosPromise<void> {
            return localVarFp.putApiV1UserAgreeTerms(body, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザに関するオプトインorアウトを行う
         * @summary オプト イン/アウト
         * @param {InlineObject9} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV1UserOpt(body?: InlineObject9, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.putApiV1UserOpt(body, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーセッションを基に本誌判定を行う
         * @summary 本誌判定（セッション）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV1UserCheckMagazineMemberJudge(options?: any): AxiosPromise<PostApiV1UserJudgeMagazineMember200Response> {
            return localVarFp.getApiV1UserCheckMagazineMemberJudge(options).then((request) => request(axios, basePath));
        },
        /**
         * 個人情報または共通顧客番号を基に本誌判定を行う
         * @summary 本誌判定（個人情報または共通顧客番号）
         * @param {PostApiV1UserJudgeMagazineMemberRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV1UserJudgeMagazineMember(body?: PostApiV1UserJudgeMagazineMemberRequest, options?: any): AxiosPromise<PostApiV1UserJudgeMagazineMember200Response> {
            return localVarFp.postApiV1UserJudgeMagazineMember(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary オプト イン/アウト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getApiV1UserOpt(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getApiV1UserOpt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 「パスワードのリセットメール(OTP)送信」APIで送信したOTPを検証する
     * @summary パスワードのOTP検証
     * @param {InlineObject7} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postApiV1UserPasswordConfirmOtp(body?: InlineObject7, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postApiV1UserPasswordConfirmOtp(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新しいパスワードを設定する。先に「パスワードのOTP検証」APIを成功させておく必要がある
     * @summary パスワードの再設定
     * @param {InlineObject8} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postApiV1UserPasswordReset(body?: InlineObject8, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postApiV1UserPasswordReset(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワード再設定の手順に必要なOTPを送信する
     * @summary パスワードのリセットメール(OTP)送信
     * @param {InlineObject6} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postApiV1UserPasswordSendOtp(body?: InlineObject6, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postApiV1UserPasswordSendOtp(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 退会
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postApiV1UserWithdraw(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postApiV1UserWithdraw(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 「仮ユーザ登録API」で発行されたワンタイムパスワード（OTP）が正しいかどうか検証する<br> 新たなユーザコンテキストがレスポンスされるので、以降のAPIはこれを使用する
     * @summary 仮ユーザ登録OTP検証
     * @param {InlineObject2} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postApiV1UsersConfirmOtp(body?: InlineObject2, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postApiV1UsersConfirmOtp(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザの仮登録を行う。<br> メールアドレスを指定して、ワンタイムパスワード（OTP）を発行する。<br> OTPはワンタイムパスワード確認APIに使用する。
     * @summary 仮ユーザ登録OTP送信
     * @param {InlineObject1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postApiV1UsersPreRegistration(body?: InlineObject1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postApiV1UsersPreRegistration(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 規約同意
     * @param {InlineObject5} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putApiV1UserAgreeTerms(body?: InlineObject5, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).putApiV1UserAgreeTerms(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザに関するオプトインorアウトを行う
     * @summary オプト イン/アウト
     * @param {InlineObject9} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putApiV1UserOpt(body?: InlineObject9, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).putApiV1UserOpt(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーセッションを基に本誌判定を行う
     * @summary 本誌判定（セッション）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getApiV1UserCheckMagazineMemberJudge(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getApiV1UserCheckMagazineMemberJudge(options).then((request) => request(this.axios, this.basePath));
    }
    
    /**
     * 個人情報または共通顧客番号を基に本誌判定を行う
     * @summary 本誌判定（個人情報または共通顧客番号）
     * @param {PostApiV1UserJudgeMagazineMemberRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postApiV1UserJudgeMagazineMember(body?: PostApiV1UserJudgeMagazineMemberRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postApiV1UserJudgeMagazineMember(body, options).then((request) => request(this.axios, this.basePath));
    }
}


