import { PetTypeEnum as PetType } from "../../types/typescript-axios";

export const petData: {
  [key in PetType]: Array<{
    value: string;
    label: string;
  }>;
} = {
  dog: [
    { value: "アーフェンピンシャー", label: "アーフェンピンシャー" },
    {
      value: "アイリッシュ・ウォーター・スパニエル",
      label: "アイリッシュ・ウォーター・スパニエル",
    },
    {
      value: "アイリッシュ・ウルフハウンド",
      label: "アイリッシュ・ウルフハウンド",
    },
    { value: "アイリッシュ・セター", label: "アイリッシュ・セター" },
    {
      value: "アイリッシュ・ソフトコーテッド・ウィートン・テリア",
      label: "アイリッシュ・ソフトコーテッド・ウィートン・テリア",
    },
    { value: "アイリッシュ・テリア", label: "アイリッシュ・テリア" },
    {
      value: "アイリッシュ・レッド・アンド・ホワイト・セター",
      label: "アイリッシュ・レッド・アンド・ホワイト・セター",
    },
    { value: "秋田", label: "秋田" },
    { value: "アフガン・ハウンド", label: "アフガン・ハウンド" },
    { value: "アメリカン・アキタ", label: "アメリカン・アキタ" },
    {
      value: "アメリカン・コッカー・スパニエル",
      label: "アメリカン・コッカー・スパニエル",
    },
    {
      value: "アメリカン・スタッフォードシャー・テリア",
      label: "アメリカン・スタッフォードシャー・テリア",
    },
    {
      value: "アメリカン・フォックスハウンド",
      label: "アメリカン・フォックスハウンド",
    },
    {
      value: "アラスカン・マラミュート",
      label: "アラスカン・マラミュート",
    },
    {
      value: "イタリアン・グレーハウンド",
      label: "イタリアン・グレーハウンド",
    },
    { value: "イビザン・ハウンド", label: "イビザン・ハウンド" },
    {
      value: "イングリッシュ・コッカー・スパニエル",
      label: "イングリッシュ・コッカー・スパニエル",
    },
    {
      value: "イングリッシュ・スプリンガー・スパニエル",
      label: "イングリッシュ・スプリンガー・スパニエル",
    },
    { value: "イングリッシュ・セター", label: "イングリッシュ・セター" },
    {
      value: "イングリッシュ・ポインター",
      label: "イングリッシュ・ポインター",
    },
    { value: "ウィペット", label: "ウィペット" },
    {
      value: "ウエスト・ハイランド・ホワイト・テリア",
      label: "ウエスト・ハイランド・ホワイト・テリア",
    },
    {
      value: "ウェルシュ・コーギー・カーディガン",
      label: "ウェルシュ・コーギー・カーディガン",
    },
    {
      value: "ウェルシュ・コーギー・ペンブローク",
      label: "ウェルシュ・コーギー・ペンブローク",
    },
    {
      value: "ウェルシュ・スプリンガー・スパニエル",
      label: "ウェルシュ・スプリンガー・スパニエル",
    },
    { value: "ウェルシュ・テリア", label: "ウェルシュ・テリア" },
    { value: "エアデール・テリア", label: "エアデール・テリア" },
    {
      value: "オーストラリアン・キャトル・ドッグ",
      label: "オーストラリアン・キャトル・ドッグ",
    },
    {
      value: "オーストラリアン・ケルピー",
      label: "オーストラリアン・ケルピー",
    },
    {
      value: "オーストラリアン・シェパード",
      label: "オーストラリアン・シェパード",
    },
    {
      value: "オーストラリアン・シルキー・テリア",
      label: "オーストラリアン・シルキー・テリア",
    },
    {
      value: "オーストラリアン・テリア",
      label: "オーストラリアン・テリア",
    },
    {
      value: "オールド・イングリッシュ・シープドッグ",
      label: "オールド・イングリッシュ・シープドッグ",
    },
    {
      value: "カーリーコーテッド・レトリーバー",
      label: "カーリーコーテッド・レトリーバー",
    },
    { value: "甲斐", label: "甲斐" },
    {
      value: "カニーンヘン・ダックスフンド",
      label: "カニーンヘン・ダックスフンド",
    },
    {
      value: "キースホンド（ジャーマン・ウルフスピッツ）",
      label: "キースホンド（ジャーマン・ウルフスピッツ）",
    },
    { value: "紀州", label: "紀州" },
    {
      value: "キャバリア・キング・チャールズ・スパニエル",
      label: "キャバリア・キング・チャールズ・スパニエル",
    },
    {
      value: "キング・チャールズ・スパニエル",
      label: "キング・チャールズ・スパニエル",
    },
    { value: "クランバー・スパニエル", label: "クランバー・スパニエル" },
    { value: "グレート・デーン", label: "グレート・デーン" },
    { value: "グレート・ピレニーズ", label: "グレート・ピレニーズ" },
    { value: "グレーハウンド", label: "グレーハウンド" },
    { value: "ケアーン・テリア", label: "ケアーン・テリア" },
    { value: "ケリー・ブルー・テリア", label: "ケリー・ブルー・テリア" },
    { value: "コーイケルホンディエ", label: "コーイケルホンディエ" },
    { value: "ゴードン・セター", label: "ゴードン・セター" },
    {
      value: "ゴールデン・レトリーバー",
      label: "ゴールデン・レトリーバー",
    },
    { value: "コモンドール", label: "コモンドール" },
    { value: "サモエド", label: "サモエド" },
    { value: "サルーキ", label: "サルーキ" },
    { value: "シー・ズー", label: "シー・ズー" },
    { value: "シーリハム・テリア", label: "シーリハム・テリア" },
    {
      value: "シェットランド・シープドッグ",
      label: "シェットランド・シープドッグ",
    },
    { value: "四国", label: "四国" },
    { value: "柴", label: "柴" },
    { value: "シベリアン・ハスキー", label: "シベリアン・ハスキー" },
    { value: "シャー・ペイ", label: "シャー・ペイ" },
    {
      value: "ジャーマン・シェパード・ドッグ",
      label: "ジャーマン・シェパード・ドッグ",
    },
    {
      value: "ジャーマン・ショートヘアード・ポインター",
      label: "ジャーマン・ショートヘアード・ポインター",
    },
    {
      value: "ジャーマン・ワイアーヘアード・ポインター",
      label: "ジャーマン・ワイアーヘアード・ポインター",
    },
    {
      value: "ジャイアント・シュナウザー",
      label: "ジャイアント・シュナウザー",
    },
    {
      value: "ジャック・ラッセル・テリア",
      label: "ジャック・ラッセル・テリア",
    },
    {
      value: "ショートヘアード・ハンガリアン・ビズラ",
      label: "ショートヘアード・ハンガリアン・ビズラ",
    },
    { value: "スカイ・テリア", label: "スカイ・テリア" },
    { value: "スキッパーキ", label: "スキッパーキ" },
    { value: "スコティッシュ・テリア", label: "スコティッシュ・テリア" },
    {
      value: "スタッフォードシャー・ブル・テリア",
      label: "スタッフォードシャー・ブル・テリア",
    },
    {
      value: "スタンダード・シュナウザー",
      label: "スタンダード・シュナウザー",
    },
    { value: "スタンダード・プードル", label: "スタンダード・プードル" },
    { value: "スムース・コリー", label: "スムース・コリー" },
    {
      value: "スムース・フォックス・テリア",
      label: "スムース・フォックス・テリア",
    },
    { value: "スルーギ", label: "スルーギ" },
    { value: "セント・バーナード", label: "セント・バーナード" },
    {
      value: "タイ・リッジバック・ドッグ",
      label: "タイ・リッジバック・ドッグ",
    },
    { value: "ダックスフンド", label: "ダックスフンド" },
    { value: "ダルメシアン", label: "ダルメシアン" },
    {
      value: "ダンディ・ディンモント・テリア",
      label: "ダンディ・ディンモント・テリア",
    },
    {
      value: "チェサピーク・ベイ・レトリーバー",
      label: "チェサピーク・ベイ・レトリーバー",
    },
    { value: "チベタン・スパニエル", label: "チベタン・スパニエル" },
    { value: "チベタン・テリア", label: "チベタン・テリア" },
    {
      value: "チャイニーズ・クレステッド・ドッグ",
      label: "チャイニーズ・クレステッド・ドッグ",
    },
    { value: "チャウ・チャウ", label: "チャウ・チャウ" },
    { value: "チワワ", label: "チワワ" },
    { value: "狆", label: "狆" },
    { value: "ディアハウンド", label: "ディアハウンド" },
    { value: "トイ・プードル", label: "トイ・プードル" },
    {
      value: "トイ・マンチェスター・テリア",
      label: "トイ・マンチェスター・テリア",
    },
    { value: "ドーベルマン", label: "ドーベルマン" },
    { value: "ドゴ・アルヘンティーノ", label: "ドゴ・アルヘンティーノ" },
    { value: "土佐", label: "土佐" },
    { value: "ナポリタン・マスティフ", label: "ナポリタン・マスティフ" },
    { value: "日本スピッツ", label: "日本スピッツ" },
    { value: "日本テリア", label: "日本テリア" },
    { value: "ニューファンドランド", label: "ニューファンドランド" },
    {
      value: "ノヴァ・スコシア・ダック・トーリング・レトリーバー",
      label: "ノヴァ・スコシア・ダック・トーリング・レトリーバー",
    },
    { value: "ノーフォーク・テリア", label: "ノーフォーク・テリア" },
    { value: "ノーリッチ・テリア", label: "ノーリッチ・テリア" },
    {
      value: "ノルウェジアン・エルクハウンド",
      label: "ノルウェジアン・エルクハウンド",
    },
    {
      value: "パーソン・ラッセル・テリア",
      label: "パーソン・ラッセル・テリア",
    },
    {
      value: "バーニーズ・マウンテン・ドッグ",
      label: "バーニーズ・マウンテン・ドッグ",
    },
    { value: "パグ", label: "パグ" },
    { value: "バセット・ハウンド", label: "バセット・ハウンド" },
    { value: "バセンジー", label: "バセンジー" },
    { value: "パピヨン", label: "パピヨン" },
    { value: "ハリア", label: "ハリア" },
    { value: "ビアデッド・コリー", label: "ビアデッド・コリー" },
    { value: "ビーグル", label: "ビーグル" },
    { value: "ビション・フリーゼ", label: "ビション・フリーゼ" },
    {
      value: "ピレニアン・シープドッグ",
      label: "ピレニアン・シープドッグ",
    },
    { value: "ピレニアン・マスティフ", label: "ピレニアン・マスティフ" },
    { value: "ファラオ・ハウンド", label: "ファラオ・ハウンド" },
    { value: "フィールド・スパニエル", label: "フィールド・スパニエル" },
    {
      value: "ブービエ・デ・フランダース",
      label: "ブービエ・デ・フランダース",
    },
    { value: "プーリー", label: "プーリー" },
    {
      value: "プチ・バセット・グリフォン・バンデーン",
      label: "プチ・バセット・グリフォン・バンデーン",
    },
    { value: "プチ・ブラバンソン", label: "プチ・ブラバンソン" },
    {
      value: "ブラジリアン・ガード・ドッグ",
      label: "ブラジリアン・ガード・ドッグ",
    },
    {
      value: "ブラック・アンド・タン・クーンハウンド",
      label: "ブラック・アンド・タン・クーンハウンド",
    },
    {
      value: "フラットコーテッド・レトリーバー",
      label: "フラットコーテッド・レトリーバー",
    },
    { value: "ブラッドハウンド", label: "ブラッドハウンド" },
    { value: "ブリアード", label: "ブリアード" },
    { value: "ブリタニー・スパニエル", label: "ブリタニー・スパニエル" },
    {
      value: "ブリュッセル・グリフォン",
      label: "ブリュッセル・グリフォン",
    },
    { value: "ブル・テリア", label: "ブル・テリア" },
    { value: "ブルドッグ", label: "ブルドッグ" },
    { value: "ブルマスティフ", label: "ブルマスティフ" },
    { value: "フレンチ・ブルドッグ", label: "フレンチ・ブルドッグ" },
    { value: "ペキニーズ", label: "ペキニーズ" },
    { value: "ベドリントン・テリア", label: "ベドリントン・テリア" },
    {
      value: "ペルービアン・ヘアレス・ドッグ",
      label: "ペルービアン・ヘアレス・ドッグ",
    },
    { value: "ベルジアン・グリフォン", label: "ベルジアン・グリフォン" },
    {
      value: "ベルジアン・シェパード・ドッグ・グローネンダール",
      label: "ベルジアン・シェパード・ドッグ・グローネンダール",
    },
    {
      value: "ベルジアン・シェパード・ドッグ・タービュレン",
      label: "ベルジアン・シェパード・ドッグ・タービュレン",
    },
    {
      value: "ベルジアン・シェパード・ドッグ・マリノア",
      label: "ベルジアン・シェパード・ドッグ・マリノア",
    },
    {
      value: "ベルジアン・シェパード・ドッグ・ラケノア",
      label: "ベルジアン・シェパード・ドッグ・ラケノア",
    },
    { value: "ボーダー・コリー", label: "ボーダー・コリー" },
    { value: "ボーダー・テリア", label: "ボーダー・テリア" },
    {
      value: "ポーチュギーズ・ウォーター・ドッグ",
      label: "ポーチュギーズ・ウォーター・ドッグ",
    },
    { value: "ボクサー", label: "ボクサー" },
    { value: "ボストン・テリア", label: "ボストン・テリア" },
    { value: "北海道", label: "北海道" },
    { value: "ポメラニアン", label: "ポメラニアン" },
    {
      value: "ポリッシュ・ローランド・シープドッグ",
      label: "ポリッシュ・ローランド・シープドッグ",
    },
    { value: "ボルゾイ", label: "ボルゾイ" },
    { value: "ボルドー・マスティフ", label: "ボルドー・マスティフ" },
    { value: "ボロニーズ", label: "ボロニーズ" },
    { value: "マスティフ", label: "マスティフ" },
    { value: "マルチーズ", label: "マルチーズ" },
    { value: "マレンマ・シープドッグ", label: "マレンマ・シープドッグ" },
    { value: "マンチェスター・テリア", label: "マンチェスター・テリア" },
    { value: "ミディアム・プードル", label: "ミディアム・プードル" },
    {
      value: "ミニチュア・シュナウザー",
      label: "ミニチュア・シュナウザー",
    },
    {
      value: "ミニチュア・ダックスフンド",
      label: "ミニチュア・ダックスフンド",
    },
    { value: "ミニチュア・ピンシャー", label: "ミニチュア・ピンシャー" },
    { value: "ミニチュア・プードル", label: "ミニチュア・プードル" },
    {
      value: "ミニチュア・ブル・テリア",
      label: "ミニチュア・ブル・テリア",
    },
    {
      value: "メキシカン・ヘアレス・ドッグ",
      label: "メキシカン・ヘアレス・ドッグ",
    },
    { value: "ヨークシャー・テリア", label: "ヨークシャー・テリア" },
    {
      value: "ラージ・ミュンスターレンダー",
      label: "ラージ・ミュンスターレンダー",
    },
    { value: "ラサ・アプソ", label: "ラサ・アプソ" },
    { value: "ラフ・コリー", label: "ラフ・コリー" },
    {
      value: "ラブラドール・レトリーバー",
      label: "ラブラドール・レトリーバー",
    },
    { value: "レークランド・テリア", label: "レークランド・テリア" },
    { value: "レオンベルガー", label: "レオンベルガー" },
    { value: "ローシェン", label: "ローシェン" },
    {
      value: "ローデシアン・リッジバック",
      label: "ローデシアン・リッジバック",
    },
    { value: "ロットワイラー", label: "ロットワイラー" },
    {
      value: "ワイアー・フォックス・テリア",
      label: "ワイアー・フォックス・テリア",
    },
    { value: "ワイマラナー", label: "ワイマラナー" },
    { value: "Mix（小型犬）", label: "Mix（小型犬）" },
    { value: "Mix（中型犬）", label: "Mix（中型犬）" },
    { value: "Mix（大型犬）", label: "Mix（大型犬）" },
    { value: "その他（犬）", label: "その他（犬）" },
  ],
  cat: [
    { value: "MIX　キジトラ", label: "MIX　キジトラ" },
    { value: "MIX　サバトラ", label: "MIX　サバトラ" },
    { value: "MIX　茶トラ", label: "MIX　茶トラ" },
    { value: "MIX　黒", label: "MIX　黒" },
    { value: "MIX　グレー", label: "MIX　グレー" },
    { value: "MIX　白", label: "MIX　白" },
    { value: "MIX　黒×白", label: "MIX　黒×白" },
    { value: "MIX　茶×白", label: "MIX　茶×白" },
    { value: "MIX　サビ", label: "MIX　サビ" },
    { value: "MIX　ミケ", label: "MIX　ミケ" },
    { value: "アビシニアン", label: "アビシニアン" },
    { value: "アメリカンカール", label: "アメリカンカール" },
    {
      value: "アメリカンカール・ショートヘア",
      label: "アメリカンカール・ショートヘア",
    },
    { value: "アメリカンショートヘア", label: "アメリカンショートヘア" },
    { value: "アメリカンワイヤーヘア", label: "アメリカンワイヤーヘア" },
    {
      value: "エキゾチックショートヘア",
      label: "エキゾチックショートヘア",
    },
    { value: "エジプシャンマウ", label: "エジプシャンマウ" },
    { value: "オシキャット", label: "オシキャット" },
    {
      value: "オリエンタルショートヘア",
      label: "オリエンタルショートヘア",
    },
    { value: "キムリック", label: "キムリック" },
    { value: "コーニッシュレックス", label: "コーニッシュレックス" },
    { value: "コラット", label: "コラット" },
    { value: "サイベリアン", label: "サイベリアン" },
    {
      value: "ジャパニーズ・ボブテイル",
      label: "ジャパニーズ・ボブテイル",
    },
    { value: "シャム", label: "シャム" },
    { value: "シャルトリュー", label: "シャルトリュー" },
    { value: "シンガプーラ", label: "シンガプーラ" },
    { value: "スクーカム", label: "スクーカム" },
    {
      value: "スコティッシュフォールド",
      label: "スコティッシュフォールド",
    },
    {
      value: "スコティッシュフォールド・ロングヘア",
      label: "スコティッシュフォールド・ロングヘア",
    },
    { value: "スフィンクス", label: "スフィンクス" },
    { value: "セルカークレックス", label: "セルカークレックス" },
    { value: "ソマリ", label: "ソマリ" },
    { value: "ターキッシュアンゴラ", label: "ターキッシュアンゴラ" },
    { value: "ターキッシュバン", label: "ターキッシュバン" },
    { value: "デボンレックス", label: "デボンレックス" },
    { value: "トンキニーズ", label: "トンキニーズ" },
    { value: "日本ネコ", label: "日本ネコ" },
    {
      value: "ノルウェージアンフォレストキャット",
      label: "ノルウェージアンフォレストキャット",
    },
    { value: "バーマン", label: "バーマン" },
    { value: "バーミーズ", label: "バーミーズ" },
    { value: "ハバナブラウン", label: "ハバナブラウン" },
    { value: "バリニーズ", label: "バリニーズ" },
    { value: "ヒマラヤン", label: "ヒマラヤン" },
    {
      value: "ブリティッシュショートヘア",
      label: "ブリティッシュショートヘア",
    },
    { value: "ペルシャ", label: "ペルシャ" },
    { value: "ベンガル", label: "ベンガル" },
    { value: "ボンベイ", label: "ボンベイ" },
    { value: "マンクス", label: "マンクス" },
    { value: "マンチカール", label: "マンチカール" },
    { value: "マンチカン", label: "マンチカン" },
    { value: "マンチカン・ロングヘア", label: "マンチカン・ロングヘア" },
    { value: "メインクーン", label: "メインクーン" },
    { value: "ラガマフィン", label: "ラガマフィン" },
    { value: "ラグドール", label: "ラグドール" },
    { value: "ラパーム", label: "ラパーム" },
    { value: "ロシアンブルー", label: "ロシアンブルー" },
    { value: "その他（猫）", label: "その他（猫）" },
  ],
  other: [],
};
