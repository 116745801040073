import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { History } from "history";
import { UseFormSetError, FieldPath } from "react-hook-form";

import {
  DateOfBirth,
  Newsletter,
  RegistrationInputs,
} from "../../pages/Registration";
import {
  Gender,
  PetTypeEnum as PetType,
  InlineObject3 as ApiFormValues,
  InlineResponse2001 as RequiredFields,
} from "../../types/typescript-axios";
import { AppThunk } from "../../app/store";
import {
  instantiateAddressApi,
  instantiateCampaignApi,
} from "../../utils/apiWrappers";
import { toast } from "react-toastify";
import { ApiError, handleError } from "../../utils/handleError";
import { resetCampaignState } from "../campaign/campaignSlice";
import { resetUserState } from "../user/userSlice";

export interface Pet {
  petId: string;
  have: string;
  orghave: string;
  havehave: string;
  name: string;
  dateOfBirth: DateOfBirth;
  gender: Gender;
  type: PetType;
  type2: PetType;
  breed: string;
}

export interface User {
  surname: string;
  name: string;
  surnameKatakana: string;
  nameKatakana: string;
  gender?: Gender;
  dateOfBirth?: DateOfBirth;
  phoneNumber?: {
    firstPart?: string;
    secondPart?: string;
    thirdPart?: string;
  };
  zipCode?: string;
  address?: {
    firstPart?: string;
    secondPart?: string;
  };
  subscribeToNewsletter: boolean;
  newsletters: Newsletter[];
  password?: {
    firstTime?: string;
    secondTime?: string;
  };
}

export interface Delivery {
  fullName?: string;
  phoneNumber?: {
    firstPart?: number;
    secondPart?: number;
    thirdPart?: number;
  };
  zipCode?: string;
  address?: {
    firstPart?: string;
    secondPart?: string;
  };
}

export interface FormValues {
  user?: User;
  delivery?: Delivery;
  pets?: Pet[];
}

interface FieldSetting {
  show: boolean;
  required?: boolean;
}

interface FieldSettings {
  user: FieldSetting;
  delivery: FieldSetting;
  pet: FieldSetting;
}

type ZipType = "user" | "delivery";

export enum Subscription {
  NotSubscribed,
  Subscribed,
}

interface FormState {
  formValues?: FormValues;
  fieldSettings: FieldSettings;
  // React hooks formで配列に対してエラーをうまく設定できなかった為Reduxで設定
  noPetError?: string;
  zipSearchResults: Record<ZipType, string[]>;
}

type ApiFormErrorField =
  | "user.last_name"
  | "user.first_name"
  | "user.last_name_kana"
  | "user.first_name_kana"
  | "user.gender"
  | "user.birthday"
  | "user.telephone_number"
  | "user.postal_code"
  | "user.address1"
  | "user.address2"
  | "user.password"
  | "delivery.name"
  | "delivery.telephone_number"
  | "delivery.postal_code"
  | "delivery.address1"
  | "delivery.address2"
  | "pets"
  | "zip";

interface ApiFormError {
  response: {
    data: {
      error: {
        errors: Record<ApiFormErrorField, string>;
        message: string;
      };
    };
  };
}

interface ZipSearchPayload {
  results: string[];
  zipType: ZipType;
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type ApiErrorMap = {
  [name in ApiFormErrorField]?: FieldPath<RegistrationInputs>;
};

const initialState: FormState = {
  fieldSettings: {
    user: { show: true },
    delivery: { show: true },
    pet: { show: true },
  },
  zipSearchResults: {
    user: [],
    delivery: [],
  },
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormState: (state, action: PayloadAction<FormValues>) => {
      const { user, delivery, pets } = action.payload;

      state.formValues = {
        ...(user && { user: { ...user } }),
        ...(delivery && { delivery: { ...delivery } }),
        pets: pets ? [...pets.map((pet) => ({ ...pet }))] : [],
      };
    },
    setFieldSettings: (state, action: PayloadAction<RequiredFields>) => {
      const {
        user_registration_requirements,
        address_requirements,
        petdata_requirements,
      } = action.payload;

      state.fieldSettings = {
        user: {
          show: user_registration_requirements !== 1,
          required: user_registration_requirements === 3,
        },
        delivery: {
          show: address_requirements !== 1,
          required: address_requirements === 3,
        },
        pet: {
          show: petdata_requirements !== 1,
          required: petdata_requirements === 3,
        },
      };
    },
    setNoPetError: (state, action: PayloadAction<string | undefined>) => {
      state.noPetError = action.payload;
    },
    setZipSearchResults: (state, action: PayloadAction<ZipSearchPayload>) => {
      const { zipType, results } = action.payload;

      state.zipSearchResults[zipType] = results;
    },
    clearFormValues: (state) => {
      state.formValues = undefined;
    },
    resetFormState: () => ({ ...initialState }),
  },
});

export const {
  setFormState,
  setFieldSettings,
  setNoPetError,
  setZipSearchResults,
  clearFormValues,
  resetFormState,
} = formSlice.actions;

export default formSlice.reducer;

const convertToDoubleDigit = (number: string) =>
  number.length === 1 ? `0${number}` : number;

const convertDateOfBirth = (dateOfBirth: DateOfBirth) =>
  `${dateOfBirth.year}-${convertToDoubleDigit(
    dateOfBirth.month
  )}-${convertToDoubleDigit(dateOfBirth.day)}`;

export const convertFormStateToApiParams = ({
  user,
  delivery,
  pets,
}: FormValues): ApiFormValues => {
  const convertedValues: ApiFormValues = {
    ...(user && {
      user: {
        last_name: user.surname,
        first_name: user.name,
        ...(user.surnameKatakana && { last_name_kana: user.surnameKatakana }),
        ...(user.nameKatakana && { first_name_kana: user.nameKatakana }),
        ...(user.gender && { gender: user.gender }),
        ...(user.dateOfBirth?.year &&
          user.dateOfBirth?.month &&
          user.dateOfBirth?.day && {
            birthday: convertDateOfBirth(user.dateOfBirth),
          }),
        ...(user.phoneNumber?.firstPart &&
          user.phoneNumber?.secondPart &&
          user.phoneNumber.thirdPart && {
            telephone_number: `${user.phoneNumber.firstPart}-${user.phoneNumber.secondPart}-${user.phoneNumber.thirdPart}`,
          }),
        ...(user.zipCode && { postal_code: user.zipCode.toString() }),
        ...(user.address?.firstPart && { address1: user.address.firstPart }),
        ...(user.address?.secondPart && { address2: user.address.secondPart }),
        ...(user.password && { password: user.password.firstTime }),
        is_subscription_mail_magazine: user.subscribeToNewsletter,
        is_senior_lead: user.newsletters.includes("longevity") ? true : false,
      },
    }),
    ...(delivery && {
      delivery: {
        ...(delivery.fullName && { name: delivery.fullName }),
        ...(delivery.phoneNumber?.firstPart &&
            delivery.phoneNumber?.secondPart &&
            delivery.phoneNumber.thirdPart && {
              telephone_number: `${delivery.phoneNumber.firstPart}-${delivery.phoneNumber.secondPart}-${delivery.phoneNumber.thirdPart}`,
            }),
        ...(delivery.zipCode && { postal_code: delivery?.zipCode?.toString() }),
        ...(delivery.address?.firstPart && {
          address1: delivery.address?.firstPart,
        }),
        ...(delivery.address?.secondPart && {
          address2: delivery.address?.secondPart,
        }),
      },
    }),
    ...(pets?.length && {
      pets: pets.map((pet) => ({
        id: pet.petId,
        have: pet.have,
        havehave: pet.havehave,
        name: pet.name,
        birthday: convertDateOfBirth(pet.dateOfBirth),
        gender: pet.gender,
        type: pet.type,
        type2: pet.type2,
        breed: pet.breed,
      })),
    }),
  };

  if (
    convertedValues.delivery &&
    !Object.keys(convertedValues.delivery).length
  ) {
    delete convertedValues.delivery;
  }

  return convertedValues;
};

export const validateAndSaveFormValues =
  (
    formValues: FormValues,
    history: History,
    setError: UseFormSetError<RegistrationInputs>
  ): AppThunk =>
  async (dispatch, getState) => {
    const campaignApi = instantiateCampaignApi();

    dispatch(setNoPetError(undefined));

    const {
      campaign: { currentCampaignId },
    } = getState();

    if (!currentCampaignId) {
      toast.error("キャンペーンIDが指定されていません", { autoClose: 10000 });
      return;
    }

    try {
      await campaignApi.postApiV1CampaignsIdApply(
        currentCampaignId,
        true,
        convertFormStateToApiParams(formValues)
      );

      dispatch(setFormState(formValues));

      history.push({
        pathname: "/registrationConfirmation",
        search: `?campaignId=${currentCampaignId}`,
      });
    } catch (e) {
      const apiErrorMap: ApiErrorMap = {
        "user.last_name": "user.surname",
        "user.first_name": "user.name",
        "user.last_name_kana": "user.surnameKatakana",
        "user.first_name_kana": "user.nameKatakana",
        "user.gender": "user.gender",
        "user.birthday": "user.dateOfBirth.year",
        "user.telephone_number": "user.phoneNumber.firstPart",
        "user.postal_code": "user.zipCode",
        "user.address1": "user.address.firstPart",
        "user.address2": "user.address.secondPart",
        "user.password": "user.password",
        "delivery.name": "delivery.fullName",
        "delivery.postal_code": "delivery.zipCode",
        "delivery.address1": "delivery.address.firstPart",
        "delivery.address2": "delivery.address.secondPart",
      };

      (
        Object.entries(
          (e as ApiFormError).response.data.error.errors
        ) as Entries<Record<ApiFormErrorField, string>>
      ).forEach(([key, error]) => {
        const errorMessage = apiErrorMap[key];

        if (key === "pets" || !errorMessage) return;

        setError(
          errorMessage,
          {
            type: "api",
            message: error,
          },
          {
            shouldFocus: true,
          }
        );
      });

      if ((e as ApiFormError).response.data.error.errors.pets) {
        dispatch(
          setNoPetError((e as ApiFormError).response.data.error.errors.pets)
        );
      }

      if ((e as ApiError).response?.status === 403) {
        handleError(e as ApiError);

        dispatch(resetFormState());
        dispatch(resetCampaignState());
        dispatch(resetUserState());

        localStorage.clear();
        sessionStorage.clear();

        history.push(`/?campaignId=${currentCampaignId}`);
      }
    }
  };

export const findAddressByZipCode =
  (
    zipCode: string,
    zipType: ZipType,
    setValue: (value: string) => void,
    setError: (error: string) => void,
    selectedValue: string = "",
  ): AppThunk =>
  async (dispatch) => {
    const addressApi = instantiateAddressApi();

    if (!zipCode) {
      return;
    }

    try {
      const res = await addressApi.getAddressZip(zipCode.toString());

      if (!res.data.length) {
        setError("存在しない郵便番号が入力されました。");
        setValue("");
      }

      dispatch(
        setZipSearchResults({
          results: res.data,
          zipType: zipType,
        })
      );


      if (res.data.length) {
        const matchedIndex = res.data.indexOf(selectedValue)
        if (matchedIndex !== -1) {
          setValue(res.data[matchedIndex]);
          return;
        }

        setValue(res.data[0]);
      }
    } catch (e) {
      if ((e as ApiError).response?.status === 500) {
        handleError(e as ApiError);

        return;
      }

      if ((e as ApiFormError)?.response?.data?.error?.errors) {
        if ((e as ApiFormError).response.data.error.errors.zip) {
          setError((e as ApiFormError).response.data.error.errors.zip);
        } else {
          // サーバーの予期していないエラー類
          // setError((e as ApiFormError).response.data.error.message);
          setError("接続できませんでした。時間をおいて再度実行してください");
        }
        return;
      }
    }
  };
