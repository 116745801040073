import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

import { Button } from "./Button";

interface ModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  message,
  confirmButtonText,
  cancelButtonText,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onCancel}
    style={{
      overlay: {
        background: "rgb(75, 75, 75, 0.7)",
      },
      content: {
        top: "40%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        height: "260px",
        maxWidth: "388px",
        width: "350px",
        borderRadius: "20px",
        textAlign: "center",
      },
    }}
  >
    <ModalText>{message}</ModalText>

    <ModalConfirmButton
      type="button"
      icon="arrow"
      color="white"
      onClick={onConfirm}
    >
      {confirmButtonText}
    </ModalConfirmButton>

    <ModalCancelButton type="button" icon="arrow" onClick={onCancel}>
      {cancelButtonText}
    </ModalCancelButton>
  </ReactModal>
);

const ModalText = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0 40px 0;
`;

const ModalConfirmButton = styled(Button)`
  width: 80%;
  margin-bottom: 15px;
`;

const ModalCancelButton = styled(Button)`
  width: 80%;
  text-align: center;
`;
