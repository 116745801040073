import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import form from "../features/form/formSlice";
import campaign from "../features/campaign/campaignSlice";
import user from "../features/user/userSlice";

export const store = configureStore({
  reducer: { form, campaign, user },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
