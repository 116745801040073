import { useState } from "react";
import styled from "styled-components";
import { Redirect, useHistory } from "react-router";

import Attention from "../assets/images/attention_red@3x.png";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Modal } from "../components/Modal";
import { unsubscribe } from "../features/user/userSlice";
import { saveStoreData } from "../utils/saveStoreData";

export const Unsubscribe = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const { loggedIn } = useAppSelector((state) => state.user);

  if (!loggedIn) {
    return <Redirect push to="/?noCampaignPath=unsubscribe" />;
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        onConfirm={async () => {
          await dispatch(unsubscribe(history));
          saveStoreData();
        }}
        onCancel={() => setShowModal(false)}
        message="本当に登録解除しますか？"
        confirmButtonText="登録解除"
        cancelButtonText="いいえ"
      />

      <Title>登録解除</Title>

      <AttentionImage src={Attention} />

      <DescriptionSmall>
        本サービスの登録解除を行います。
        <br />
        登録解除をすると以下のサービスの利用ができなくなります。
      </DescriptionSmall>

      <StyledCard>
        <List>
          <li>応募先として登録したお名前や住所情報の保持</li>
          <li>ご登録いただいたペットの情報</li>
          <li>プレゼントやキャンペーン、商品のご案内</li>
        </List>
      </StyledCard>

      <DescriptionLarge>
        ＜ペット共通 IDをご利用の方＞
        <br />
        同じID（メールアドレス）で下記の対象サービスをご利用の場合、本サービス以外は引き続きご利用いただけます。
        <br />
        <br />
        ご登録いただいたペット情報は他サービスにて修正可能です
        <br />
        <StyledLink href="https://petcare.benesse.ne.jp/faq/81845">
          ＞対象サービスはこちら
        </StyledLink>
      </DescriptionLarge>

      <UnsubscribeButton
        type="button"
        icon="arrow"
        onClick={() => setShowModal(true)}
      >
        登録を解除する
      </UnsubscribeButton>
    </>
  );
};

const Title = styled.h1`
  font-size: 26px;
  font-weight: 600;
`;

const AttentionImage = styled.img`
  width: 44px;
  height: 38px;
`;

const DescriptionSmall = styled.p`
  text-align: center;
  font-size: 12px;
  line-height: 18px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 24px;
  width: 100%;
`;

const List = styled.ul`
  color: #c10000;
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  padding-inline-start: 20px;
`;

const DescriptionLarge = styled.p`
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 40px;
`;

const StyledLink = styled.a`
  color: #367fb9;
  font-size: 14px;
  text-decoration: underline;
`;

const UnsubscribeButton = styled(Button)`
  width: 80%;
  margin-bottom: 45px;
`;
