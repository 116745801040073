import styled from "styled-components";

export const Card = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 6px 0 #00000029;
  padding: 20px;

  > * {
    width: 100%;
  }
`;
