import styled from "styled-components";
import "../assets/css/ckeditor.css";
import { Card } from "../components/Card";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import axios from "axios";
import {
  setThanksData,
} from "../features/user/userSlice";
import TagManager from 'react-gtm-module';

export const Thanks = () => { 
  const dispatch = useAppDispatch();
  const { ThanksData } = useAppSelector(
    (state) => state.user
  );
  const query = new URLSearchParams(useLocation().search);
  let campaignId = query.get("campaignId") as string;

  if (campaignId === null) {
    const url = window.location.pathname;
    campaignId = url.substring(url.lastIndexOf('/', url.length) + 1) as string;
  }

  if (ThanksData === undefined) {
    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/api/v1/getThanks/${campaignId}`)
    .then(response => {
      dispatch(setThanksData(response.data.thanks));
      
      TagManager.initialize({
        gtmId: 'GTM-TX6427',
      })
      TagManager.dataLayer({
        dataLayer: {
          event: "precam_thanks_view",
          precam_campaign_id: campaignId,
          precam_campaign_name: response.data.thanks.name,
        },
      });
    })
  }
  return (
    <>
        {ThanksData && (
          <>
            <TitleContainer>
              <Title>{ThanksData.name}</Title>
            </TitleContainer>

            {ThanksData.is_heading_display && (
              <CampaignHeadingInfo>
                {ThanksData?.heading}
              </CampaignHeadingInfo>
            )}

            <ThanksCard><div dangerouslySetInnerHTML={{__html: ThanksData.description}}></div></ThanksCard>
          </>
        )}
    </>
  );
}

const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-shadow: 0px 3px 6px #00000029;
  padding: 0px 43px;
  white-space: pre-wrap;
`;

const TitleContainer = styled.div`
  width: 100vw;
  background-color: #ef8205;
  box-shadow: #00000029 0px 7px 6px;
`;

const CampaignHeadingInfo = styled.p`
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  white-space: pre-wrap;
  padding: 0px 43px;
`;

const ThanksCard = styled(Card)`
  margin-top: 20px;
  width: 100%;
  padding: 0px 43px;
  height: auto;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
`;