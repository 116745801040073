import styled from "styled-components";

import CheckIcon from "../assets/images/check@3x.png";

interface CheckBoxProps {
  isSelected: boolean;
  onClick: () => void;
  label: string;
  className?: string;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  isSelected,
  onClick,
  label,
  className,
}) => (
  <Container onClick={onClick} className={className}>
    <Box>{isSelected && <CheckMark src={CheckIcon} />}</Box>

    <Label>{label}</Label>
  </Container>
);

const Container = styled.div`
  display: flex;
  cursor: pointer;

  /** Safariのちらつき対策 */
  -webkit-tap-highlight-color: transparent;
`;

const Box = styled.div`
  position: relative;
  height: 25px;
  width: 25px;
  border: 1px #afafaf solid;
  border-radius: 3px;
  margin-right: 10px;
`;

const CheckMark = styled.img`
  position: absolute;
  top: -3px;
  left: 2px;
  height: 25px;
  width: 25px;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 25px;
`;
