import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./app/App";
import { store } from "./app/store";
import theme from "./theme/index";
import GlobalStyle from "./theme/globalStyle";
import { ScrollToTop } from "./components/ScrollToTop";
import { reconstructor as userReconstructor} from "./features/user/userSlice";
import axios from "axios";

(async () => {
  store.dispatch((() => {
    return async () => {
      await axios.get(process.env.REACT_APP_BACKEND_API_URL + "/api/v1/front_state").then(async (response: any) => {
        store.dispatch(userReconstructor(response.data.user));
      });
    }
  })()).then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTop />

            <GlobalStyle />

            <ToastContainer theme="colored" position="top-center" />

            <Provider store={store}>
              <App />
            </Provider>
          </BrowserRouter>
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
})()
