import {
  LoginApi,
  DefaultApi,
  UserApi,
  CampaignApi,
  AddressApi,
} from "../types/typescript-axios";

const standardConfig = {
  basePath: process.env.REACT_APP_BACKEND_API_URL,
  isJsonMime: (mime: string) => {
    const jsonMime: RegExp = new RegExp(
      // eslint-disable-next-line no-control-regex
      "^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$",
      "i"
    );
    return (
      mime !== null &&
      (jsonMime.test(mime) ||
        mime.toLowerCase() === "application/json-patch+json")
    );
  },
};

export const instantiateDefaultApi = () => new DefaultApi(standardConfig);

export const instantiateLoginApi = () => new LoginApi(standardConfig);

export const instantiateUserApi = () => new UserApi(standardConfig);

export const instantiateCampaignApi = () => new CampaignApi(standardConfig);

export const instantiateAddressApi = () => new AddressApi(standardConfig);
