import styled from "styled-components";

import { Footer } from "../features/Footer";
import { Stepper } from "../features/form/stepper/Stepper";
import { Header } from "../features/Header";

interface StandardTemplateProps {
  logo?: "standard" | "large";
  waveColor?: "beige" | "orange";
  sidePadding?: number;
  title?: string;
  showStepper?: boolean;
  iconImage?: "headerLoading" | "bodyLoading";
}

export const StandardTemplate: React.FC<StandardTemplateProps> = ({
  children,
  logo = "standard",
  waveColor = "beige",
  sidePadding = 20,
  title,
  showStepper,
  iconImage = "headerLoading",
}) => (
  <>
    <Header logo={logo} waveColor={waveColor} title={title} iconImage={iconImage}/>

    <Wrapper>
      <Container sidePadding={sidePadding}>
        {showStepper && <Stepper />}
        {children}
      </Container>
    </Wrapper>

    <Footer />
  </>
);

const Wrapper = styled.div`
  position: relative;
  top: -1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdecd8;
  flex: 1;
`;

const Container = styled.div<{ sidePadding: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${({ sidePadding }) => sidePadding}px 20px
    ${({ sidePadding }) => sidePadding}px;
  max-width: 700px;
`;
