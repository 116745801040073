import styled from "styled-components";

import { InlineResponse2002Presents as PresentData } from "../../types/typescript-axios";

interface PresentProps {
  present: PresentData;
}

export const Present: React.FC<PresentProps> = ({ present }) => (
  <StyledPresent fullWidth={present.shape_type === "full"}>
    {present.shape_type === "full" ? (
      <ImageContainer>
        <ImageWrapper className="wrapper">
          <Image src={present.image_url} alt="賞品Photo" />
        </ImageWrapper>
      </ImageContainer>
    ) : (
      <SquareImageContainer>
        <SquareImageWrapper className="wrapper">
          <SquareImage src={present.image_url} alt="賞品Photo" />
        </SquareImageWrapper>
      </SquareImageContainer>
    )}

    <Title>{present.name}</Title>

    <PresentCount>
      <PresentCountLabel>{present.number_of_winners}</PresentCountLabel>
      <span>名</span>
    </PresentCount>

    <Description>{present.description}</Description>
  </StyledPresent>
);

const StyledPresent = styled.div<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? 100 : 47)}%;
`;

const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  text-align: center;
  border-radius: 10px;
`;

const SquareImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const SquareImageWrapper = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

const SquareImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  border-radius: 10px;
`;

const PresentCount = styled.div`
  margin: 8px;
  text-align: center;
`;

const PresentCountLabel = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

const Title = styled.h2`
  overflow-wrap: break-word;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  font-weight: 600;
`;

const Description = styled.p`
  overflow-wrap: break-word;
  margin: 0 0 36px 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
  white-space: pre-wrap;
`;
