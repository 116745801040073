import styled from "styled-components";

import ArrowOrange from "../assets/images/arrow@3x.png";
import ArrowWhite from "../assets/images/arrow_white@3x.png";

type Icon = "arrow";

interface ButtonProps {
  icon?: Icon;
  color?: "orange" | "white";
}

export const Button: React.FC<
  ButtonProps &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  children,
  icon,
  className,
  type,
  onClick,
  disabled,
  color = "orange",
}) => (
  <StyledButton
    type={type}
    className={className}
    onClick={onClick}
    disabled={disabled}
    color={color}
  >
    {children}
    {icon === "arrow" ? (
      <RightArrow src={color === "orange" ? ArrowWhite : ArrowOrange} />
    ) : (
      ""
    )}
  </StyledButton>
);

const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  height: 45px;
  color: ${({ color }) => (color === "white" ? "#000" : "#fff")};
  background-color: ${({ color }) => (color === "white" ? "#fff" : "#ef8205")};
  font-size: 20px;
  border: 1px ${({ color }) => (color === "white" ? "#4b4b4b" : "#ef8205")}
    solid;
  border-radius: 20px;
`;

const RightArrow = styled.img`
  position: absolute;
  top: 10px;
  right: 20px;
  height: 21px;
  width: 11px;
`;
