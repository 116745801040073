import { useState } from "react";
import styled from "styled-components";
import {
  SubmitHandler,
  SubmitErrorHandler,
  useFieldArray,
  useForm,
  Controller,
  FieldPath,
} from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";
import { useAppDispatch } from "../../app/hooks";
import {
  findAddressByZipCode,
  Subscription,
  setNoPetError,
  validateAndSaveFormValues,
} from "../form/formSlice";
import { FormValue } from "../form/FormResponse";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { Label } from "../../components/Label";
import { Input } from "../../components/Input";
import { useAppSelector } from "../../app/hooks";
import { Gender, PetTypeEnum as PetType } from "../../types/typescript-axios";
import { regExps } from "../../utils/regExps";
import { saveStoreData } from "../../utils/saveStoreData";
import { petData } from "../../assets/data/pets";
import { Modal } from "../../components/Modal";
import { Radio } from "../../components/Radio";
import { Newsletters } from "../form/Newsletters";
import { DateOfBirth, RegistrationInputs } from "../../pages/Registration";
import eyeOpen from "../../assets/images/eyeOpen.svg";
import eyeClose from "../../assets/images/eye-close.svg";

interface HideContainerProps {
  initialHeight: boolean;
}

interface DisplayAllLabelProps {
  isShow: boolean;
}

export const RegistrationForm = () => {
  const [petToDelete, setPetToDelete] = useState<number>();
  const [fillRequiredFields, setFillRequiredFields] = useState(false);

  const defaultPetValues = {
    petId: uuid(),
    have: "1",
    havehave: "1",
    orghave: "",
    name: "",
    dateOfBirth: {
      year: "",
      month: "",
      day: "",
    },
    gender: Gender.Male,
    type: PetType.Dog,
    type2: PetType.Dog,
    breed: "",
  };

  const { email, applicationType, loggedIn, userData, userPetData, MagazineJudgeUserData } = useAppSelector(
    (state) => state.user
  );
  const { formValues, fieldSettings, noPetError, zipSearchResults } = useAppSelector(
    (state) => state.form
  );

  let registerablePetCount = 50;
  if (loggedIn && userData?.registerable_pet_count) {
    registerablePetCount = userData?.registerable_pet_count;
  }

  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    getValues,
    setValue,
    setError,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm<RegistrationInputs>({
    mode: "onChange",
    shouldUnregister: false,
    defaultValues: formValues ?? {
      ...(applicationType !== "skipRegistration" && {
        user: {
          surname: userData?.last_name ? userData?.last_name : MagazineJudgeUserData?.surname ? MagazineJudgeUserData?.surname : '',
          name: userData?.first_name ? userData?.first_name :MagazineJudgeUserData?.name ? MagazineJudgeUserData?.name : '',
          surnameKatakana: userData?.last_name_kana,
          nameKatakana: userData?.first_name_kana,
          ...(fieldSettings.delivery.show && {
            gender: userData?.gender || Gender.Female,
            dateOfBirth: userData?.birthday
              ? {
                  year: userData.birthday.split("-")[0],
                  month: userData.birthday.split("-")[1],
                  day: userData.birthday.split("-")[2],
                }
              : MagazineJudgeUserData?.year
              ? {
                  year: MagazineJudgeUserData?.year,
                  month: MagazineJudgeUserData?.month,
                  day: MagazineJudgeUserData?.day,
              }
              : undefined,
            phoneNumber: userData?.telephone_number
              ? {
                  firstPart: userData.telephone_number.split("-")[0],
                  secondPart: userData.telephone_number.split("-")[1],
                  thirdPart: userData.telephone_number.split("-")[2],
                }
              : MagazineJudgeUserData?.firstPart
              ? {
                  firstPart: MagazineJudgeUserData?.firstPart,
                  secondPart: MagazineJudgeUserData?.secondPart,
                  thirdPart: MagazineJudgeUserData?.thirdPart,
              }
              : {},
            zipCode: userData?.postal_code ? userData.postal_code : undefined,
            address: {
              firstPart: userData?.address1,
              secondPart: userData?.address2,
            },
          }),
          subscribeToNewsletter:
            userData?.is_subscription_mail_magazine ===
              Subscription.NotSubscribed ||
            userData?.is_subscription_mail_magazine === null
              ? false
              : true,
          newsletters:
            userData?.is_need_senior_lead ===
              Subscription.NotSubscribed ||
              userData?.is_need_senior_lead === null
              ? []
              : ["longevity"]
          ,
          ...(email && { email }),
        },
      }),
      ...(applicationType !== "skipRegistration" &&
        fieldSettings.pet.show && {
          pets: userPetData?.length
            ? userPetData.map((pet) => ({
                petId: pet.id,
                have: pet.have,
                orghave: pet.have,
                havehave: pet.havehave,
                name: pet.name,
                dateOfBirth: pet?.birthday
                  ? {
                      year: pet.birthday.split("-")[0],
                      month: pet.birthday.split("-")[1],
                      day: pet.birthday.split("-")[2],
                    }
                  : {
                    year: "",
                    month: "",
                    day: "",
                  },
                gender: pet.gender || Gender.Male,
                type: pet.type || pet.type2 || PetType.Dog,
                type2: pet.type2 || PetType.Dog,
                breed: pet.breed,
              }))
            : [],
        }),
    },
  });

  const { fields, append, remove } = useFieldArray({ control, name: "pets" });

  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [isRevealConfirmPassword, setIsRevealConfirmPassword] = useState(false);
  const [isDeliveryHidden, setDeliveryHidden] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  }
  const toggleConfirmPassword = () => {
    setIsRevealConfirmPassword((prevState) => !prevState);
  }

  const changeDeliveryStyle = () => {
    setDeliveryHidden((prevState) => !prevState);
  }

  const user = watch("user");
  const pets = watch("pets");

  const handleTypeChange = (petType: PetType, petIndex: number) => {
    if (!pets[petIndex].breed) {
      setValue(`pets.${petIndex}.type`, petType);
      setValue(`pets.${petIndex}.type2`, petType)
      return;
    }

    let values = getValues();
    setValue(`pets.${petIndex}.breed`, "")
    setValue(`pets.${petIndex}.type`, petType)
    setValue(`pets.${petIndex}.type2`, petType)
    trigger(`pets.${petIndex}.breed`)
    reset({ ...values });
  };

  const handleType2Change = (petType: PetType, petIndex: number) => {
    let values = getValues();
    setValue(`pets.${petIndex}.type2`, petType)
    setValue(`pets.${petIndex}.type`, petType)
    setValue(`pets.${petIndex}.breed`, "")
    trigger(`pets.${petIndex}.breed`)
    reset({ ...values });
  };

  const validateDate = (date: DateOfBirth, fieldPath: string) => {
    let isValid = true;
    if (date.year === "" || date.month === "" || date.day === "") {
      return isValid;
    } else {
      const dateObj = new Date(
        parseInt(date.year),
        parseInt(date.month) - 1,
        parseInt(date.day)
      );
  
      if (dateObj > new Date()) {
        setError(
          `${fieldPath}.year` as FieldPath<RegistrationInputs>,
          { type: "invalidDate" },
          { shouldFocus: true }
        );
  
        setError(`${fieldPath}.month` as FieldPath<RegistrationInputs>, {
          type: "invalidDate",
        });
  
        setError(`${fieldPath}.day` as FieldPath<RegistrationInputs>, {
          type: "invalidDate",
        });
  
        isValid = false;
      }
  
      if (dateObj.getMonth() + 1 !== parseInt(date.month)) {
        setError(
          `${fieldPath}.month` as FieldPath<RegistrationInputs>,
          { type: "invalidDate" },
          { shouldFocus: true }
        );
  
        isValid = false;
      }
  
      if (dateObj.getDate() !== parseInt(date.day)) {
        setError(
          `${fieldPath}.day` as FieldPath<RegistrationInputs>,
          { type: "invalidDate" },
          { shouldFocus: true }
        );
  
        isValid = false;
      }
  
      return isValid;
  
    }
  };

  const onSubmit: SubmitHandler<RegistrationInputs> = async (data) => {
    setFillRequiredFields(false);
    // どちらもエラーだった場合、userにfocusしてほしいのでdeliveryを先にバリデートしている
    const hasErrorDeliveryAddress = await invalidateZipCode("delivery");
    const hasErrorUserAddress = await invalidateZipCode("user");

    if (hasErrorUserAddress || hasErrorDeliveryAddress) {
      return;
    }

    if (
      data.user?.dateOfBirth?.year ||
      data.user?.dateOfBirth?.month ||
      data.user?.dateOfBirth?.day
    ) {
      if (
        !(
          data.user?.dateOfBirth?.year &&
          data.user?.dateOfBirth?.month &&
          data.user?.dateOfBirth?.day
        )
      ) {
        Object.entries(data.user?.dateOfBirth).forEach(([key, value]) => {
          if (!value)
            setError(
              `user.dateOfBirth.${key as "year" | "month" | "day"}`,
              { type: "allFieldsRequired" },
              { shouldFocus: true }
            );
        });

        return;
      }

      if (!validateDate(data.user?.dateOfBirth, "user.dateOfBirth")) return;
    }

    for (let index in data.pets) {
      const pet = data.pets[index];

      if (!validateDate(pet.dateOfBirth, `pets.${index}.dateOfBirth`)) return;
    }

    await dispatch(validateAndSaveFormValues(data, history, setError));
    saveStoreData();
  };

  const onSubmitError: SubmitErrorHandler<RegistrationInputs> = async (errors) => {
    if (containsRequiredError(errors)) {
      setFillRequiredFields(true);
    }
  }

  const containsRequiredError = (errors: any): boolean => {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const error = errors[key];

        if (Array.isArray(errors)) {
          for (const error of errors) {
            if (containsRequiredError(error)) {
              return true;
            }
          }
        }

        if (Object.keys(error).length > 3) {
          continue;
        }
  
        if (error.type === 'required') {
          return true;
        }
  
        if (typeof error === 'object' && error !== null) {
          if (containsRequiredError(error)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const invalidateZipCode = async (zipType: string) => {
    let isInvalidate = false;

    // 想定のzipTypeじゃない場合はおかしいから中断
    if (!(zipType === "user" || zipType === "delivery"))
      return (isInvalidate = true);
    // zipTypeがなければ異常なし
    const zipCode = getValues(`${zipType}.zipCode`);
    if (!zipCode) return isInvalidate;

    let hasError = false;
    await dispatch(
      findAddressByZipCode(
        zipCode,
        zipType,
        (value) => {
          setValue(`${zipType}.address.firstPart`, value);
          if (value == null || value.length < 1) {
            // 結果がなかった場合は存在しなかった
            isInvalidate = true;
          }
        },
        (error) => {
          setError(
            `${zipType}.zipCode`,
            {
              type: "api",
              message: error,
            },
            {
              shouldFocus: true,
            }
          );

          hasError = true;
        },
        getValues(`${zipType}.address.firstPart`) ?? ""
      )
    );
    if (hasError) {
      return (isInvalidate = true);
    }

    return isInvalidate;
  };

  const phoneValidateRules = (fieldName: string, type: "user" | "delivery") => {
    const rules =  {
      complete: (value: any) => {
        const phoneNumberErrors: any = errors?.[type]?.phoneNumber ?? {};

        // 他のエラーが既に存在する場合は何もしない
        for (const key in phoneNumberErrors) {
          const error = phoneNumberErrors[key];

          if (error.ref.name === fieldName) {
            continue;
          }

          if (!Object.keys(rules).includes(error.type)) {
            return true;
          }
        }

        const first = getValues(`${type}.phoneNumber.firstPart`)?.toString();
        const second = getValues(`${type}.phoneNumber.secondPart`)?.toString();
        const third = getValues(`${type}.phoneNumber.thirdPart`)?.toString();

        clearErrors(`${type}.phoneNumber`);

        // いずれかが入力されている、かつ、全て入力されていない
        if ((first || second || third) && !(first && second && third)) {
          if (!first) {
            setError(`${type}.phoneNumber.firstPart`, {type: "complete"});
          }
          if (!second) {
            setError(`${type}.phoneNumber.secondPart`, {type: "complete"});
          }
          if (!third) {
            setError(`${type}.phoneNumber.thirdPart`, {type: "complete"});
          }

          return !!value;
        }

        return true;
      },
      format: () =>  {
        const phoneNumberErrors: any = errors?.[type]?.phoneNumber ?? {};

        // 他のエラーが既に存在する場合は何もしない
        for (const key in phoneNumberErrors) {
          const error = phoneNumberErrors[key];

          if (error.ref.name === fieldName) {
            continue;
          }

          if (!Object.keys(rules).includes(error.type)) {
            return true;
          }
        }

        const first = getValues(`${type}.phoneNumber.firstPart`)?.toString();
        const second = getValues(`${type}.phoneNumber.secondPart`)?.toString();
        const third = getValues(`${type}.phoneNumber.thirdPart`)?.toString();

        if (!first || !second || !third) {
          return true;
        }

        clearErrors(`${type}.phoneNumber`);

        const phoneNumber = [first, second, third].join(`-`)

        if (!regExps.phone.test(phoneNumber)) {
          setError(`${type}.phoneNumber.firstPart`, {type: `format`});
          setError(`${type}.phoneNumber.secondPart`, {type: `format`});
          setError(`${type}.phoneNumber.thirdPart`, {type: `format`});

          return false;
        }

        return true;
      },
    }

    return rules;
  }

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const handleShowPasswordForm = () => {
    setShowPasswordForm(true);
  };
  const handleHidePasswordForm = () => {
    setShowPasswordForm(false);
    setValue("user.password.firstTime", "");
    setValue("user.password.secondTime", "");
  };

  const formId = uuid();

  let initHasPets = false;
  if (userPetData?.length) initHasPets = true;
  let initIsUser = false;
  if (userData !== undefined) initIsUser = true;
  if (MagazineJudgeUserData !== undefined) {
    if (MagazineJudgeUserData?.surname !== "" && MagazineJudgeUserData?.name !== "") initIsUser = true;
  }

  return (
    <>
      <Modal
        isOpen={petToDelete !== undefined}
        onConfirm={() => {
          remove(petToDelete);
          setPetToDelete(undefined);
        }}
        onCancel={() => setPetToDelete(undefined)}
        message={`本当にペット（${
          petToDelete !== undefined ? petToDelete + 1 : ""
        }）の登録を解除しますか？`}
        confirmButtonText="登録解除"
        cancelButtonText="いいえ"
      />

      {applicationType === "login" && (
        <>
          <Title>登録情報確認</Title>
        </>
      )}
      {applicationType === "registration" && (
        <>
          <Title>お客様情報入力</Title>
        </>
      )}
      {applicationType === "skipRegistration" && (
        <>
          <Title></Title>
        </>
      )}

      <StyledForm onSubmit={handleSubmit(onSubmit, onSubmitError)} noValidate>
        {applicationType !== "skipRegistration" && (
          <>
          {fillRequiredFields && (
            <ErrorRequiredMessage>必須項目が入力されていません</ErrorRequiredMessage>
          )}
          {noPetError && (
            <ErrorRequiredMessage>必須項目が入力されていません</ErrorRequiredMessage>
          )}
          {applicationType === "login" && (
            <>
              <SubmitButton icon="arrow">確認して次へ</SubmitButton>
              <SubmitTopDescription>
                変更する場合は下記を編集してください。
              </SubmitTopDescription>
            </>
          )}
            <CardWithMargin>
              <DisplayAllButton
                id={`${formId}-01`}
                type="checkbox"
                isShow={initIsUser}
              />
              <DisplayAllLabel
                htmlFor={`${formId}-01`}
                isShow={initIsUser}
              />
              <HideContainer
                initialHeight={initIsUser}
                className="cp_container"
              >
                <SectionTitle>応募者情報</SectionTitle>

                <Label required>氏名（漢字）</Label>

                <InputContainer>
                  <NameErrorContainer>
                    <Input
                      {...register("user.surname", {
                        required: true,
                      })}
                      placeholder="姓"
                      error={!!errors.user?.surname}
                    />

                    {errors.user?.surname?.type === "required" && (
                      <ErrorNameMessage>姓は必ず入力して下さい。</ErrorNameMessage>
                    )}

                    {errors.user?.surname?.type === "api" && (
                      <ErrorNameMessage>{errors.user?.surname?.message}</ErrorNameMessage>
                    )}

                    {errors.user?.name?.type === "required" && (
                      <ErrorNameMessage>名は必ず入力して下さい。</ErrorNameMessage>
                    )}

                    {errors.user?.name?.type === "api" && (
                      <ErrorNameMessage>{errors.user?.name?.message}</ErrorNameMessage>
                    )}
                  </NameErrorContainer>

                  <NameErrorContainer>
                    <Input
                      {...register("user.name", {
                        required: true,
                      })}
                      placeholder="名"
                      error={!!errors.user?.name}
                    />

                  </NameErrorContainer>
                </InputContainer>

                <Label required>氏名（カタカナ）</Label>

                <InputContainer>
                  <NameErrorContainer>
                    <Input
                      {...register("user.surnameKatakana", {
                        required: true,
                        pattern: regExps.katakana,
                      })}
                      placeholder="姓（カタカナ）"
                      error={!!errors.user?.surnameKatakana}
                    />

                    {errors.user?.surnameKatakana?.type === "required" && (
                      <ErrorNameMessage>
                        姓（カタカナ）は必ず入力して下さい。
                      </ErrorNameMessage>
                    )}

                    {errors.user?.surnameKatakana?.type === "pattern" && (
                      <ErrorNameMessage>姓をカタカナで入力してください</ErrorNameMessage>
                    )}

                    {errors.user?.surnameKatakana?.type === "api" && (
                      <ErrorNameMessage>
                        {errors.user?.surnameKatakana?.message}
                      </ErrorNameMessage>
                    )}

                    {errors.user?.nameKatakana?.type === "required" && (
                      <ErrorNameMessage>
                        名（カタカナ）は必ず入力して下さい。
                      </ErrorNameMessage>
                    )}

                    {errors.user?.nameKatakana?.type === "pattern" && (
                      <ErrorNameMessage>名をカタカナで入力してください</ErrorNameMessage>
                    )}

                    {errors.user?.nameKatakana?.type === "api" && (
                      <ErrorNameMessage>
                        {errors.user?.nameKatakana?.message}
                      </ErrorNameMessage>
                    )}
                  </NameErrorContainer>

                  <NameErrorContainer>
                    <Input
                      {...register("user.nameKatakana", {
                        required: true,
                        pattern: regExps.katakana,
                      })}
                      placeholder="名（カタカナ）"
                      error={!!errors.user?.nameKatakana}
                    />

                  </NameErrorContainer>
                </InputContainer>

                {fieldSettings.delivery.show && (
                  <>
                    <Label>性別</Label>

                    <Controller
                      control={control}
                      name={`user.gender`}
                      render={({ field: { onChange, value } }) => (
                        <GenderInputContainer>
                          <StyledRadio
                            onClick={() => onChange(Gender.Female)}
                            isSelected={value === Gender.Female}
                            label="女性"
                          />

                          <StyledRadio
                            onClick={() => onChange(Gender.Male)}
                            isSelected={value === Gender.Male}
                            label="男性"
                          />

                          <StyledRadio
                            onClick={() => onChange(Gender.Other)}
                            isSelected={value === Gender.Other}
                            label="無回答"
                          />
                        </GenderInputContainer>
                      )}
                    />

                    <Label>生年月日</Label>

                    <ErrorContainer>
                      <DateInputContainer>
                        <YearInput
                          {...register("user.dateOfBirth.year", {
                            minLength: 4,
                            maxLength: 4,
                            min: 1900,
                          })}
                          error={!!errors.user?.dateOfBirth?.year}
                          type="number"
                          placeholder="年"
                        />

                        <MonthDayInput
                          {...register("user.dateOfBirth.month", {
                            minLength: 1,
                            maxLength: 2,
                          })}
                          error={!!errors.user?.dateOfBirth?.month}
                          type="number"
                          placeholder="月"
                        />

                        <MonthDayInput
                          {...register("user.dateOfBirth.day", {
                            minLength: 1,
                            maxLength: 2,
                          })}
                          error={!!errors.user?.dateOfBirth?.day}
                          type="number"
                          placeholder="日"
                        />
                      </DateInputContainer>

                      {errors.user?.dateOfBirth?.year?.type === "min"  && (
                        <ErrorMessage>生年月日を正しく入力して下さい。</ErrorMessage>
                      )}

                      {(errors.user?.dateOfBirth?.year?.type === "validate" ||
                        errors.user?.dateOfBirth?.month?.type === "validate" ||
                        errors.user?.dateOfBirth?.day?.type === "validate") && (
                        <ErrorMessage>生年月日は必ず指定して下さい。</ErrorMessage>
                      )}

                      {errors.user?.dateOfBirth?.year?.type === "api" && (
                        <ErrorMessage>
                          {errors.user?.dateOfBirth?.year?.message}
                        </ErrorMessage>
                      )}

                      {(errors.user?.dateOfBirth?.year?.type ===
                        "allFieldsRequired" ||
                        errors.user?.dateOfBirth?.month?.type ===
                          "allFieldsRequired" ||
                        errors.user?.dateOfBirth?.day?.type ===
                          "allFieldsRequired") && (
                        <ErrorMessage>
                          生年月日の入力が不足しています。
                        </ErrorMessage>
                      )}

                      {(errors.user?.dateOfBirth?.year?.type === "invalidDate" ||
                        errors.user?.dateOfBirth?.month?.type === "invalidDate" ||
                        errors.user?.dateOfBirth?.day?.type === "invalidDate") && (
                        <ErrorMessage>生年月日を正しく入力して下さい。</ErrorMessage>
                      )}

                      {(errors.user?.dateOfBirth?.year?.type === "maxLength" ||
                        errors.user?.dateOfBirth?.year?.type === "minLength") && (
                        <ErrorMessage>年は4桁で入力して下さい。</ErrorMessage>
                      )}

                      {(errors.user?.dateOfBirth?.month?.type === "maxLength" ||
                        errors.user?.dateOfBirth?.month?.type === "minLength") && (
                        <ErrorMessage>月は2桁までで入力して下さい。</ErrorMessage>
                      )}

                      {(errors.user?.dateOfBirth?.day?.type === "maxLength" ||
                        errors.user?.dateOfBirth?.day?.type === "minLength") && (
                        <ErrorMessage>日は2桁までで入力して下さい。</ErrorMessage>
                      )}
                    </ErrorContainer>

                    <Label>電話番号</Label>

                    <ErrorContainer>
                      <InputContainer>
                        <PhoneInput
                          {...register("user.phoneNumber.firstPart", {
                            maxLength: 5,
                            pattern: /^\d*$/,
                            validate: phoneValidateRules("user.phoneNumber.firstPart", "user"),
                          })}
                          placeholder="例）080"
                          type="tel"
                          pattern="\d*"
                          maxLength={5}
                          error={!!errors.user?.phoneNumber?.firstPart}
                        />

                        <PhoneInput
                          {...register("user.phoneNumber.secondPart", {
                            maxLength: 4,
                            pattern: /^\d*$/,
                            validate: phoneValidateRules("user.phoneNumber.secondPart", "user"),
                          })}
                          placeholder="0000"
                          type="tel"
                          pattern="\d*"
                          maxLength={4}
                          error={!!errors.user?.phoneNumber?.secondPart}
                        />

                        <PhoneInput
                          {...register("user.phoneNumber.thirdPart", {
                            maxLength: 4,
                            pattern: /^\d*$/,
                            validate: phoneValidateRules("user.phoneNumber.thirdPart", "user"),
                          })}
                          placeholder="0000"
                          type="tel"
                          pattern="\d*"
                          maxLength={4}
                          error={!!errors.user?.phoneNumber?.thirdPart}
                        />
                      </InputContainer>

                      {errors.user?.phoneNumber?.firstPart?.type ===
                        "maxLength" && (
                        <ErrorMessage>
                          1つ目の入力欄は最大5桁です。
                        </ErrorMessage>
                      )}

                      {errors.user?.phoneNumber?.secondPart?.type ===
                        "maxLength" && (
                        <ErrorMessage>
                          2つ目の入力欄は最大4桁です。
                        </ErrorMessage>
                      )}

                      {errors.user?.phoneNumber?.thirdPart?.type ===
                        "maxLength" && (
                        <ErrorMessage>
                          3つ目の入力欄は最大4桁です。
                        </ErrorMessage>
                      )}

                      {(errors.user?.phoneNumber?.firstPart?.type === "pattern" ||
                        errors.user?.phoneNumber?.secondPart?.type === "pattern" ||
                        errors.user?.phoneNumber?.thirdPart?.type === "pattern") && (
                        <ErrorMessage>電話番号は数値を入力してください</ErrorMessage>
                      )}

                      {errors.user?.phoneNumber?.firstPart?.type === "api" && (
                        <ErrorMessage>
                          {errors.user?.phoneNumber?.firstPart?.message}
                        </ErrorMessage>
                      )}

                      {(errors.user?.phoneNumber?.firstPart?.type === "complete" ||
                        errors.user?.phoneNumber?.secondPart?.type === "complete" ||
                        errors.user?.phoneNumber?.thirdPart?.type === "complete") && (
                        <ErrorMessage>
                          電話番号を入力する場合は全てのフィールドを入力してください
                        </ErrorMessage>
                      )}

                      {(errors.user?.phoneNumber?.firstPart?.type === "format" ||
                        errors.user?.phoneNumber?.secondPart?.type === "format" ||
                        errors.user?.phoneNumber?.thirdPart?.type === "format") && (
                        <ErrorMessage>
                          電話番号を入力してください。
                        </ErrorMessage>
                      )}
                    </ErrorContainer>

                    <Label>郵便番号（ハイフンなしの7桁）</Label>

                    <ErrorContainer>
                      <InputContainer>
                        <ZipInput
                          {...register("user.zipCode", {
                            minLength: 7,
                            maxLength: 7,
                            pattern: regExps.zipCode,
                          })}
                          placeholder="000000"
                          type="number"
                          pattern="\d*"
                          error={!!errors.user?.zipCode}
                        />

                        <AddressAutofillButton
                          type="button"
                          onClick={async () => {
                            const result = await trigger("user.zipCode");
                            const zipCode = await getValues("user.zipCode");

                            if (!result || !zipCode) return;

                            dispatch(
                              findAddressByZipCode(
                                zipCode,
                                "user",
                                (value) =>
                                  setValue("user.address.firstPart", value),
                                (error) =>
                                  setError("user.zipCode", {
                                    type: "api",
                                    message: error,
                                  })
                              )
                            );
                          }}
                        >
                          住所入力
                        </AddressAutofillButton>
                      </InputContainer>

                      {(errors.user?.zipCode?.type === "minLength" ||
                        errors.user?.zipCode?.type === "maxLength") && (
                        <ErrorMessage>7桁の郵便番号を入力して下さい。</ErrorMessage>
                      )}

                      {errors.user?.zipCode?.type === "pattern" && (
                        <ErrorMessage>
                          郵便番号を入力して下さい。
                        </ErrorMessage>
                      )}

                      {errors.user?.zipCode?.type === "api" && (
                        <ErrorMessage>{errors.user?.zipCode.message}</ErrorMessage>
                      )}
                    </ErrorContainer>

                    <Label>住所</Label>

                    <ErrorContainer>
                      {zipSearchResults["user"].length ? (
                        <Dropdown
                          {...register("user.address.firstPart")}
                          error={!!errors.user?.address?.firstPart}
                        >
                          {zipSearchResults["user"].map((value, index) => (
                            <option key={"address-" + index} value={value}>
                              {value}
                            </option>
                          ))}
                        </Dropdown>
                      ) : (
                        <Input
                          {...register("user.address.firstPart")}
                          error={!!errors.user?.address?.firstPart}
                          placeholder="東京都多摩市落合"
                        />
                      )}

                      {errors.user?.address?.firstPart?.type === "api" && (
                        <ErrorMessage>
                          {errors.user?.address?.firstPart?.message}
                        </ErrorMessage>
                      )}
                    </ErrorContainer>

                    <Label>住所2</Label>

                    <ErrorContainer>
                      <Input
                        {...register("user.address.secondPart")}
                        error={!!errors.user?.address?.secondPart}
                        placeholder="1-34"
                      />

                      {errors.user?.address?.secondPart?.type === "api" && (
                        <ErrorMessage>
                          {errors.user?.address?.secondPart?.message}
                        </ErrorMessage>
                      )}
                    </ErrorContainer>
                  </>
                )}

                <Label>メールアドレス</Label>

                <ErrorContainer>
                  <Input
                    {...register("user.email")}
                    placeholder=" "
                    disabled={!!email}
                    error={!!errors.user?.email}
                  />

                  {errors.user?.email?.type === "api" && (
                    <ErrorMessage>{errors.user?.email?.message}</ErrorMessage>
                  )}
                </ErrorContainer>

                <Newsletters control={control} />

                {applicationType === "login" && (
                  <>
                    {!showPasswordForm && (
                      <>
                        <Label>パスワード</Label>

                        <InputContainer>
                          <FormValue>・・・・・・・・・・</FormValue>

                          <PasswordToggleButton
                            type="button"
                            onClick={() => handleShowPasswordForm()}
                          >
                            変更
                          </PasswordToggleButton>
                            {isRevealPassword ? ('') : ('')}
                            {isRevealPassword ? ('') : ('')}
                            {isRevealPassword ? ('') : ('')}                       
                        </InputContainer>
                      </>
                    )}

                    {showPasswordForm && (
                      <>
                        <Label required>新しいパスワード</Label>

                        <ErrorContainer>
                          <Input
                            type={isRevealPassword ? 'text' : 'password'}
                            placeholder="8文字以上の半角英数記号"
                            {...register("user.password.firstTime", {
                              required: true,
                              minLength: 8,
                              maxLength: 32,
                              pattern: regExps.password,
                            })}
                            error={!!errors.user?.password?.firstTime}
                            icon={true}
                          />
                          <PasswordReveal>
                            <span 
                              onClick={togglePassword}
                              role="presentation"
                            >
                              {isRevealPassword ? (
                                <SmallEyes src={eyeOpen} />
                              ) : (
                                <SmallEyes src={eyeClose} />
                              )}
                            </span>
                          </PasswordReveal>

                          {errors.user?.password?.firstTime?.type ===
                            "required" && (
                            <ErrorMessage>
                              新しいパスワードは必ず指定して下さい。
                            </ErrorMessage>
                          )}

                          {errors.user?.password?.firstTime?.type ===
                            "minLength" && (
                            <ErrorMessage>
                              新しいパスワードを8文字以上にしてください
                            </ErrorMessage>
                          )}

                          {errors.user?.password?.firstTime?.type ===
                            "maxLength" && (
                            <ErrorMessage>
                              新しいパスワードを32文字以内にしてください
                            </ErrorMessage>
                          )}

                          {errors.user?.password?.firstTime?.type === "pattern" && (
                            <ErrorMessage>
                              新しいパスワードに使用できる文字は、半角英数字です。8文字以上32文字以下で英字と数字を含めてください
                            </ErrorMessage>
                          )}

                          {errors.user?.password?.firstTime?.type === "api" && (
                            <ErrorMessage>
                              {errors.user?.password?.firstTime?.message}
                            </ErrorMessage>
                          )}
                        </ErrorContainer>

                        <Label required>新しいパスワード（確認）</Label>

                        <ErrorContainer>
                          <Input
                            type={isRevealConfirmPassword ? 'text' : 'password'}
                            placeholder="上のパスワードと同じものを入力"
                            {...register("user.password.secondTime", {
                              required: true,
                              minLength: 8,
                              maxLength: 32,
                              pattern: regExps.password,
                              validate: (value) =>
                                user.password?.firstTime === value,
                            })}
                            icon={true}
                          />
                          <PasswordReveal>
                            <span 
                              onClick={toggleConfirmPassword}
                              role="presentation"
                            >
                              {isRevealConfirmPassword ? (
                                <SmallEyes src={eyeOpen} />
                              ) : (
                                <SmallEyes src={eyeClose} />
                              )}
                            </span>
                          </PasswordReveal>

                          {errors.user?.password?.secondTime?.type ===
                            "required" && (
                            <ErrorMessage>
                              新しいパスワード（確認）は必ず指定して下さい。
                            </ErrorMessage>
                          )}

                          {errors.user?.password?.secondTime?.type ===
                            "minLength" && (
                            <ErrorMessage>
                              新しいパスワード（確認）を8文字以上にしてください
                            </ErrorMessage>
                          )}

                          {errors.user?.password?.secondTime?.type ===
                            "maxLength" && (
                            <ErrorMessage>
                              新しいパスワード（確認）を32文字以内にしてください
                            </ErrorMessage>
                          )}

                          {errors.user?.password?.secondTime?.type ===
                            "pattern" && (
                            <ErrorMessage>
                              新しいパスワード（確認）に使用できる文字は、半角英数字です。8文字以上32文字以下で英字と数字を含めてください
                            </ErrorMessage>
                          )}

                          {errors.user?.password?.secondTime?.type ===
                            "validate" && (
                            <ErrorMessage>
                              新しいパスワードとパスワード（確認）が一致しません。
                            </ErrorMessage>
                          )}
                        </ErrorContainer>

                        <PasswordDescription>
                          パスワードに使用できる文字は、半角英数字です。
                          8文字以上32文字以下で英字・数字をすべて含めてください。
                        </PasswordDescription>

                        <PasswordToggleButtonContainer>
                          <PasswordToggleButton
                            type="button"
                            onClick={() => handleHidePasswordForm()}
                          >
                            パスワードを変更しない
                          </PasswordToggleButton>
                        </PasswordToggleButtonContainer>
                      </>
                    )}
                  </>
                )}

                {applicationType === "registration" && (
                  <>
                    <Label required>パスワード</Label>

                    <ErrorContainer>
                      <Input
                        type={isRevealPassword ? 'text' : 'password'}
                        placeholder="8文字以上の半角英数記号"
                        {...register("user.password.firstTime", {
                          required: true,
                          minLength: 8,
                          maxLength: 32,
                          pattern: regExps.password,
                        })}
                        error={!!errors.user?.password?.firstTime}
                        icon={true}
                      />
                      <PasswordReveal>
                        <span 
                          onClick={togglePassword}
                          role="presentation"
                        >
                          {isRevealPassword ? (
                            <SmallEyes src={eyeOpen} />
                          ) : (
                            <SmallEyes src={eyeClose} />
                          )}
                        </span>
                      </PasswordReveal>

                      {errors.user?.password?.firstTime?.type === "required" && (
                        <ErrorMessage>
                          パスワードは必ず指定して下さい。
                        </ErrorMessage>
                      )}

                      {errors.user?.password?.firstTime?.type === "minLength" && (
                        <ErrorMessage>
                          パスワードを8文字以上にしてください
                        </ErrorMessage>
                      )}

                      {errors.user?.password?.firstTime?.type === "maxLength" && (
                        <ErrorMessage>
                          パスワードを32文字以内にしてください
                        </ErrorMessage>
                      )}

                      {errors.user?.password?.firstTime?.type === "pattern" && (
                        <ErrorMessage>
                          パスワードに使用できる文字は、半角英数字です。8文字以上32文字以下で英字と数字を含めてください
                        </ErrorMessage>
                      )}

                      {errors.user?.password?.firstTime?.type === "api" && (
                        <ErrorMessage>
                          {errors.user?.password?.firstTime?.message}
                        </ErrorMessage>
                      )}
                    </ErrorContainer>

                    <Label required>パスワード（確認）</Label>

                    <ErrorContainer>
                      <Input
                        type={isRevealConfirmPassword ? 'text' : 'password'}
                        placeholder="上のパスワードと同じものを入力"
                        {...register("user.password.secondTime", {
                          required: true,
                          minLength: 8,
                          maxLength: 32,
                          pattern: regExps.password,
                          validate: (value) => user.password?.firstTime === value,
                        })}
                        icon={true}
                      />
                      <PasswordReveal>
                        <span 
                          onClick={toggleConfirmPassword}
                          role="presentation"
                        >
                          {isRevealConfirmPassword ? (
                            <SmallEyes src={eyeOpen} />
                          ) : (
                            <SmallEyes src={eyeClose} />
                          )}
                        </span>
                      </PasswordReveal>

                      {errors.user?.password?.secondTime?.type === "required" && (
                        <ErrorMessage>
                          パスワード（確認）は必ず指定して下さい。
                        </ErrorMessage>
                      )}

                      {errors.user?.password?.secondTime?.type === "minLength" && (
                        <ErrorMessage>
                          パスワードを8文字以上にしてください
                        </ErrorMessage>
                      )}

                      {errors.user?.password?.secondTime?.type === "maxLength" && (
                        <ErrorMessage>
                          パスワードを32文字以内にしてください
                        </ErrorMessage>
                      )}

                      {errors.user?.password?.secondTime?.type === "pattern" && (
                        <ErrorMessage>
                          パスワードに使用できる文字は、半角英数字です。8文字以上32文字以下で英字と数字を含めてください
                        </ErrorMessage>
                      )}

                      {errors.user?.password?.secondTime?.type === "validate" && (
                        <ErrorMessage>
                          パスワードが一致しません。
                        </ErrorMessage>
                      )}
                    </ErrorContainer>

                    <PasswordDescription>
                      パスワードに使用できる文字は、半角英数字です。
                      8文字以上32文字以下で英字・数字をすべて含めてください。
                    </PasswordDescription>
                  </>
                )}
              </HideContainer>
            </CardWithMargin>
          </>
        )}

        {fieldSettings.delivery.show && (
          <CardWithMargin>
            <SectionTitle>お届け先情報</SectionTitle>

            {applicationType !== "skipRegistration" && (
              <SectionDescription>
                ※ご登録いただいた住所と、賞品の届け先が違う場合にご入力ください。
              </SectionDescription>
            )}

            {applicationType !== "skipRegistration" && (
              <HiddenDelivery
                delivStyle={isDeliveryHidden}
              >

                <Label>届け先氏名</Label>

                <ErrorContainer>
                  <Input
                    {...register("delivery.fullName")}
                    placeholder=" "
                    error={!!errors.delivery?.fullName}
                  />

                  {errors.delivery?.fullName?.type === "api" && (
                    <ErrorMessage>
                      {errors.delivery?.fullName?.message}
                    </ErrorMessage>
                  )}
                </ErrorContainer>

                <Label>電話番号</Label>

                <ErrorContainer>
                  <InputContainer>
                    <PhoneInput
                      {...register("delivery.phoneNumber.firstPart", {
                        maxLength: 5,
                        pattern: /^\d*$/,
                        validate: phoneValidateRules("delivery.phoneNumber.firstPart", "delivery"),
                      })}
                      placeholder="例）080"
                      type="tel"
                      pattern="\d*"
                      maxLength={5}
                      error={!!errors.delivery?.phoneNumber?.firstPart}
                    />

                    <PhoneInput
                      {...register("delivery.phoneNumber.secondPart", {
                        maxLength: 4,
                        pattern: /^\d*$/,
                        validate: phoneValidateRules("delivery.phoneNumber.secondPart", "delivery"),
                      })}
                      placeholder="0000"
                      type="tel"
                      pattern="\d*"
                      maxLength={4}
                      error={!!errors.delivery?.phoneNumber?.secondPart}
                    />

                    <PhoneInput
                      {...register("delivery.phoneNumber.thirdPart", {
                        maxLength: 4,
                        pattern: /^\d*$/,
                        validate: phoneValidateRules("delivery.phoneNumber.thirdPart", "delivery"),
                      })}
                      placeholder="0000"
                      type="tel"
                      pattern="\d*"
                      maxLength={4}
                      error={!!errors.delivery?.phoneNumber?.thirdPart}
                    />
                  </InputContainer>

                  {errors.delivery?.phoneNumber?.firstPart?.type ===
                    "maxLength" && (
                    <ErrorMessage>
                      1つ目の入力欄は最大5桁です。
                    </ErrorMessage>
                  )}

                  {errors.delivery?.phoneNumber?.secondPart?.type ===
                    "maxLength" && (
                    <ErrorMessage>
                      2つ目の入力欄は最大4桁です。
                    </ErrorMessage>
                  )}

                  {errors.delivery?.phoneNumber?.thirdPart?.type ===
                    "maxLength" && (
                    <ErrorMessage>
                      3つ目の入力欄は最大4桁です。
                    </ErrorMessage>
                  )}

                  {(errors.delivery?.phoneNumber?.firstPart?.type === "pattern" ||
                    errors.delivery?.phoneNumber?.secondPart?.type === "pattern" ||
                    errors.delivery?.phoneNumber?.thirdPart?.type === "pattern") && (
                    <ErrorMessage>電話番号は数値を入力してください</ErrorMessage>
                  )}

                  {errors.delivery?.phoneNumber?.firstPart?.type === "api" && (
                    <ErrorMessage>
                      {errors.delivery?.phoneNumber?.firstPart?.message}
                    </ErrorMessage>
                  )}

                  {(errors.delivery?.phoneNumber?.firstPart?.type === "complete" ||
                    errors.delivery?.phoneNumber?.secondPart?.type === "complete" ||
                    errors.delivery?.phoneNumber?.thirdPart?.type === "complete") && (
                    <ErrorMessage>
                      電話番号を入力する場合は全てのフィールドを入力してください
                    </ErrorMessage>
                  )}

                  {(errors.delivery?.phoneNumber?.firstPart?.type === "format" ||
                    errors.delivery?.phoneNumber?.secondPart?.type === "format" ||
                    errors.delivery?.phoneNumber?.thirdPart?.type === "format") && (
                    <ErrorMessage>
                      電話番号を入力してください。
                      </ErrorMessage>
                    )}
                  </ErrorContainer>

                <Label>郵便番号（ハイフンなしの7桁）</Label>

                <ErrorContainer>
                  <InputContainer>
                    <ZipInput
                      {...register("delivery.zipCode", {
                        minLength: 7,
                        maxLength: 7,
                        pattern: regExps.zipCode,
                      })}
                      placeholder="000000"
                      pattern="\d*"
                      error={!!errors.delivery?.zipCode}
                    />

                    <AddressAutofillButton
                      type="button"
                      onClick={async () => {
                        const result = await trigger("delivery.zipCode");
                        const zipCode = getValues("delivery.zipCode");

                        if (!result || !zipCode) return;

                        dispatch(
                          findAddressByZipCode(
                            zipCode,
                            "delivery",
                            (value) =>
                              setValue("delivery.address.firstPart", value),
                            (error) =>
                              setError("delivery.zipCode", {
                                type: "api",
                                message: error,
                              })
                          )
                        );
                      }}
                    >
                      住所入力
                    </AddressAutofillButton>
                  </InputContainer>

                  {(errors.delivery?.zipCode?.type === "minLength" ||
                    errors.delivery?.zipCode?.type === "maxLength") && (
                    <ErrorMessage>7桁の郵便番号を入力して下さい。</ErrorMessage>
                  )}

                  {errors.delivery?.zipCode?.type === "api" && (
                    <ErrorMessage>{errors.delivery?.zipCode.message}</ErrorMessage>
                  )}

                  {errors.delivery?.zipCode?.type === "pattern" && (
                    <ErrorMessage>
                      郵便番号を入力して下さい。
                    </ErrorMessage>
                  )}
                </ErrorContainer>

                <Label>住所</Label>

                <ErrorContainer>
                  {zipSearchResults["delivery"].length ? (
                    <Dropdown
                      {...register("delivery.address.firstPart")}
                      error={!!errors.delivery?.address?.firstPart}
                    >
                      {zipSearchResults["delivery"].map((value, index) => (
                        <option key={"address-" + index} value={value}>
                          {value}
                        </option>
                      ))}
                    </Dropdown>
                  ) : (
                    <Input
                      {...register("delivery.address.firstPart")}
                      placeholder=" "
                      error={!!errors.delivery?.address?.firstPart}
                    />
                  )}

                  {errors.delivery?.address?.firstPart?.type === "api" && (
                    <ErrorMessage>
                      {errors.delivery?.address?.firstPart?.message}
                    </ErrorMessage>
                  )}
                </ErrorContainer>

                <Label>住所2</Label>

                <ErrorContainer>
                  <Input
                    {...register("delivery.address.secondPart")}
                    placeholder=" "
                    error={!!errors.delivery?.address?.secondPart}
                  />

                  {errors.delivery?.address?.secondPart?.type === "api" && (
                    <ErrorMessage>
                      {errors.delivery?.address?.secondPart.message}
                    </ErrorMessage>
                  )}
                </ErrorContainer>
              </HiddenDelivery>
            )}

            {applicationType !== "skipRegistration" && (
              <ShowDelivButton
                type="button"
                color="white"
                onClick={changeDeliveryStyle}
                delivStyle={isDeliveryHidden}
              >
                お届け先を入力する +
              </ShowDelivButton>
            )}

            {applicationType === "skipRegistration" && (
              <>
                <Label>届け先氏名</Label>

                <ErrorContainer>
                  <Input
                    {...register("delivery.fullName")}
                    placeholder=" "
                    error={!!errors.delivery?.fullName}
                  />

                  {errors.delivery?.fullName?.type === "api" && (
                    <ErrorMessage>
                      {errors.delivery?.fullName?.message}
                    </ErrorMessage>
                  )}
                </ErrorContainer>

                <Label>電話番号</Label>

                <ErrorContainer>
                  <InputContainer>
                    <PhoneInput
                      {...register("delivery.phoneNumber.firstPart", {
                        maxLength: 5,
                        pattern: /^\d*$/,
                        validate: phoneValidateRules("delivery.phoneNumber.firstPart", "delivery"),
                      })}
                      placeholder="例）080"
                      type="tel"
                      pattern="\d*"
                      maxLength={5}
                      error={!!errors.delivery?.phoneNumber?.firstPart}
                    />

                    <PhoneInput
                      {...register("delivery.phoneNumber.secondPart", {
                        maxLength: 4,
                        pattern: /^\d*$/,
                        validate: phoneValidateRules("delivery.phoneNumber.secondPart", "delivery"),
                      })}
                      placeholder="0000"
                      type="tel"
                      pattern="\d*"
                      maxLength={4}
                      error={!!errors.delivery?.phoneNumber?.secondPart}
                    />

                    <PhoneInput
                      {...register("delivery.phoneNumber.thirdPart", {
                        maxLength: 4,
                        pattern: /^\d*$/,
                        validate: phoneValidateRules("delivery.phoneNumber.thirdPart", "delivery"),
                      })}
                      placeholder="0000"
                      type="tel"
                      pattern="\d*"
                      maxLength={4}
                      error={!!errors.delivery?.phoneNumber?.thirdPart}
                    />
                  </InputContainer>

                  {errors.delivery?.phoneNumber?.firstPart?.type ===
                    "maxLength" && (
                    <ErrorMessage>
                      1つ目の入力欄は最大5桁です。
                    </ErrorMessage>
                  )}

                  {errors.delivery?.phoneNumber?.secondPart?.type ===
                    "maxLength" && (
                    <ErrorMessage>
                      2つ目の入力欄は最大4桁です。
                    </ErrorMessage>
                  )}

                  {errors.delivery?.phoneNumber?.thirdPart?.type ===
                    "maxLength" && (
                    <ErrorMessage>
                      3つ目の入力欄は最大4桁です。
                    </ErrorMessage>
                  )}

                  {(errors.delivery?.phoneNumber?.firstPart?.type === "pattern" ||
                    errors.delivery?.phoneNumber?.secondPart?.type === "pattern" ||
                    errors.delivery?.phoneNumber?.thirdPart?.type === "pattern") && (
                    <ErrorMessage>電話番号は数値を入力してください</ErrorMessage>
                  )}

                  {errors.delivery?.phoneNumber?.firstPart?.type === "api" && (
                    <ErrorMessage>
                      {errors.delivery?.phoneNumber?.firstPart?.message}
                    </ErrorMessage>
                  )}

                  {(errors.delivery?.phoneNumber?.firstPart?.type === "complete" ||
                    errors.delivery?.phoneNumber?.secondPart?.type === "complete" ||
                    errors.delivery?.phoneNumber?.thirdPart?.type === "complete") && (
                    <ErrorMessage>
                      電話番号を入力する場合は全てのフィールドを入力してください
                    </ErrorMessage>
                  )}

                  {(errors.delivery?.phoneNumber?.firstPart?.type === "format" ||
                    errors.delivery?.phoneNumber?.secondPart?.type === "format" ||
                    errors.delivery?.phoneNumber?.thirdPart?.type === "format") && (
                    <ErrorMessage>
                      電話番号を入力してください。
                      </ErrorMessage>
                    )}
                  </ErrorContainer>

                <Label>郵便番号（ハイフンなしの7桁）</Label>

                <ErrorContainer>
                  <InputContainer>
                    <ZipInput
                      {...register("delivery.zipCode", {
                        minLength: 7,
                        maxLength: 7,
                        pattern: regExps.zipCode,
                      })}
                      placeholder="000000"
                      pattern="\d*"
                      error={!!errors.delivery?.zipCode}
                    />

                    <AddressAutofillButton
                      type="button"
                      onClick={async () => {
                        const result = await trigger("delivery.zipCode");
                        const zipCode = getValues("delivery.zipCode");

                        if (!result || !zipCode) return;

                        dispatch(
                          findAddressByZipCode(
                            zipCode,
                            "delivery",
                            (value) =>
                              setValue("delivery.address.firstPart", value),
                            (error) =>
                              setError("delivery.zipCode", {
                                type: "api",
                                message: error,
                              })
                          )
                        );
                      }}
                    >
                      住所入力
                    </AddressAutofillButton>
                  </InputContainer>

                  {(errors.delivery?.zipCode?.type === "minLength" ||
                    errors.delivery?.zipCode?.type === "maxLength") && (
                    <ErrorMessage>7桁の郵便番号を入力して下さい。</ErrorMessage>
                  )}

                  {errors.delivery?.zipCode?.type === "api" && (
                    <ErrorMessage>{errors.delivery?.zipCode.message}</ErrorMessage>
                  )}

                  {errors.delivery?.zipCode?.type === "pattern" && (
                    <ErrorMessage>
                      郵便番号を入力して下さい。
                    </ErrorMessage>
                  )}
                </ErrorContainer>

                <Label>住所</Label>

                <ErrorContainer>
                  {zipSearchResults["delivery"].length ? (
                    <Dropdown
                      {...register("delivery.address.firstPart")}
                      error={!!errors.delivery?.address?.firstPart}
                    >
                      {zipSearchResults["delivery"].map((value, index) => (
                        <option key={"address-" + index} value={value}>
                          {value}
                        </option>
                      ))}
                    </Dropdown>
                  ) : (
                    <Input
                      {...register("delivery.address.firstPart")}
                      placeholder=" "
                      error={!!errors.delivery?.address?.firstPart}
                    />
                  )}

                  {errors.delivery?.address?.firstPart?.type === "api" && (
                    <ErrorMessage>
                      {errors.delivery?.address?.firstPart?.message}
                    </ErrorMessage>
                  )}
                </ErrorContainer>

                <Label>住所2</Label>

                <ErrorContainer>
                  <Input
                    {...register("delivery.address.secondPart")}
                    placeholder=" "
                    error={!!errors.delivery?.address?.secondPart}
                  />

                  {errors.delivery?.address?.secondPart?.type === "api" && (
                    <ErrorMessage>
                      {errors.delivery?.address?.secondPart.message}
                    </ErrorMessage>
                  )}
                </ErrorContainer>
              </>
            )}
          </CardWithMargin>
        )}

        {applicationType !== "skipRegistration" && fieldSettings.pet.show && (
          <CardWithMargin>
            <SectionTitle>
              ペット情報
              {fieldSettings.pet.required && (
                <RequiredTextWithTitle>[必須]</RequiredTextWithTitle>
              )}
            </SectionTitle>

            <SectionDescription>
              飼っているまたは飼う予定のペットをご入力ください。
              ペットにぴったりの情報をお送りします。
            </SectionDescription>

            <DisplayAllButton
              id={`${formId}-02`}
              type="checkbox"
              isShow={initHasPets}
            />
            <DisplayAllLabel
              htmlFor={`${formId}-02`}
              isShow={initHasPets}
            />
            <HideContainer
              className="cp_container"
              initialHeight={initHasPets}
            >

              {fields.map((field, index) => (
                <PetSection key={field.id}>

                  <Label>飼育状況</Label>

                  {pets[index].orghave !== "1" && (
                    <>
                      <Controller
                        control={control}
                        name={`pets.${index}.have`}
                        render={({ field: { onChange, value } }) => (
                          <HaveInputContainer>
                            <StyledRadio
                              onClick={() => onChange("1")}
                              isSelected={value === "1"}
                              label="飼っている"
                            />

                            <StyledRadio
                              onClick={() => onChange("0")}
                              isSelected={value === "0"}
                              label="飼う予定"
                            />
                          </HaveInputContainer>
                        )}
                      />
                    </>
                  )}

                  {pets[index].orghave === "1" && (
                    <>
                      <Controller
                        control={control}
                        name={`pets.${index}.have`}
                        render={({ field: { onChange, value } }) => (
                          <HaveInputContainer>
                            <StyledRadio
                              onClick={() => onChange("1")}
                              isSelected={true}
                              label="飼っている"
                            />

                            <StyledRadio
                              onClick={() => onChange("1")}
                              isSelected={false}
                              label="飼う予定"
                            />
                          </HaveInputContainer>
                        )}
                      />
                    </>
                  )}
                  

                  <SectionDescriptionTwo>
                    ※同居していないペットの場合も「飼っている」をお選びください
                  </SectionDescriptionTwo>

                  <Label>ペット名（{index+1}）</Label>

                  <ErrorContainer>
                    <Input
                      placeholder="なまえ"
                      {...register(`pets.${index}.name`, {
                        validate: (value) => value !== "",
                        maxLength: 21,
                      })}
                      error={!!errors.pets?.[index]?.name}
                    />
                    {errors.pets?.[index]?.name?.type === "validate" && (
                      <ErrorMessage>ペット名は必ず入力して下さい。</ErrorMessage>
                    )}
                    {errors.pets?.[index]?.name?.type === "maxLength" && (
                      <ErrorMessage>ペット名は21文字以内で入力してください。</ErrorMessage>
                    )}
                  </ErrorContainer>
                  
                  {pets[index].have === "0" && (
                    <>
                      <SectionDescriptionTwo>
                        ※飼う予定のペットの品種や特徴を教えてください
                      </SectionDescriptionTwo>
                    </>
                  )}

                  {pets[index].have === "1" && (
                    <>
                  <Label>生年月日</Label>

                  <ErrorContainer>
                    <DateInputContainer>
                      <YearInput
                        {...register(`pets.${index}.dateOfBirth.year`, {
                          required: true,
                          minLength: 4,
                          maxLength: 4,
                          min: 1900,
                        })}
                        error={!!errors.pets?.[index]?.dateOfBirth?.year}
                        type="number"
                        placeholder="年"
                      />

                      <MonthDayInput
                        {...register(`pets.${index}.dateOfBirth.month`, {
                          required: true,
                          minLength: 1,
                          maxLength: 2,
                        })}
                        error={!!errors.pets?.[index]?.dateOfBirth?.month}
                        type="number"
                        placeholder="月"
                      />

                      <MonthDayInput
                        {...register(`pets.${index}.dateOfBirth.day`, {
                          required: true,
                          minLength: 1,
                          maxLength: 2,
                        })}
                        error={!!errors.pets?.[index]?.dateOfBirth?.day}
                        type="number"
                        placeholder="日"
                      />
                    </DateInputContainer>

                    {errors.pets?.[index]?.dateOfBirth?.year?.type === "min"  && (
                      <ErrorMessage>生年月日を正しく入力して下さい。</ErrorMessage>
                    )}

                    {(errors.pets?.[index]?.dateOfBirth?.year?.type ===
                      "required" ||
                      errors.pets?.[index]?.dateOfBirth?.month?.type ===
                        "required" ||
                      errors.pets?.[index]?.dateOfBirth?.day?.type ===
                        "required") && (
                      <ErrorMessage>生年月日は必ず入力して下さい。</ErrorMessage>
                    )}

                    {(errors.pets?.[index]?.dateOfBirth?.year?.type ===
                      "invalidDate" ||
                      errors.pets?.[index]?.dateOfBirth?.month?.type ===
                        "invalidDate" ||
                      errors.pets?.[index]?.dateOfBirth?.day?.type ===
                        "invalidDate") && (
                      <ErrorMessage>生年月日を正しく入力して下さい。</ErrorMessage>
                    )}

                    {(errors.pets?.[index]?.dateOfBirth?.year?.type ===
                      "maxLength" ||
                      errors.pets?.[index]?.dateOfBirth?.year?.type ===
                        "minLength") && (
                      <ErrorMessage>年は4桁で入力して下さい。</ErrorMessage>
                    )}

                    {(errors.pets?.[index]?.dateOfBirth?.month?.type ===
                      "maxLength" ||
                      errors.pets?.[index]?.dateOfBirth?.month?.type ===
                        "minLength") && (
                      <ErrorMessage>月は2桁までで入力して下さい。</ErrorMessage>
                    )}

                    {(errors.pets?.[index]?.dateOfBirth?.day?.type ===
                      "maxLength" ||
                      errors.pets?.[index]?.dateOfBirth?.day?.type ===
                        "minLength") && (
                      <ErrorMessage>日は2桁までで入力して下さい。</ErrorMessage>
                    )}
                  </ErrorContainer>

                  <Label>性別</Label>

                  <Controller
                    control={control}
                    name={`pets.${index}.gender`}
                    render={({ field: { onChange, value } }) => (
                      <GenderInputContainer>
                        <StyledRadio
                          onClick={() => onChange(Gender.Male)}
                          isSelected={value === Gender.Male}
                          label="男の子"
                        />

                        <StyledRadio
                          onClick={() => onChange(Gender.Female)}
                          isSelected={value === Gender.Female}
                          label="女の子"
                        />

                        <StyledRadio
                          onClick={() => onChange(Gender.Other)}
                          isSelected={value === Gender.Other}
                          label="不明"
                        />
                      </GenderInputContainer>
                    )}
                  />

                  <Label>ペットの種類</Label>

                  <Controller
                    control={control}
                    name={`pets.${index}.type`}
                    render={({ field: { onChange, value } }) => (
                      <PetTypeInputContainer>
                        <StyledRadio
                          onClick={() => {
                            onChange(PetType.Dog);
                            handleTypeChange(PetType.Dog, index);
                          }}
                          isSelected={value === PetType.Dog}
                          label="犬"
                        />

                        <StyledRadio
                          onClick={() => {
                            onChange(PetType.Cat);
                            handleTypeChange(PetType.Cat, index);
                          }}
                          isSelected={value === PetType.Cat}
                          label="猫"
                        />

                        <StyledRadio
                          onClick={() => {
                            onChange(PetType.Other);
                            handleTypeChange(PetType.Other, index);
                          }}
                          isSelected={value === PetType.Other}
                          label="その他"
                        />
                      </PetTypeInputContainer>
                    )}
                  />

                  <Label>品種</Label>

                  <ErrorContainer>
                    <Controller
                      {...register(`pets.${index}.breed`, {
                        validate: (value) => value !== "" && value !== undefined,
                      })}
                      control={control}
                      name={`pets.${index}.breed`}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <CreatableSelect
                          value={{ value, label: value }}
                          placeholder=""
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable
                          isSearchable
                          onChange={(value) => onChange(value?.value)}
                          options={petData[pets[index].type as PetType]}
                          formatCreateLabel={(value) => value}
                          noOptionsMessage={() => "選択肢はありません"}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              backgroundColor: "#FFFFFF",
                              border: `1px ${
                                errors.pets?.[index]?.breed
                                  ? "#d01414"
                                  : "#afafaf"
                              } solid`,
                              outline: "none",
                            }),
                            option: (styles, { isSelected }) => ({
                              ...styles,
                              backgroundColor: isSelected ? "#EF8205" : "#FFF",
                              ":hover": {
                                backgroundColor: "#FFF8DD",
                                color: "#000",
                              },
                            }),
                          }}
                        />
                      )}
                    />
                    {errors.pets?.[index]?.breed?.type === "required" && (
                      <ErrorMessage>品種は必ず指定して下さい。</ErrorMessage>
                    )}
                  </ErrorContainer>
                    </>
                  )}
                  {pets[index].have === "0" && (
                    <>
                      <Label>飼う予定のペットの種類</Label>

                      <Controller
                        control={control}
                        name={`pets.${index}.type2`}
                        render={({ field: { onChange, value } }) => (
                          <PetTypeInputContainer>
                            <StyledRadio
                              onClick={() => {
                                onChange(PetType.Dog);
                                handleType2Change(PetType.Dog, index);
                              }}
                              isSelected={value === PetType.Dog}
                              label="犬"
                            />

                            <StyledRadio
                              onClick={() => {
                                onChange(PetType.Cat);
                                handleType2Change(PetType.Cat, index);
                              }}
                              isSelected={value === PetType.Cat}
                              label="猫"
                            />

                            <StyledRadio
                              onClick={() => {
                                onChange(PetType.Other);
                                handleType2Change(PetType.Other, index);
                              }}
                              isSelected={value === PetType.Other}
                              label="その他"
                            />
                          </PetTypeInputContainer>
                        )}
                      />
                    </>
                  )}

                  <DeletePetButton
                    type="button"
                    onClick={() => setPetToDelete(index)}
                  >
                    登録解除する
                  </DeletePetButton>
                </PetSection>
              ))}
            </HideContainer>

          {pets.length < registerablePetCount && (
              <AddPetButton
                type="button"
                color="white"
                onClick={() => {
                  append(defaultPetValues);
                  dispatch(setNoPetError(undefined));
                }}
              >
                ペットを追加する +
              </AddPetButton>
            )}

            {noPetError && (
              <ErrorPetInfoMessage>ペット情報は必ず入力して下さい</ErrorPetInfoMessage>
            )}
          </CardWithMargin>
        )}

        {applicationType === "login" && (
          <>
          <SubmitButton icon="arrow">確認して次へ</SubmitButton>
          </>
        )}
        {applicationType === "registration" && (
          <>
          <SubmitButton icon="arrow">会員登録する</SubmitButton>
          </>
        )}
        {applicationType === "skipRegistration" && (
          <>
          <SubmitButton icon="arrow">登録する</SubmitButton>
          </>
        )}
      </StyledForm>
    </>
  );
};

const StyledForm = styled.form`
  width: 100%;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
`;

const CardWithMargin = styled(Card)`
  position: relative;
  margin-bottom: 40px;
`;

const SectionTitle = styled.h3`
  text-align: center;
  font-size: 20px;
  margin: 0 0 10px 0;
  font-weight: 600;
`;

const SectionDescription = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 20px;
`;

const SectionDescriptionTwo = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 20px;
`;

const ErrorPetInfoMessage = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 20px;
  color: #d01414;
  text-align: center;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
`;

export const PasswordToggleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  color: #d01414;
  font-size: 14px;
`;

const ErrorNameMessage = styled.div`
  color: #d01414;
  font-size: 14px;
  width: 200%
`;

const ErrorRequiredMessage = styled.div`
  width: 100%;
  color: #d01414;
  font-size: 18px;
  text-align: center;
`;

const NameErrorContainer = styled(ErrorContainer)`
  width: 48%;
`;

export const StyledRadio = styled(Radio)`
  margin-right: 20px;
`;

const GenderInputContainer = styled.div`
  display: flex;
  margin: 5px 0 15px 0;
`;

const HaveInputContainer = styled.div`
  display: flex;
  margin: 5px 0 15px 0;
`;

const Dropdown = styled.select<{ error?: boolean }>`
  height: 38px;
  margin: 10px 0;
  padding: 7px;
  font-size: 14px;
  color: ${({ value }) => (value === "0" ? "#afafaf" : "#000")};
  background-color: #ffffff;
  border: 1px ${({ error }) => (error ? "#d01414" : "#afafaf")} solid;
  border-radius: 4px;
`;

const DateInputContainer = styled.div`
  display: flex;
`;

const YearInput = styled(Input)`
  width: 20%;
`;

const MonthDayInput = styled(Input)`
  width: 15%;
  margin-left: 10px;
`;

const PhoneInput = styled(Input)`
  width: 32%;
`;

const ZipInput = styled(Input)`
  width: 55%;
`;

const AddressAutofillButton = styled(Button)`
  background-color: #e3e3e3;
  border-color: #4b4b4b;
  color: #4b4b4b;
  font-size: 14px;
  width: 40%;
`;

const PasswordToggleButton = styled(Button)`
  background-color: #e3e3e3;
  border-color: #4b4b4b;
  color: #4b4b4b;
  font-size: 14px;
  width: 40%;
`;

export const OptionDescription = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  padding: 0 5px;
  margin-bottom: 20px;
`;

const PasswordDescription = styled.div`
  font-size: 11px;
  line-height: 18px;
  margin-bottom: 30px;
  font-weight: 300;
`;

const PetTypeInputContainer = styled.div`
  display: flex;
  margin: 5px 0 25px 0;
`;

const AddPetButton = styled(Button)`
  height: 43px;
  font-size: 14px;
  width: 70%;
  margin-bottom: 10px;
`;

const DeletePetButton = styled(Button)`
  border: none;
  outline: none;
  align-self: center;
  font-size: 12px;
  height: 25px;
  width: 100px;
  color: #d01414;
  margin-bottom: 20px;
  background-color: #fff;
`;

const SubmitButton = styled(Button)`
  margin: 40px 0 20px 10%;
  width: 80%;
`;

const PetSection = styled.div`
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  &:not(:last-of-type) {
    border-bottom: 2px dashed #707070;
    margin-bottom: 15px;
  }
`;

const RequiredTextWithTitle = styled.span`
  color: #d01414;
  margin-left: 12px;
  position: relative;
`;

const PasswordReveal = styled.div`
  position: absolute;
  height: 30px;
  right:5px;
  top:9px;
  cursor: pointer;
`;

const SmallEyes = styled.img`
  height: 30px;
  width: 30px;
`;

const HiddenDelivery = styled.div<{ delivStyle?: boolean}>`
  display: ${({ delivStyle }) => (delivStyle ? "inline" : "none")};
`;

const ShowDelivButton = styled(Button)<{ delivStyle?: boolean}>`
  height: 43px;
  font-size: 14px;
  width: 70%;
  margin-bottom: 10px;
  display: ${({ delivStyle }) => (delivStyle ? "none" : "inline")};

`;

const SubmitTopDescription = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 20px;
  width: 100%
`;

const DisplayAllLabel = styled.label<DisplayAllLabelProps>`
  display: ${(props) => (props.isShow ? "" : "none")};
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 140px; /* グラデーションの高さ */
  cursor: pointer;
  text-align: center;
  background: linear-gradient(to bottom, rgba(250, 252, 252, 0) 0%, rgba(250, 252, 252, 0.95) 90%);
  &::after {
      line-height: 2.5rem;
      position: absolute;
      z-index: 2;
      bottom: 20px;
      left: 50%;
      width: 16rem;
      content: '全て表示する';
      transform: translate(-50%, 0);
      letter-spacing: 0.05em;
      color: #ffffff;
      border-radius: 20px;
      background-color: rgba(27, 37, 56, 1);
  }
`;

const DisplayAllButton = styled.input<{ isShow?: boolean}>`
  display: none;
  &:checked + ${DisplayAllLabel} {
    display: none;
  }
  &:checked ~ .cp_container {
    display: ${({isShow}) => (isShow ? "" : "none")};
    height: auto;
    transition: all 0.5s;
    overflow: visible;
  }
`;

const HideContainer = styled.div<HideContainerProps>`
  overflow: ${(props) => props.initialHeight ? "hidden" : "visible"};
  height: ${(props) => props.initialHeight ? "300px" : "auto"};
  transition: all 0.5s;
`;
