import { Route, Switch, useHistory } from "react-router-dom";
import axios from "axios";
import ReactModal from "react-modal";
import { useLocation } from "react-router";

import { StandardTemplate } from "../templates/Standard";
import { Login } from "../pages/Login";
import { UserAgreement } from "../pages/UserAgreement";
import { EmailRegistration } from "../pages/EmailRegistration";
import { OTPConfirmation } from "../pages/OTPConfirmation";
import { Registration } from "../pages/Registration";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword";
import { RegistrationConfirmation } from "../pages/RegistrationConfirmation";
import { RegistrationCompletion } from "../pages/RegistrationCompletion";
import { Campaign } from "../pages/Campaign";
import { Thanks } from "../pages/Thanks";
import { Unsubscribe } from "../pages/Unsubscribe";
import { UnsubscribeComplete } from "../pages/UnsubscribeComplete";
import { UpdateNewsletters } from "../pages/UpdateNewsletters";
import { UpdateNewslettersComplete } from "../pages/UpdateNewslettersComplete";
import { ApplicationComplete } from "../pages/ApplicationComplete";
import { PageNotFound } from "../pages/PageNotFound";
import { FailureScreen } from "../pages/FailureScreen";
import { MaintenancePage } from "../pages/MaintenancePage";
import { JudgeMagazine } from "../pages/JudgeMagazine";

axios.defaults.withCredentials = true;
ReactModal.setAppElement("#root");

const App: React.FC = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const noCampaignPath = query.get('noCampaignPath');
  const campaignId = query.get("campaignId") as string;

  const getTitle = () => {
    switch (noCampaignPath) {
      case "unsubscribe":
        return "応募会員登録解除";
      case "updateNewsletters":
        return "応募会員登録変更";
      default:
        return "応募会員登録画面";
    }
  };

  if (noCampaignPath !== "maintenancePage") {
    axios.get(process.env.REACT_APP_BACKEND_API_URL + "/api/v1/isFrontMaintain")
    .then(response => {
      if (response.data.maintain_state === 1) {
        if (campaignId === null) {
          const url = window.location.pathname;
          const campaign = url.substring(url.lastIndexOf('/', url.length) + 1) as string;
          if (campaign !== 'maintenancePage') {
            history.push({
              pathname: "/maintenancePage",
              search: `?noCampaignPath=maintenancePage&campaignId=${campaign}`,
              state: {referrer: "/currentLocation"},
            })
          }
        } else {
          history.push({
            pathname: "/maintenancePage",
            search: `?noCampaignPath=maintenancePage&campaignId=${campaignId}`,
            state: {referrer: "/currentLocation"},
          })
        }
      }
    })
  }

  return (
    <Switch>
      <Route exact path="/">
        <StandardTemplate logo="large" title={getTitle()}>
          <Login />
        </StandardTemplate>
      </Route>

      <Route path="/campaign/:campaignId">
        <StandardTemplate sidePadding={0} waveColor="orange" iconImage="bodyLoading">
          <Campaign />
        </StandardTemplate>
      </Route>

      <Route path="/thanks/:campaignId">
        <StandardTemplate sidePadding={0} waveColor="orange">
          <Thanks />
        </StandardTemplate>
      </Route>

      <Route path="/userAgreement">
        <StandardTemplate showStepper>
          <UserAgreement />
        </StandardTemplate>
      </Route>

      <Route path="/emailRegistration">
        <StandardTemplate showStepper>
          <EmailRegistration />
        </StandardTemplate>
      </Route>

      <Route path="/otpConfirmation">
        <StandardTemplate showStepper>
          <OTPConfirmation />
        </StandardTemplate>
      </Route>

      <Route path="/registration">
        <StandardTemplate showStepper>
          <Registration />
        </StandardTemplate>
      </Route>

      <Route path="/forgotPassword">
        <StandardTemplate>
          <ForgotPassword />
        </StandardTemplate>
      </Route>

      <Route path="/resetPassword">
        <StandardTemplate>
          <ResetPassword />
        </StandardTemplate>
      </Route>

      <Route path="/registrationConfirmation">
        <StandardTemplate showStepper>
          <RegistrationConfirmation />
        </StandardTemplate>
      </Route>

      <Route path="/registrationCompletion">
        <StandardTemplate>
          <RegistrationCompletion />
        </StandardTemplate>
      </Route>

      <Route path="/unsubscribe">
        <StandardTemplate>
          <Unsubscribe />
        </StandardTemplate>
      </Route>

      <Route path="/unsubscribeComplete">
        <StandardTemplate>
          <UnsubscribeComplete />
        </StandardTemplate>
      </Route>

      <Route path="/updateNewsletters">
        <StandardTemplate>
          <UpdateNewsletters />
        </StandardTemplate>
      </Route>

      <Route path="/updateNewslettersComplete">
        <StandardTemplate>
          <UpdateNewslettersComplete />
        </StandardTemplate>
      </Route>

      <Route path="/applicationComplete">
        <StandardTemplate>
          <ApplicationComplete />
        </StandardTemplate>
      </Route>

      <Route path="/FailureScreen">
        <StandardTemplate>
          <FailureScreen />
        </StandardTemplate>
      </Route>

      <Route path="/maintenancePage">
        <StandardTemplate>
          <MaintenancePage />
        </StandardTemplate>
      </Route>

      <Route path="/judgeMagazine">
        <StandardTemplate>
          <JudgeMagazine />
        </StandardTemplate>
      </Route>

      {/*  最後に書くことで引っかからない場合に出す */}
      <Route>
        <StandardTemplate>
          <PageNotFound />
        </StandardTemplate>
      </Route>
    </Switch>
  );
};

export default App;
