import styled from "styled-components";

import BenesseLogoFooterIcon from "../assets/images/benesse_logo_footer@3x.png";

export const Footer = () => (
  <StyledFooter>
    <a href="https://www.benesse.co.jp/" rel="noreferrer" target="_blank">
      <BenesseFooterIconImage src={BenesseLogoFooterIcon} />
    </a>

    <p>
      <a href="https://www.benesse.co.jp/privacy/" rel="noreferrer" target="_blank">個人情報保護への取り込みについて</a> |{" "}
      <a href="https://benesse.ne.jp/userdata/pet.html" rel="noreferrer" target="_blank">利用者情報の取り扱い</a> |{" "}
      <a href="https://www.benesse.co.jp/benesseinfo/" rel="noreferrer" target="_blank">会社案内</a>
    </p>

    <p>Copyright ©︎Benesse Corporation. All rights reserved.</p>
  </StyledFooter>
);

const StyledFooter = styled.footer`
  font-size: 12px;
  text-align: center;
  background-color: #ffffff;
  min-height: 123px;
`;

const BenesseFooterIconImage = styled.img`
  margin-top: 20px;
  width: 105px;
  height: 25px;
`;
