import styled from "styled-components";

export const ApplicationComplete = () => (
  <Title>ご応募ありがとうございました</Title>
);

const Title = styled.h2`
  margin-top: 80px;
  font-size: 26px;
`;
