import styled from "styled-components";

export const UnsubscribeComplete = () => (
  <>
    <Title>登録を解除しました</Title>

    <Description>
      これまでご利用いただき
      <br />
      ありがとうございました。
      <br />
      またのご利用をお待ちしております。
    </Description>
  </>
);

const Title = styled.h2`
  font-size: 26px;
`;

const Description = styled.p`
  margin-bottom: 37px;
  font-size: 14px;
  line-height: 21px;
  width: 80%;
  text-align: center;
`;
