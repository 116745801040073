import { useHistory } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import eyeOpen from "../assets/images/eyeOpen.svg";
import eyeClose from "../assets/images/eye-close.svg";

import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Label } from "../components/Label";
import { Input } from "../components/Input";
import { regExps } from "../utils/regExps";
import { handleError, ApiError } from "../utils/handleError";
import { instantiateUserApi } from "../utils/apiWrappers";

interface ResetPasswordInputs {
  password: string;
  passwordRepeat: string;
}

export const ResetPassword = () => {

  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [isRevealConfirmPassword, setIsRevealConfirmPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  }
  const toggleConfirmPassword = () => {
    setIsRevealConfirmPassword((prevState) => !prevState);
  }

  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordInputs>();

  const password = watch("password");

  const query = new URLSearchParams(useLocation().search);

  const onSubmit: SubmitHandler<ResetPasswordInputs> = async (data) => {
    const userApi = instantiateUserApi();
    const queryString = query.toString();

    try {
      await userApi.postApiV1UserPasswordReset({
        password: data.password,
      });

      history.push({
        pathname: "/",
        search: `?${queryString}&resetState=completeResetPassword`,
      });
    } catch (e) {
      handleError(e as ApiError);
    }
  };

  return (
    <>
      <Title>パスワード再設定</Title>

      <Description>
        パスワードを設定してください。
        パスワードに使用できる文字は、半角英数字です。
        8文字以上32文字以下で英字・数字をすべて含めてください。
      </Description>

      <ResetPasswordCard>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Label>パスワード</Label>

          <PasswordErrorContainer>
            <Input
              type={isRevealPassword ? 'text' : 'password'}
              placeholder="8文字以上の半角英数"
              error={!!errors.password}
              {...register("password", {
                required: true,
                minLength: 8,
                maxLength: 32,
                pattern: regExps.password,
              })}
              icon={true}
            />
            <PasswordReveal>
              <span 
                onClick={togglePassword}
                role="presentation"
              >
                {isRevealPassword ? (
                  <SmallEyes src={eyeOpen} />
                ) : (
                  <SmallEyes src={eyeClose} />
                )}
              </span>
            </PasswordReveal>

            {errors.password?.type === "minLength" && (
              <ErrorMessage>パスワードを8文字以上にしてください</ErrorMessage>
            )}

            {errors.password?.type === "maxLength" && (
              <ErrorMessage>パスワードを32文字以内にしてください</ErrorMessage>
            )}

            {errors.password?.type === "pattern" && (
              <ErrorMessage>
                パスワードに使用できる文字は、半角英数字です。8文字以上32文字以下で英字と数字を含めてください
              </ErrorMessage>
            )}
          </PasswordErrorContainer>

          <Label>パスワード（確認）</Label>

          <ErrorContainer>
            <Input
              type={isRevealConfirmPassword ? 'text' : 'password'}
              placeholder="上のパスワードと同じものを入力"
              error={!!errors.passwordRepeat}
              {...register("passwordRepeat", {
                required: true,
                minLength: 8,
                maxLength: 32,
                pattern: regExps.password,
                validate: (value) => password === value,
              })}
              icon={true}
            />
            <PasswordReveal>
              <span 
                onClick={toggleConfirmPassword}
                role="presentation"
              >
                {isRevealConfirmPassword ? (
                  <SmallEyes src={eyeOpen} />
                ) : (
                  <SmallEyes src={eyeClose} />
                )}
              </span>
            </PasswordReveal>

            {errors.passwordRepeat?.type === "minLength" && (
              <ErrorMessage>パスワードを8文字以上にしてください</ErrorMessage>
            )}

            {errors.passwordRepeat?.type === "maxLength" && (
              <ErrorMessage>パスワードを32文字以内にしてください</ErrorMessage>
            )}

            {errors.passwordRepeat?.type === "pattern" && (
              <ErrorMessage>
                パスワードに使用できる文字は、半角英数字です。8文字以上32文字以下で英字と数字を含めてください
              </ErrorMessage>
            )}

            {errors.passwordRepeat?.type === "validate" && (
              <ErrorMessage>
                パスワードが一致しません。
              </ErrorMessage>
            )}
          </ErrorContainer>

          <NextButton icon="arrow">パスワード再設定</NextButton>
        </Form>
      </ResetPasswordCard>
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
`;

const Description = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 30px;
  line-height: 18px;
`;

const ResetPasswordCard = styled(Card)`
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-bottom: 15px; */
`;

const PasswordErrorContainer = styled(ErrorContainer)`
  margin-bottom: 30px;
`;

const ErrorMessage = styled.div`
  color: #d01414;
  font-size: 14px;
`;

const NextButton = styled(Button)`
  margin-top: 35px;
`;

const PasswordReveal = styled.div`
  position: absolute;
  height: 30px;
  right:5px;
  top:9px;
  cursor: pointer;
`;

const SmallEyes = styled.img`
  height: 30px;
  width: 30px;
`;
