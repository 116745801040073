import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  zIndexes: {
    header: 2,
    logo: 1,
  },
};

export default theme;
