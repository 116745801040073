import styled from "styled-components";
import { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Button } from "../components/Button";
import {
  fetchCampaignData,
  setError,
} from "../features/campaign/campaignSlice";
import { PageNotFound } from "./PageNotFound";

export const RegistrationCompletion = () => {
  const { questionnaireUrl, campaignAnswerId } = useAppSelector(
    (state) => state.campaign
  );

  const dispatch = useAppDispatch();
  const { campaignData, error, loading, currentCampaignId } = useAppSelector(
    (state) => state.campaign
  );
  const queryParam = useLocation().search;
  useEffect(() => {
    if (campaignData == null) {
      const query = new URLSearchParams(queryParam);
      const campaignId = query.get("campaignId");
      if (!campaignId) {
        dispatch(setError(true));
        return;
      }

      dispatch(fetchCampaignData(campaignId, document));
    }
  }, [dispatch, queryParam, campaignData]);

  if (loading) {
    return <Fragment />;
  }

  if (error || !campaignData || !currentCampaignId) {
    return <PageNotFound />;
  }

  const onClickNext = () => {
    if (!questionnaireUrl) {
      return;
    }

    // ID連携しないアンケートへ遷移
    if (questionnaireUrl.slice(-3) !== "id=") {
      return window.location.replace(questionnaireUrl);
    }

    // ID連携するアンケートへ遷移
    const url = campaignAnswerId
      ? `${questionnaireUrl}${campaignAnswerId}`
      : questionnaireUrl;

    window.location.replace(url);
  };

  return (
    <>
      <Title>登録が完了しました</Title>

      <Description>キャンペーンページよりご応募ください。</Description>

      <NextButton onClick={onClickNext} type="button" icon="arrow">
        キャンペーンやアンケートに参加
      </NextButton>
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
`;

const Description = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 30px;
  line-height: 18px;
`;

const NextButton = styled(Button)`
  font-size: 16px;
  margin-top: 35px;
  width: 100%;
`;
