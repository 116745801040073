import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { History } from "history";
import { UseFormSetError } from "react-hook-form";
import { toast } from "react-toastify";

import { AppThunk } from "../../app/store";
import { ForgotPasswordInputs } from "../../pages/ForgotPassword";
import { LoginInput } from "../../pages/Login";
import { OTPConfirmationInput } from "../../pages/OTPConfirmation";
import {
  InlineResponse2001 as RequiredFields,
  InlineResponse200User as UserData,
  InlineResponse2009User as MagazineJudgeUserData,
  Pet,
  ThanksData,
} from "../../types/typescript-axios";
import {
  instantiateLoginApi,
  instantiateUserApi,
} from "../../utils/apiWrappers";
import { ApiError, handleError } from "../../utils/handleError";
import { resetCampaignState } from "../campaign/campaignSlice";
import { resetFormState } from "../form/formSlice";

type ApplicationType = "registration" | "login" | "skipRegistration";

type OtpType = "login" | "passwordReset" | "registration";

interface UserState {
  loggedIn: boolean;
  email?: string;
  maintainTime?: string;
  otpType?: OtpType;
  needsUserAgreement: boolean;
  applicationType?: ApplicationType;
  userData?: UserData;
  MagazineJudgeUserData?: MagazineJudgeUserData;
  userPetData?: Pet[];
  ThanksData?: ThanksData;
  firstDeviceLogin?: boolean;
  loading?: boolean;
  checkType?: number;
}

const initialState: UserState = {
  loggedIn: false,
  needsUserAgreement: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginStatus: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setMaintainTime: (state, action: PayloadAction<string>) => {
      state.maintainTime = action.payload;
    },
    setNeedsUserAgreement: (state, action: PayloadAction<boolean>) => {
      state.needsUserAgreement = action.payload;
    },
    setOtpType: (state, action: PayloadAction<OtpType | undefined>) => {
      state.otpType = action.payload;
    },
    setApplicationType: (state, action: PayloadAction<ApplicationType>) => {
      state.applicationType = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    setMagazineJudgeUserData: (state, action: PayloadAction<MagazineJudgeUserData>) => {
      state.MagazineJudgeUserData = action.payload;
    },
    setPetData: (state, action: PayloadAction<Pet[]>) => {
      state.userPetData = action.payload;
    },
    setThanksData: (state, action: PayloadAction<ThanksData>) => {
      state.ThanksData = action.payload;
    },
    setFirstDeviceLogin: (state, action: PayloadAction<boolean>) => {
      state.firstDeviceLogin = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCheckType: (state, action: PayloadAction<RequiredFields>) => {
      action.payload.user_registration_requirements === 3 ? state.checkType = action.payload.check_magazine : state.checkType = 0;
    },
    reconstructor: (state, action: PayloadAction<UserState>) => {
      if (!action.payload) {
        return;
      }

      state.loggedIn = action.payload.loggedIn;
      state.email = action.payload.email;
      state.otpType = action.payload.otpType;
      state.needsUserAgreement = action.payload.needsUserAgreement;
      state.applicationType = action.payload.applicationType;
      state.userData = action.payload.userData;
      state.userPetData = action.payload.userPetData;
      state.ThanksData = action.payload.ThanksData;
      state.firstDeviceLogin = action.payload.firstDeviceLogin;
      state.checkType = action.payload.checkType;
      state.maintainTime = action.payload.maintainTime;
      state.loading = action.payload.loading;
      state.MagazineJudgeUserData = action.payload.MagazineJudgeUserData;
    },
    resetUserState: () => ({ ...initialState }),
  },
});

export const {
  setLoginStatus,
  setEmail,
  setMaintainTime,
  setNeedsUserAgreement,
  setOtpType,
  setApplicationType,
  setUserData,
  setMagazineJudgeUserData,
  setPetData,
  setThanksData,
  setFirstDeviceLogin,
  setLoading,
  setCheckType,
  resetUserState,
  reconstructor,
} = userSlice.actions;

export default userSlice.reducer;

export const registerEmail =
  (email: string, history: History, campaignId: string): AppThunk =>
  async (dispatch) => {
    const userApi = instantiateUserApi();

    try {
      await userApi.postApiV1UsersPreRegistration({
        mail_address: email,
      });

      dispatch(setEmail(email));
      dispatch(setOtpType("registration"));
      history.push({
        pathname: "/otpConfirmation",
        search: `?campaignId=${campaignId}`,
      });
    } catch (e) {
      handleError(e as ApiError);

      if ((e as ApiError).response?.status === 409) {
        if (!(e as ApiError).response?.data?.is_need_login) {
          dispatch(setOtpType("registration"));
        }

        history.push({
          pathname: (e as ApiError).response?.data?.is_need_login
            ? "/"
            : "/otpConfirmation",
          search: `?campaignId=${campaignId}`,
        });
      }
      if ((e as ApiError).response?.status === 420) {
        history.push({
          pathname: "/",
          search: `?campaignId=${campaignId}`,
        });
      }
    }
  };

export const login =
  (
    userId: string,
    password: string,
    history: History,
    campaignId: string | undefined,
    noCampaignPath: string | null,
    setLoginError: UseFormSetError<LoginInput>
  ): AppThunk =>
  async (dispatch, getState) => {
    const {
      user: { checkType },
    } = getState();

    const loginApi = instantiateLoginApi();

    const trustedTerminalId = localStorage.getItem("trustedTerminalId");

    try {
      const checkPrecam =
        noCampaignPath === "unsubscribe" ||
        noCampaignPath === "updateNewsletters";

      const res = await loginApi.postLogin({
        user_id: userId,
        password: password,
        check_precam: checkPrecam,
        ...(trustedTerminalId && { trusted_terminal_id: trustedTerminalId }),
      });

      dispatch(setApplicationType("login"));

      dispatch(setNeedsUserAgreement(!res.data.user.is_terms_agreed));

      dispatch(setEmail(userId));

      dispatch(setUserData(res.data.user));

      dispatch(setPetData(res.data.pets));

      if (res.data.is_need_otp) {
        dispatch(setOtpType("login"));

        dispatch(setFirstDeviceLogin(true));

        const searchParams = new URLSearchParams();
        if (campaignId) searchParams.set("campaignId", campaignId);
        if (noCampaignPath) searchParams.set("noCampaignPath", noCampaignPath);

        history.push({
          pathname: "/otpConfirmation",
          search: searchParams.toString(),
        });
      } else if (!res.data?.user?.is_terms_agreed) {
        dispatch(setLoginStatus(true));

        history.push({
          pathname: "/userAgreement",
          search: `?campaignId=${campaignId}`,
        });
      } else {
        dispatch(setLoginStatus(true));

        if (noCampaignPath) {
          history.push({
            pathname: noCampaignPath,
            search: `?noCampaignPath=${noCampaignPath}`,
          });

          return;
        }

        if ( await checkMagazineJudge( checkType ) ) {
          history.push({
            pathname: "/registration",
            search: `?campaignId=${campaignId}`,
          });
        } else {
          history.push({
            pathname: "/judgeMagazine",
            search: `?campaignId=${campaignId}`,
          });
        }
      }
    } catch (e) {
      if ((e as ApiError).response?.status === 500) {
        handleError(e as ApiError);

        return;
      }

      setLoginError("loginId", {
        type: "api",
        message: (e as ApiError).response?.data?.error?.message,
      });

      setLoginError("password", {
        type: "api",
        message: (e as ApiError).response?.data?.error?.message,
      });
    }
  };

export const confirmOtp =
  (
    otp: string,
    history: History,
    campaignId: string | null,
    noCampaignPath: string | null,
    setOtpError: UseFormSetError<OTPConfirmationInput>
  ): AppThunk =>
  async (dispatch, getState) => {
    const {
      user: { needsUserAgreement, otpType, checkType },
    } = getState();

    const loginApi = instantiateLoginApi();
    const userApi = instantiateUserApi();

    try {
      switch (otpType) {
        case "login": {
          const res = await loginApi.postApiV1UserLoginConfirmOtp({ otp });

          if (res.data.code === -1) {
            return;
          }

          localStorage.setItem(
            "trustedTerminalId",
            res.data.trusted_terminal_id
          );

          dispatch(setLoginStatus(true));

          if (noCampaignPath) {
            history.push({
              pathname: noCampaignPath,
            });

            return;
          }

          if (needsUserAgreement) {
            history.push({
              pathname: "/userAgreement",
              search: `?campaignId=${campaignId}`,
            });

            return;
          }

          if ( await checkMagazineJudge( checkType ) ) {
            history.push({
              pathname: "/registration",
              search: `?campaignId=${campaignId}`,
            });
          } else {
            history.push({
              pathname: "/judgeMagazine",
              search: `?campaignId=${campaignId}`,
            });
          }

          break;
        }
        case "passwordReset": {
          const res = await userApi.postApiV1UserPasswordConfirmOtp({ otp });

          if (res.data.code === -1) {
            return;
          }

          const searchParams = new URLSearchParams();
          if (campaignId) searchParams.set("campaignId", campaignId);
          if (noCampaignPath)
            searchParams.set("noCampaignPath", noCampaignPath);

          history.push({
            pathname: "/resetPassword",
            search: searchParams.toString(),
          });

          break;
        }
        case "registration": {
          const res = await userApi.postApiV1UsersConfirmOtp({ otp });

          if (res.data.code === -1) {
            return;
          }

          localStorage.setItem(
            "trustedTerminalId",
            res.data.trusted_terminal_id
          );

          if (checkType !== 0 && checkType !== undefined) {
            history.push({
              pathname: "/judgeMagazine",
              search: `?campaignId=${campaignId}`,
            })
          } else {
            history.push({
              pathname: "/registration",
              search: `?campaignId=${campaignId}`,
            });
          }

          break;
        }

        default: {
          toast.error("OTPの種類が指定されていません", { autoClose: 10000 });

          return;
        }
      }

      dispatch(setOtpType(undefined));
    } catch (e) {
      if ((e as ApiError).response?.status === 500) {
        handleError(e as ApiError);

        return;
      }

      setOtpError("otp", {
        type: "api",
        message: (e as ApiError).response?.data?.error?.message,
      });
    }
  };

export const sendPasswordResetOtp =
  (
    email: string,
    name: string,
    surname: string,
    history: History,
    campaignId: string | null,
    noCampaignPath: string | null,
    setForgotPasswordError: UseFormSetError<ForgotPasswordInputs>
  ): AppThunk =>
  async (dispatch) => {
    const userApi = instantiateUserApi();

    try {
      await userApi.postApiV1UserPasswordSendOtp({
        mail_address: email,
        last_name: surname,
        first_name: name,
      });

      dispatch(setOtpType("passwordReset"));

      const searchParams = new URLSearchParams();
      if (campaignId) searchParams.set("campaignId", campaignId);
      if (noCampaignPath) searchParams.set("noCampaignPath", noCampaignPath);

      history.push({
        pathname: "/otpConfirmation",
        search: searchParams.toString(),
      });
    } catch (e) {
      if ((e as ApiError).response?.status === 500) {
        handleError(e as ApiError);

        return;
      }

      setForgotPasswordError("email", {
        type: "api",
        message: (e as ApiError).response?.data?.error?.message,
      });
    }
  };

export const unsubscribe =
  (history: History): AppThunk =>
  async (dispatch) => {
    const userApi = instantiateUserApi();

    try {
      const res = await userApi.postApiV1UserWithdraw();
      if (res.data.maintain_state === 1) {
        history.push({
          pathname: "/maintenancePage",
          search: `?noCampaignPath=maintenancePage`,
        })
      } else {

        dispatch(resetFormState());
        dispatch(resetCampaignState());
        dispatch(resetUserState());

        localStorage.clear();
        sessionStorage.clear();

        history.push("/unsubscribeComplete");
      }
    } catch (e) {
      handleError(e as ApiError);

      if ((e as ApiError).response?.status === 401 || (e as ApiError).response?.status === 404 || (e as ApiError).response?.status === 409) {
        history.push({
          pathname: "/",
          search: `?noCampaignPath=unsubscribe`,
        });
      }
      if ((e as ApiError).response?.status === 403) {
        dispatch(resetFormState());
        dispatch(resetCampaignState());
        dispatch(resetUserState());

        localStorage.clear();
        sessionStorage.clear();

        history.push("/?noCampaignPath=unsubscribe");
      }
    }
  };

export const fetchNewsletterSettings =
  (): AppThunk => async (dispatch, getState) => {
    const userApi = instantiateUserApi();
    const { user } = getState();

    if (!user.userData) return;

    dispatch(setLoading(true));

    try {
      const res = await userApi.getApiV1UserOpt();

      console.log(res.data);

      dispatch(
        setUserData({
          ...user.userData,
          is_need_senior_lead: res.data.is_senior_lead ? 1 : 0,
          is_subscription_mail_magazine: res.data.is_subscription_mail_magazine
            ? 1
            : 0,
        })
      );
    } catch (e) {
      handleError(e as ApiError);
    }

    dispatch(setLoading(false));
  };

export const fetchUserData = createAsyncThunk<
  { user: UserData; pets: Pet[] },
  { history: History; campaignId: string | undefined }
>("user/fetchUserData", async ({ history, campaignId }, { dispatch }) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_URL + "/api/v1/user/get"
    );

    dispatch(setEmail(response.data.user.email));
    dispatch(setUserData(response.data.user));
    dispatch(setPetData(response.data.pets));

    return response.data;
  } catch (e) {
    handleError(e as ApiError);

    if ((e as ApiError).response?.status === 401) {
      dispatch(resetFormState());
      dispatch(resetCampaignState());
      dispatch(resetUserState());

      localStorage.clear();
      sessionStorage.clear();

      history.push(`/?campaignId=${campaignId}`);
    }
  }
});

export const checkMagazineJudge = async ( checkType?: number ) => {
  const userApi = instantiateUserApi();

  if ( checkType === 0 && checkType !== undefined) return true;

  try {
    const res = await userApi.getApiV1UserCheckMagazineMemberJudge();

    switch (checkType) {
      case 1:
        return true;
      case 2:
        if ( res.data.catMagazineMemberFlg === "0" && res.data.dogMagazineMemberFlg === "0" ) {
          return false;
        } else {
          return true;
        }
      case 3:
        if ( res.data.catMagazineMemberFlg === "1" || res.data.dogMagazineMemberFlg === "1" ) {
          return true;
        } else {
          return false;
        }
      case 4:
        if ( res.data.dogMagazineMemberFlg === "1" ) {
          return true;
        } else {
          return false;
        }
      case 5:
        if ( res.data.catMagazineMemberFlg === "1" ) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  } catch (e) {
    return false;
  }
};

export const setMagazineJudgeParameter =
  (
    surname: string,
    name: string,
    year: string,
    month: string,
    day: string,
    firstPart: string,
    secondPart: string,
    thirdPart: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(
        setMagazineJudgeUserData({
          surname: surname,
          name: name,
          year: year,
          month: month,
          day: day,
          firstPart: firstPart,
          secondPart: secondPart,
          thirdPart: thirdPart,
        })
      );
    } catch (e) {
      if ((e as ApiError).response?.status === 500) {
        handleError(e as ApiError);

        return;
      }
    }
  };

