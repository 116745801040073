import styled from "styled-components";

import OrangeWaveIcon from "../assets/images/bg_wave_orange@3x.png";
import BeigeWaveIcon from "../assets/images/bg_wave@3x.png";
import BenesseLogoHeaderIcon from "../assets/images/benesse_logo_header@3x.png";
import InunekoLogoIconSmall from "../assets/images/inuneko_logo_s@3x.png";
import InunekoLogoIconLarge from "../assets/images/inuneko_logo_l@3x.png";

interface HeaderProps {
  logo?: "standard" | "large";
  waveColor?: "beige" | "orange";
  title?: string;
  iconImage?: "headerLoading" | "bodyLoading";
}

export const Header: React.FC<HeaderProps> = ({
  logo = "standard",
  waveColor = "beige",
  title,
  iconImage = "headerLoading",
}) => (
  <>
    {logo === "standard" ? (
      <IconContainer>
        <a href="https://www.benesse.co.jp/" rel="noreferrer" target="_blank">
          <BenesseLogoHeaderIconImage src={BenesseLogoHeaderIcon} />
        </a>

        <InunekoLogoIconImage src={InunekoLogoIconSmall} />
      </IconContainer>
    ) : (
      <LargeIconContainer>
        <a href="https://www.benesse.co.jp/" rel="noreferrer" target="_blank">
          <BenesseLogoHeaderIconImage src={BenesseLogoHeaderIcon} />
        </a>

        <InunekoLogoIconILarge src={InunekoLogoIconLarge} />

        <Title>{title}</Title>
      </LargeIconContainer>
    )}
    {iconImage === "headerLoading" ? (
      <WaveIconImage
        src={waveColor === "orange" ? OrangeWaveIcon : BeigeWaveIcon}
        alt="Wave icon"
      />
    ) : (
        <></>
    )}
  </>
);

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`;

const LargeIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;
const BenesseLogoHeaderIconImage = styled.img`
  margin-left: 16px;
  width: 105px;
  height: 24px;
`;

const InunekoLogoIconImage = styled.img`
  width: 200px;
  height: 27px;
  margin-right: 27px;
`;

const InunekoLogoIconILarge = styled.img`
  align-self: center;
  margin-top: 27px;
  height: 40px;
`;

const Title = styled.h1`
  align-self: center;
  font-size: 26px;
  line-height: 18px;
  color: #221715;
  font-weight: 600;
`;

const WaveIconImage = styled.img`
  height: 60px;
  width: 100%;
`;
