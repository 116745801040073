import { useHistory } from "react-router";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect, Fragment } from "react";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  personalInformationAgreement,
  termsOfUse,
} from "../assets/data/agreementTexts";
import { Button } from "../components/Button";
import { instantiateUserApi } from "../utils/apiWrappers";
import { ApiError, handleError } from "../utils/handleError";
import {
  fetchCampaignData,
  setError,
} from "../features/campaign/campaignSlice";
import { PageNotFound } from "./PageNotFound";
import { checkMagazineJudge } from "../features/user/userSlice";

export const UserAgreement = () => {
  const history = useHistory();
  const { applicationType, checkType } = useAppSelector((state) => state.user);
  const { userAgreementText } = useAppSelector(({ campaign }) => campaign);
  const userAgreementTextToString = userAgreementText ? userAgreementText : "";

  const dispatch = useAppDispatch();
  const { campaignData, error, loading, currentCampaignId } = useAppSelector(
    (state) => state.campaign
  );
  const queryParam = useLocation().search;
  useEffect(() => {
    if (campaignData == null) {
      const query = new URLSearchParams(queryParam);
      const campaignId = query.get("campaignId");
      if (!campaignId) {
        dispatch(setError(true));
        return;
      }

      dispatch(fetchCampaignData(campaignId, document));
    }
  }, [dispatch, queryParam, campaignData]);

  if (loading) {
    return <Fragment />;
  }

  if (error || !campaignData) {
    return <PageNotFound />;
  }

  const handleClickNext = async () => {
    if (applicationType === "login") {
      const userApi = instantiateUserApi();

      try {
        await userApi.putApiV1UserAgreeTerms({
          is_terms_agreed: true,
        });
      } catch (e) {
        handleError(e as ApiError);
      }

      if ( await checkMagazineJudge( checkType ) ) {
        history.push({
          pathname: "/registration",
          search: `?campaignId=${currentCampaignId}`,
        });
      } else {
        history.push({
          pathname: "/judgeMagazine",
          search: `?campaignId=${currentCampaignId}`,
        });
      }
    } else if (applicationType === "skipRegistration") {
      history.push({
        pathname: "/registration",
        search: `?campaignId=${currentCampaignId}`,
      });
    } else {
      history.push({
        pathname: "/emailRegistration",
        search: `?campaignId=${currentCampaignId}`,
      });
    }
  };

  const handleBackLink = () => {
    if (applicationType === "registration") {
      history.push(`/?campaignId=${currentCampaignId}`)
    } else {
      history.push(`/campaign/${currentCampaignId}`)
    }
  };

  return (
    <>
      <Title></Title>

      <Description>
        {(() => {
          if (applicationType === "skipRegistration") {
            return <span>個人情報の取扱いについて、承諾くださる方はお進みください。</span>
          } else {
            return <span>
                ＜個人情報の取り扱いについて＞と＜会員規約＞<br />
                についてご承諾くださる方はお進みください。
            </span>
          }
        })()}
      </Description>

      <TextAreaTitle>個人情報の取り扱いについて</TextAreaTitle>

      <TextArea>
        <TextAreaContents>
          {(() => {
            if (applicationType === "skipRegistration") {
              return <div dangerouslySetInnerHTML={{__html: userAgreementTextToString}}></div>;
            } else {
              return <span>{personalInformationAgreement}</span>;
            }
          })()}
          </TextAreaContents>
      </TextArea>

      {applicationType !== "skipRegistration" && (
        <>
          <TextAreaTitle>会員規約</TextAreaTitle>

          <TextArea>
            <TextAreaContents>{termsOfUse}</TextAreaContents>
          </TextArea>
        </>
      )}

      <NextButton type="button" icon="arrow" onClick={handleClickNext}>
        同意して次へ
      </NextButton>

      <BackButton
        type="button"
        color="white"
        onClick={handleBackLink}
      >
        戻る
      </BackButton>
    </>
  );
};

const Title = styled.h2`
  width: 60%;
  text-align: center;
  font-size: 20px;
`;

const Description = styled.div`
  text-align: center;
  width: 80%;
  padding-bottom: 30px;
  font-size: 12px;
`;

const TextAreaTitle = styled.h3`
  width: 100%;
  font-size: 20px;
`;

const TextArea = styled.div`
  padding: 15px 10px 10px 15px;
  background-color: #fff;
  width: 100%;
  height: 240px;
`;

const TextAreaContents = styled.div`
  white-space: pre-line;
  font-size: 14px;
  line-height: 21px;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ef8205;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
`;

const NextButton = styled(Button)`
  width: 80%;
  margin-top: 35px;
`;

const BackButton = styled(Button)`
  width: 80%;
  margin: 35px 0 55px 0;
`;
