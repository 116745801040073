import { useEffect, useState } from "react";
import styled from "styled-components";
import { Card } from "../components/Card";
import { Label } from "../components/Label";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { useHistory, useLocation } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppSelector } from "../app/hooks";
import { instantiateUserApi } from "../utils/apiWrappers";
import { toast } from "react-toastify";
import { Modal } from "../components/JudgeModal";
import { setMagazineJudgeParameter } from "../features/user/userSlice";
import { useAppDispatch } from "../app/hooks";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  fetchCampaignData,
  setError as setCampaignError
} from "../features/campaign/campaignSlice";

export interface JudgeMagazineInput {
  memberNumber: string;
  surname: string;
  name: string;
  year: string;
  month: string;
  day: string;
  firstPart: string;
  secondPart: string;
  thirdPart: string;
}

export const JudgeMagazine = () => { 
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { applicationType, checkType } = useAppSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery('(min-width:600px)');

  const query = new URLSearchParams(useLocation().search);
  const campaignId = query.get("campaignId") as string;

  useEffect(() => {
    if (!campaignId) {
      dispatch(setCampaignError(true));
      return;
    }

    dispatch(fetchCampaignData(campaignId, document));
  }, [dispatch, campaignId]);

  const convertToDoubleDigit = (number: string) =>
  number.length === 1 ? `0${number}` : number;

  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<JudgeMagazineInput>({});

  const [value, setValue] = useState(0);

  const handleSkipJudge = () => {
    history.push({
      pathname: "/registration",
      search: `?campaignId=${campaignId}`,
    });
  };

  const validateDate = (year: string, month: string, day: string) => {
    let isValid = true;
    if (year === "" || month === "" || day === "") {
      return isValid;
    } else {
      const dateObj = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
  
      if (dateObj > new Date()) {
        setError(
          "year",
          { type: "invalidDate" },
          { shouldFocus: true }
        );
  
        setError("month", {
          type: "invalidDate",
        });
  
        setError("day", {
          type: "invalidDate",
        });
  
        isValid = false;
      }
  
      if (dateObj.getMonth() + 1 !== parseInt(month)) {
        setError(
          "month",
          { type: "invalidDate" },
          { shouldFocus: true }
        );
  
        isValid = false;
      }
  
      if (dateObj.getDate() !== parseInt(day)) {
        setError(
          "day",
          { type: "invalidDate" },
          { shouldFocus: true }
        );
  
        isValid = false;
      }
  
      return isValid;
  
    }
  };

  const outputError = () => {
    toast.error("購読状況が確認できませんでした。入力内容をご確認ください。", { autoClose: 10000 });
  };

  const onSubmit: SubmitHandler<JudgeMagazineInput> = async (data) => {
    if( value === 1) {
      if (!validateDate(data.year, data.month, data.day)) return;
    }
    const userApi = instantiateUserApi();
    try {
      if(applicationType === "registration" && value === 1) {
        await dispatch(
          setMagazineJudgeParameter(
            data.surname,
            data.name,
            data.year,
            data.month,
            data.day,
            data.firstPart,
            data.secondPart,
            data.thirdPart
          )
        );
      } else {
        await dispatch(
          setMagazineJudgeParameter(
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ""
          )
        );
      }

      const res = await userApi.postApiV1UserJudgeMagazineMember({
        familyName: data.surname,
        firstName: data.name,
        birthDay: `${data.year}${data.month ? convertToDoubleDigit(data.month) : ''}${data.day ? convertToDoubleDigit(data.day) : ''}`,
        fixPhoneNumber: `${data.firstPart}${data.secondPart}${data.thirdPart}`,
        commonCustomerNumber: data.memberNumber,
        JudgeFlag: value.toString(),
      });
      if (checkType !== undefined) {
        switch (checkType) {
          case 1:
            if ( res.data.catMagazineMemberFlg === "0" && res.data.dogMagazineMemberFlg === "0" ) {
              setShowModal(true);
            } else {
              if (res.data.code === -1) {
                break;
              } else {
                handleSkipJudge();
              }
            }
            break;
          case 2:
            if ( res.data.catMagazineMemberFlg === "0" && res.data.dogMagazineMemberFlg === "0" ) {
                outputError();
            } else {
              if (res.data.code === -1) {
                break;
              } else {
                handleSkipJudge();
              }
            }
            break;
          case 3:
            if ( res.data.catMagazineMemberFlg === "1" || res.data.dogMagazineMemberFlg === "1" ) {
              handleSkipJudge();
            } else {
              if (res.data.code === -1) {
                break;
              } else {
                outputError();
              }
            }
            break;
          case 4:
            if ( res.data.dogMagazineMemberFlg === "1" ) {
              handleSkipJudge();
            } else {
              if (res.data.code === -1) {
                break;
              } else {
                outputError();
              }
            }
            break;
          case 5:
            if ( res.data.catMagazineMemberFlg === "1" ) {
              handleSkipJudge();
            } else {
              if (res.data.code === -1) {
                break;
              } else {
                outputError();
              }
            }
            break;
          default:
            outputError();
        }
      } else {
        outputError();
      }
    } catch (e) {
      if (checkType === 1 && checkType !== undefined) {
        setShowModal(true);
      } else {
        outputError();
      }
    }
  };
  
  return (
    <>
      <Modal
        isOpen={showModal}
        onConfirm={() => {
          setShowModal(false);
          handleSkipJudge();
        }}
        onCancel={() => setShowModal(false)}
        message="購読状況は確認できませんでした。このまま応募する場合は「このまま応募する」再入力する場合は「再入力する」を押してください。"
        confirmButtonText="このまま応募する"
        cancelButtonText="再入力する"
      />

      {checkType === 1 && checkType !== undefined &&
        <DescriptionText>
          「いぬのきもち」「ねこのきもち」を定期購読されている方はご登録ください。登録されていない場合は「登録しない」を押下ください。
        </DescriptionText>
      }

      {checkType !== 1 && checkType !== undefined &&
        <DescriptionText>
          「いぬのきもち」「ねこのきもち」の購読状況を確認します。
        </DescriptionText>
      }

      <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
        <LabelGroup>
          <Tab1 
            onClick={() => setValue(0)} 
            isSelected={value}
          >
            　会員番号　
          </Tab1>

          <Tab2 
            onClick={() => setValue(1)} 
            isSelected={value}
          >
            　登録情報　
          </Tab2>
        </LabelGroup>

        {value === 0 && 
          <ContentCard>
            <DescriptionText>
              お届けしている「あて名」用紙の右上に記載されている10桁の会員番号をご入力ください。10桁の会員番号がわからない方は上部タブの「登録情報」を選んで、雑誌購読お申し込み時の情報をご登録ください。
            </DescriptionText>

            <Label>10桁の会員番号を入力してください</Label>

            <ErrorContainer>
              <Input
                type="number"
                {...register("memberNumber", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                })}
                placeholder="0000000000"
                error={!!errors.memberNumber}
              />

              {errors.memberNumber?.type === "required" && (
                <ErrorMessage>会員番号は必ず入力して下さい。</ErrorMessage>
              )}

              {errors.memberNumber?.type === "minLength" && (
                <ErrorMessage>10桁の会員番号を入力して下さい。</ErrorMessage>
              )}

              {errors.memberNumber?.type === "maxLength" && (
                <ErrorMessage>10桁の会員番号を入力して下さい。</ErrorMessage>
              )}

            </ErrorContainer>
          </ContentCard>
        }

        {value === 1 && 
          <ContentCard>
            <DescriptionText> 
              ご購読のお申込み時に登録した情報をご入力ください。
            </DescriptionText>

            <Label>氏名（漢字）</Label>

            <InputContainer>
              <NameErrorContainer>
                <Input
                  {...register("surname", {
                    required: true,
                  })}
                  placeholder="姓"
                  error={!!errors.surname}
                />
                {errors.surname?.type === "required" && (
                  <ErrorNameMessage>姓は必ず入力して下さい。</ErrorNameMessage>
                )}
                {errors.name?.type === "required" && (
                  <ErrorNameMessage>名は必ず入力して下さい。</ErrorNameMessage>
                )}
              </NameErrorContainer>

              <NameErrorContainer>
                <Input
                  {...register("name", {
                    required: true,
                  })}
                  placeholder="名"
                  error={!!errors.name}
                />

              </NameErrorContainer>
            </InputContainer>

            <Label>生年月日</Label>

            <ErrorContainer>
              <DateInputContainer>
                <YearInput
                  {...register("year", {
                    required: true,
                    minLength: 4,
                    maxLength: 4,
                    min: 1900,
                  })}
                  type="number"
                  placeholder="年"
                  error={!!errors.year}
                />

                <MonthDayInput
                  {...register("month", {
                    required: true,
                    minLength: 1,
                    maxLength: 2,
                  })}
                  type="number"
                  placeholder="月"
                  error={!!errors.month}
                />

                <MonthDayInput
                  {...register("day", {
                    required: true,
                    minLength: 1,
                    maxLength: 2,
                  })}
                  type="number"
                  placeholder="日"
                  error={!!errors.day}
                />
              </DateInputContainer>

              {(errors.year?.type === "min") && (
                <ErrorMessage>生年月日を正しく入力して下さい。</ErrorMessage>
              )}

              {(errors.year?.type === "required" ||
                errors.month?.type === "required" ||
                errors.day?.type === "required") && (
                <ErrorMessage>生年月日は必ず指定して下さい。</ErrorMessage>
              )}

              {(errors.year?.type === "invalidDate" ||
                errors.month?.type === "invalidDate" ||
                errors.day?.type === "invalidDate") && (
                <ErrorMessage>生年月日を正しく入力して下さい。</ErrorMessage>
              )}

              {(errors.year?.type === "maxLength" ||
                errors.year?.type === "minLength") && (
                <ErrorMessage>年は4桁で入力して下さい。</ErrorMessage>
              )}

              {(errors.month?.type === "maxLength" ||
                errors.month?.type === "minLength") && (
                <ErrorMessage>月は2桁までで入力して下さい。</ErrorMessage>
              )}

              {(errors.day?.type === "maxLength" ||
                errors.day?.type === "minLength") && (
                <ErrorMessage>日は2桁までで入力して下さい。</ErrorMessage>
              )}
            </ErrorContainer>

            <Label>電話番号</Label>

            <ErrorContainer>
              <InputContainer>
                <PhoneInput
                  {...register("firstPart", {
                    required: true,
                    maxLength: 5,
                    pattern: /^\d*$/,
                  })}
                  placeholder="例）080"
                  type="tel"
                  pattern="\d*"
                  maxLength={5}
                  error={!!errors.firstPart}
                />

                <PhoneInput
                  {...register("secondPart", {
                    required: true,
                    maxLength: 4,
                    pattern: /^\d*$/,
                  })}
                  placeholder="0000"
                  type="tel"
                  pattern="\d*"
                  maxLength={4}
                  error={!!errors.secondPart}
                />

                <PhoneInput
                  {...register("thirdPart", {
                    required: true,
                    maxLength: 4,
                    pattern: /^\d*$/,
                  })}
                  placeholder="0000"
                  type="tel"
                  pattern="\d*"
                  maxLength={4}
                  error={!!errors.thirdPart}
                />
              </InputContainer>

              {errors.firstPart?.type ===
                "maxLength" && (
                <ErrorMessage>
                  1つ目の入力欄は最大5桁です。
                </ErrorMessage>
              )}

              {errors.secondPart?.type ===
                "maxLength" && (
                <ErrorMessage>
                  2つ目の入力欄は最大4桁です。
                </ErrorMessage>
              )}

              {errors.thirdPart?.type ===
                "maxLength" && (
                <ErrorMessage>
                  3つ目の入力欄は最大4桁です。
                </ErrorMessage>
              )}

              {(errors.firstPart?.type === "pattern" ||
                errors.secondPart?.type === "pattern" ||
                errors.thirdPart?.type === "pattern") && (
                <ErrorMessage>
                  電話番号は数値を入力してください。
                </ErrorMessage>
              )}

              {(errors.firstPart?.type === "required" ||
                errors.secondPart?.type === "required" ||
                errors.thirdPart?.type === "required") && (
                <ErrorMessage>
                  電話番号を入力してください。
                </ErrorMessage>
              )}
            </ErrorContainer>

          </ContentCard>
        }
        <SubmitButton 
          icon="arrow" 
          isSelected={value}
          isMobile={isMobile}
        >
          登録する
        </SubmitButton>

        {checkType === 1 && checkType !== undefined && (
          <SkipJudgeButton
            onClick={handleSkipJudge}
            type="button"
            color="white"
          >
            登録しない
          </SkipJudgeButton>
        )}
      </StyledForm>
    </>
  );
}

const StyledForm = styled.form`
  width: 100%;
`;

const DescriptionText = styled.p`
  font-size: 16px;
  text-align: center;
`;

const LabelGroup = styled.div`
  display: flex;
  width: 100%;
`;

const Tab1 = styled.div<{ isSelected?: number }>`
  line-height: 36px;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 -3px 3px 0 #00000029;
  background-color: ${({ isSelected }) => (isSelected === 0 ? "#ffffff" : "#fdecd8")};
`;

const Tab2 = styled.div<{ isSelected?: number }>`
  line-height: 36px;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 -3px 3px 0 #00000029;
  background-color: ${({ isSelected }) => (isSelected === 1 ? "#ffffff" : "#fdecd8")};
`;

const ContentCard = styled(Card)`
  margin-bottom: 50px;
  width: 100%;
  box-shadow: 0 3px 6px 0 #00000029;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NameErrorContainer = styled(ErrorContainer)`
  width: 48%;
`;

const DateInputContainer = styled.div`
  display: flex;
`;

const YearInput = styled(Input)`
  width: 20%;
`;

const MonthDayInput = styled(Input)`
  width: 15%;
  margin-left: 10px;
`;

const PhoneInput = styled(Input)`
  width: 32%;
`;

const SubmitButton = styled(Button)<{ isSelected?: number, isMobile?: boolean }>`
  margin: 0 0 0 10%;
  width: 80%;
`;

const SkipJudgeButton = styled(Button)`
  margin: 25px 0 0 10%;
  width: 80%;
`;

const ErrorMessage = styled.div`
  color: #d01414;
  font-size: 14px;
`;

const ErrorNameMessage = styled.div`
  color: #d01414;
  font-size: 14px;
  width: 200%
`;