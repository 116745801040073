import styled from "styled-components";
import {
  OptionDescription,
  StyledRadio,
  Newsletter,
} from "../../pages/Registration";
import { Controller, Control } from "react-hook-form";
import { CheckBox } from "../../components/CheckBox";

export interface NewslettersValues {
  user: {
    subscribeToNewsletter: boolean;
    newsletters: Newsletter[];
  };
}

interface NewslettersProps {
  control: Control<NewslettersValues>;
}

export const Newsletters: React.FC<NewslettersProps> = ({ control }) => (
  <>
    <OptionDescription>
      いぬ・ねこのきもちからペットに関する
      サービスや商品のご案内をお送りします。
    </OptionDescription>

    <Controller
      control={control}
      name={`user.subscribeToNewsletter`}
      render={({ field: { onChange, value } }) => (
        <NewsletterContainer>
          <StyledRadio
            onClick={() => onChange(true)}
            isSelected={value === true}
            label="受け取る"
          />

          <StyledRadio
            onClick={() => onChange(false)}
            isSelected={value === false}
            label="受け取らない"
          />
        </NewsletterContainer>
      )}
    />

    <OptionDescription>
      追加で入手したい情報を選んでください。
    </OptionDescription>

    <NewsletterTypeContainer>
      <Controller
        control={control}
        name={`user.newsletters`}
        render={({ field: { onChange, value } }) => (
          <CheckBox
            onClick={() =>
              onChange(
                value.includes("longevity")
                  ? value.filter((value) => value !== "longevity")
                  : value.concat("longevity")
              )
            }
            isSelected={value.includes("longevity")}
            label="長生き情報"
          />
        )}
      />

      <NewsletterTypeLink href="http://cat-dog.love/nagaiki00001" target="_blank">長生き情報とは</NewsletterTypeLink>
    </NewsletterTypeContainer>
  </>
);

const NewsletterContainer = styled.div`
  display: flex;
  margin: 0 0 40px 5px;
`;

const NewsletterTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const NewsletterTypeLink = styled.a`
  font-size: 14px;
  width: 50%;
  color: #0a7fbe;
  text-decoration: underline;
`;
