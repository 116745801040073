import styled from "styled-components";

interface LabelProps {
  required?: boolean;
  htmlFor?: string;
}

export const Label: React.FC<LabelProps> = ({
  children,
  required,
  htmlFor,
}) => (
  <StyledLabel htmlFor={htmlFor}>
    {children}

    {required ? <RequiredMessage>［必須］</RequiredMessage> : ""}
  </StyledLabel>
);

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
`;

const RequiredMessage = styled.span`
  color: #d01414;
`;
