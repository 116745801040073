import { useHistory } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import styled from "styled-components";

import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Label } from "../components/Label";
import { Input } from "../components/Input";
import { regExps } from "../utils/regExps";
import { saveStoreData } from "../utils/saveStoreData";
import { sendPasswordResetOtp } from "../features/user/userSlice";
import { useAppDispatch } from "../app/hooks";
import { PageNotFound } from "./PageNotFound";
import { useLocation } from "react-router-dom";

export interface ForgotPasswordInputs {
  email: string;
  surname: string;
  name: string;
}

export const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ForgotPasswordInputs>();

  const query = new URLSearchParams(useLocation().search);
  if (!query.has("campaignId") && !query.has("noCampaignPath")) {
    return <PageNotFound />;
  }
  const campaignId = query.get("campaignId") as string;
  const noCampaignPath = query.get("noCampaignPath") as string;

  const onSubmit: SubmitHandler<ForgotPasswordInputs> = async (data) =>
    await dispatch(
      sendPasswordResetOtp(
        data.email,
        data.name,
        data.surname,
        history,
        campaignId,
        noCampaignPath,
        setError
      )
    );
  saveStoreData();

  return (
    <>
      <Title>パスワード再発行</Title>

      <Description>
        ご登録済みのメールアドレスと漢字姓名をご入力ください。
      </Description>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Label>メールアドレス</Label>

          <EmailInput
            type="email"
            {...register("email", {
              required: true,
              pattern: regExps.email,
            })}
            placeholder=" "
          />

          <Label>氏名（漢字）</Label>

          <InputContainer>
            <NameInput
              {...register("surname", {
                required: true,
              })}
              placeholder="姓"
            />

            <NameInput
              {...register("name", {
                required: true,
              })}
              placeholder="名"
            />
          </InputContainer>

          {errors.email?.message && (
            <ErrorMessage>{errors.email.message}</ErrorMessage>
          )}

          <NextButton icon="arrow">パスワードを再発行</NextButton>

          <BackButton
            type="button"
            color="white"
            onClick={() =>
              history.push({
                pathname: "/",
                search: query.toString(),
              })
            }
          >
            戻る
          </BackButton>
        </Card>
      </form>
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 30px;
  line-height: 18px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmailInput = styled(Input)`
  margin-bottom: 30px;
`;

const NameInput = styled(Input)`
  margin: 15px 0;
  margin-bottom: 15px;
  width: 48%;
`;

const NextButton = styled(Button)`
  width: 80%;
  font-size: 18px;
  margin-top: 35px;
`;

const BackButton = styled(Button)`
  width: 80%;
  margin: 35px 0 55px 0;
`;

const ErrorMessage = styled.div`
  color: #d01414;
  font-size: 14px;
`;
const StyledOutsideLink = styled.a`
  text-decoration: underline;
  color :blue;
`;
