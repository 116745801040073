import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { History } from "history";

import { 
  InlineResponse2002 as CampaignData,
  InlineResponse2005 as NoticeData,
 } from "../../types/typescript-axios";
import { AppThunk } from "../../app/store";
import { instantiateCampaignApi } from "../../utils/apiWrappers";
import {
  resetFormState,
  convertFormStateToApiParams,
  setFieldSettings,
} from "../form/formSlice";
import { setApplicationType, resetUserState, setCheckType } from "../user/userSlice";
import { toast } from "react-toastify";
import { ApiError, handleError } from "../../utils/handleError";

export enum CampaignStatus {
  Pending = 1,
  Public = 2,
  Expired = 3,
}

interface CampaignState {
  campaignData?: CampaignData;
  noticeData?: NoticeData;
  campaignStatus?: CampaignStatus;
  error: boolean;
  loading: boolean;
  currentCampaignId?: string;
  questionnaireUrl?: string;
  userAgreementText?: string;
  campaignAnswerId?: number;
}

const initialState: CampaignState = {
  error: false,
  loading: false,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignData: (state, action: PayloadAction<CampaignData>) => {
      state.campaignData = action.payload;
      state.error = false;
      state.loading = false;
    },
    setnoticeData: (state, action: PayloadAction<NoticeData>) => {
      state.noticeData = action.payload;
      state.error = false;
      state.loading = false;
    },
    setCampaignStatus: (state, action: PayloadAction<CampaignStatus>) => {
      state.campaignStatus = action.payload;
    },
    setCurrentCampaign: (state, action: PayloadAction<string>) => {
      state.currentCampaignId = action.payload;
    },
    setQuestionnaireUrl: (state, action: PayloadAction<string>) => {
      state.questionnaireUrl = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUserAgreementText: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.userAgreementText = action.payload;
    },
    resetCampaignState: () => ({ ...initialState }),
    setCampaignAnswerId: (state, action: PayloadAction<number>) => {
      state.campaignAnswerId = action.payload;
    },
  },
});

export const {
  setCampaignData,
  setnoticeData,
  setCampaignStatus,
  setCurrentCampaign,
  setError,
  setLoading,
  setQuestionnaireUrl,
  setUserAgreementText,
  resetCampaignState,
  setCampaignAnswerId,
} = campaignSlice.actions;

export default campaignSlice.reducer;

export const fetchCampaignData =
  (campaignId: string, document: Document): AppThunk =>
  async (dispatch) => {
    dispatch(setError(false));
    dispatch(setLoading(true));

    const campaignApi = instantiateCampaignApi();

    try {
      const campaignStatusResponse =
        await campaignApi.getApiV1CampaignsIdStatus(campaignId);

      dispatch(setCampaignStatus(campaignStatusResponse.data.status));

      if (campaignStatusResponse.data.status === CampaignStatus.Public) {
        const requiredFieldsResponse = await campaignApi.getApiV1ApplicationsId(
          campaignId
        );

        dispatch(
          setCheckType(requiredFieldsResponse.data)
        );
        dispatch(
          setUserAgreementText(requiredFieldsResponse.data.confirm_sentence)
        );
        dispatch(setFieldSettings(requiredFieldsResponse.data));
      }

      dispatch(setCampaignData(campaignStatusResponse.data));
      dispatch(setCurrentCampaign(campaignId));
      document.title = campaignStatusResponse.data.campaign.name;
    } catch (err) {
      dispatch(setError(true));
    }
  };

export const applyForCampaignAndRegister =
  (history: History): AppThunk =>
  async (dispatch, getState) => {
    const campaignApi = instantiateCampaignApi();

    const {
      form: { formValues },
      campaign: { currentCampaignId },
    } = getState();

    if (!formValues) {
      toast.error("登録する情報がありません", { autoClose: 10000 });
      return;
    }

    if (!currentCampaignId) {
      toast.error("キャンペーンIDが指定されていません", { autoClose: 10000 });
      return;
    }

    const apiParams = convertFormStateToApiParams(formValues);

    try {
      const res = await campaignApi.postApiV1CampaignsIdApply(
        currentCampaignId,
        // Dryrun falseの場合で空のオブジェクトを受け取れない
        Object.keys(apiParams).length ? false : true,
        apiParams
      );

      dispatch(resetFormState());
      dispatch(resetCampaignState());
      dispatch(setQuestionnaireUrl(res.data.questionnaire_url));

      if (res.data.campaign_answer_id) {
        dispatch(setCampaignAnswerId(res.data.campaign_answer_id));
      }

      if (res.data.kfdb_external_id) {
        localStorage.setItem(
          "kfdbExternalId",
          res.data.kfdb_external_id
        );
      }

      history.push({
        pathname: "/registrationCompletion",
        search: `?campaignId=${currentCampaignId}`,
      });
    } catch (err) {
      if ((err as ApiError).response?.status === 401 || (err as ApiError).response?.status === 404 || (err as ApiError).response?.status === 409) {
        history.push({
          pathname: "/",
          search: `?campaignId=${currentCampaignId}`,
        });
      }
      if ((err as ApiError).response?.status === 403) {
        dispatch(resetFormState());
        dispatch(resetCampaignState());
        dispatch(resetUserState());

        localStorage.clear();
        sessionStorage.clear();

        history.push(`/?campaignId=${currentCampaignId}`);
      }

      handleError(err as ApiError);
    }
  };

export const skipRegistration =
  (history: History): AppThunk =>
  async (dispatch, getState) => {
    const {
      campaign: { currentCampaignId, campaignData },
      form: { fieldSettings },
    } = getState();

    if (!currentCampaignId) {
      toast.error("キャンペーンIDが指定されていません", { autoClose: 10000 });
      return;
    }

    if (fieldSettings.delivery.show) {
      dispatch(setApplicationType("skipRegistration"));
      history.push({
        pathname: "/userAgreement",
        search: `?campaignId=${currentCampaignId}`,
      });

      return;
    }

    if (!campaignData?.campaign.questionnaire_url) {
      toast.error("クエスタントのURLがありません", { autoClose: 10000 });

      return;
    }

    window.location.replace(campaignData?.campaign.questionnaire_url);
  };
