import styled from "styled-components";
import { Card } from "../components/Card";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import axios from "axios";
import {
  setMaintainTime,
} from "../features/user/userSlice";

export const MaintenancePage = () => { 
  const history = useHistory();  
  const dispatch = useAppDispatch();
  const { maintainTime } = useAppSelector(
    (state) => state.user
  );
  const query = new URLSearchParams(useLocation().search);
  const campaignId = query.get("campaignId") as string;

  axios.get(process.env.REACT_APP_BACKEND_API_URL + "/api/v1/isFrontMaintain")
  .then(response => {
    if (response.data.maintain_state === 1) {

      dispatch(setMaintainTime(response.data.maintain_time));
    } else if (response.data.maintain_state === 0) {

      history.push({
        pathname: "/",
        search: `?campaignId=${campaignId}`,
      })
    }
  })

  return (
    <>
      <Card>
        <Title>ただいまメンテナンス中です</Title>
        <DescriptionText>
          <p>ただいまメンテナンスのため、</p>
          <p>サービスを一時停止しております。</p>
          <p>ご利用の皆様にはご迷惑をおかけし、</p>
          <p>大変申し訳ございません。</p>
          <p>メンテナンス終了まで</p>
          <p>今しばらくお待ちください。</p>
        </DescriptionText>
        <DescriptionText>
           <p>【メンテナンス日時】</p>
           <p>{maintainTime}</p>
        </DescriptionText>
      </Card>
    </>
  );
}

const Title = styled.h2`
  text-align: center;
  font-size: 20px;
`;

const DescriptionText = styled.p`
  font-size: 14px;
  text-align: center;
`;
