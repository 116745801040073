import { Fragment } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";

import { Step } from "./Step";
import { useAppSelector } from "../../../app/hooks";

export const Stepper = () => {
  const { pathname } = useLocation();
  const { applicationType, needsUserAgreement, firstDeviceLogin } =
    useAppSelector(({ user }) => user);

  if (applicationType === "login" && !needsUserAgreement && !firstDeviceLogin) {
    return <Fragment />;
  }

  switch (pathname) {
    case "/userAgreement": {
      if (applicationType === "registration") {
        return (
          <StyledStepper>
            <Step active>規約に同意</Step>

            {/* TODO: 必須項目が揃った場合の非表示（登録画面スキップ） */}
            <Step>
              メールアドレス
              <br />
              登録
            </Step>

            <Step>
              ワンタイム
              <br />
              パスワード入力
            </Step>
          </StyledStepper>
        );
      }

      return (
        <StyledStepper>
          {applicationType === "login" && (
            <Step>
              ワンタイム
              <br />
              パスワード入力
            </Step>
          )}

          <Step active>規約に同意</Step>

          {/* TODO: 必須項目が揃った場合の非表示（登録画面スキップ） */}
          <Step>お客様情報入力</Step>

          <Step>確認</Step>
        </StyledStepper>
      );
    }

    case "/emailRegistration": {
      return (
        <StyledStepper>
          <Step>規約に同意</Step>

          <Step active>
            メールアドレス
            <br />
            登録
          </Step>

          <Step>
            ワンタイム
            <br />
            パスワード入力
          </Step>
        </StyledStepper>
      );
    }

    case "/otpConfirmation":
    case "/registration":
    case "/registrationConfirmation": {
      return (
        <StyledStepper>
          {applicationType !== "skipRegistration" && (
            <Step active={pathname === "/otpConfirmation"}>
              ワンタイム
              <br />
              パスワード入力
            </Step>
          )}

          {(applicationType === "skipRegistration" || needsUserAgreement) && (
            <Step>規約に同意</Step>
          )}

          {/* TODO: 必須項目が揃った場合の非表示（登録画面スキップ） */}
          <Step active={pathname === "/registration"}>お客様情報入力</Step>

          <Step active={pathname === "/registrationConfirmation"}>確認</Step>
        </StyledStepper>
      );
    }

    default: {
      return <Fragment />;
    }
  }
};

export const StyledStepper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
