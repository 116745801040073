import styled from "styled-components";

export const Input = styled.input<{ error?: boolean, icon?: boolean}>`
  background-color: #fff8dd;
  border: 1px ${({ error }) => (error ? "#d01414" : "#afafaf")} solid;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 7px;
  margin: 5px 0;
  height: 38px;
  font-weight: 600;
  outline: none;
  padding-right: ${({ icon }) => (icon ? "40px" : "7px")};

  ::placeholder {
    color: #afafaf;
  }

  :focus {
    border: 1px #d01414 solid;
  }
  :not(:placeholder-shown) {
    background-color: #ffffff;
  }

  :-webkit-autofill{
    box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  :disabled {
    background-color: #afafaf;
  }
`;
